@charset "UTF-8";@font-face{font-family:'Roboto';src:url(../fonts/Roboto-Light.eot?iefix) format("embedded-opentype");src:url(../fonts/Roboto-Light.woff2) format("font-woff2"),url(../fonts/Roboto-Light.woff) format("woff");font-style:normal;font-weight:300}
@font-face{font-family:'Roboto';src:url(../fonts/Roboto-Regular.eot?iefix) format("embedded-opentype");src:url(../fonts/Roboto-Regular.woff2) format("font-woff2"),url(../fonts/Roboto-Regular.woff) format("woff");font-style:normal;font-weight:400}
@font-face{font-family:'Roboto';src:url(../fonts/Roboto-Medium.eot?iefix) format("embedded-opentype");src:url(../fonts/Roboto-Medium.woff2) format("font-woff2"),url(../fonts/Roboto-Medium.woff) format("woff");font-style:normal;font-weight:500}
@font-face{font-family:'Roboto';src:url(../fonts/Roboto-Bold.eot?iefix) format("embedded-opentype");src:url(../fonts/Roboto-Bold.woff2) format("font-woff2"),url(../fonts/Roboto-Bold.woff) format("woff");font-style:normal;font-weight:700}
/* reset */
article,aside,footer,header,hgroup,main,nav,section{display:block}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button{margin:0;padding:0}
fieldset,img{border:0 none}
dl,ul,ol,menu,li{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
input,select,textarea,button{vertical-align:middle}
input::-ms-clear{display:none}
button{border:0 none;background-color:transparent;cursor:pointer}
body{background:#fff}
body,th,td,input,select,textarea,button{font-size:14px;line-height:1.5;font-family:'Noto Sans KR','Apple SD Gothic Neo','Malgun Gothic','맑은 고딕',sans-serif;color:#555;word-break:break-all}
a{color:#111;text-decoration:none;cursor:pointer}
a:active,a:hover{text-decoration:underline}
a:active{background-color:transparent}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:400}
img{vertical-align:top}
strong,b,th,h1,h2,h3,h4,h5,h6{font-weight:700}
button::-moz-focus-inner,input[type="reset"]::-moz-focus-inner,input[type="button"]::-moz-focus-inner,input[type="submit"]::-moz-focus-inner,input[type="file"] > input[type="button"]::-moz-focus-inner{padding:0;border:0}
/* global */
#kakaoIndex{overflow:hidden;position:absolute;left:-9999px;width:0;height:1px;margin:0;padding:0}
.ir_pm{display:block;overflow:hidden;font-size:0;line-height:0;text-indent:-9999px}/** pc용 */
.ir_wa{display:block;overflow:hidden;position:relative;z-index:-1;width:100%;height:100%}
.screen_out{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.show{display:block}
.hide{display:none}
caption.screen_out{position:relative}
/* Background Image - common 요소 */
.ico_comm{display:block;overflow:hidden;font-size:0;line-height:0;background:url("../images/ico_comm.png") no-repeat;background-size:900px 800px;text-indent:-9999px;vertical-align:top}/** 대체텍스트가 있는 아이콘 */
.img_comm{display:inline-block;background:url("../images/ico_comm.png") no-repeat;background-size:900px 800px;vertical-align:top}/** 대체텍스트가 없는 아이콘 */
.list_userinfo .menu_my .gnbsub_comm:after,.step_manage.on .l_error:after,.step_manage.on .l_exactly:after{background:url("../images/ico_comm.png") no-repeat;background-size:900px 800px}
/* .gnbsub_userinfo .link_business .txt_business{background:url(../images/gnb_business.gif) no-repeat} */
/* 2021-06-17 수정 */
/*gnb 개편 전,후 공통으로 사용되는 스타일*/
/* lnb - 캠페인관리(대쉬보드) :서제스트 레이어 */
.suggest_layer{position:absolute;left:-1px;top:42px;z-index:20;width:323px;padding:3px 3px 3px 0;border-radius:4px;background-color:#fff;-webkit-box-shadow:0px 2px 10px rgba(0,0,0,0.2);box-shadow:0px 2px 7px rgba(0,0,0,0.25)}/** 디자인 스크롤 여백 */
.suggest_layer .inner_suggest{overflow:hidden;overflow-y:auto;max-height:507px}
.suggest_layer .tit_suggest{display:block;padding:17px 20px 0;border-top:1px solid #ebebeb;font-size:13px;font-weight:500;color:#111}
.suggest_layer .tit_suggest:first-of-type{border-top:0 none}
.suggest_layer .cont_suggest{position:relative}
.suggest_layer .cont_suggest + .cont_suggest{border-top:1px solid #EBEBEB}
.suggest_layer .list_suggest li{padding-bottom:5px}
.suggest_layer .list_suggest .area_suggest{padding:5px 20px 5px 20px;font-size:13px}
.suggest_layer .list_suggest .area_suggest:hover,.suggest_layer .sublist_suggest li:hover{background:rgba(0,0,0,0.03)}
.suggest_layer .sublist_suggest{padding-bottom:10px}
.suggest_layer .sublist_suggest li{padding-left:20px}
.suggest_layer .sublist_suggest li .link_cpn{position:relative;padding:7px 0 7px 14px}
.suggest_layer .sublist_suggest li .link_cpn{font-size:12px;line-height:18px}
.suggest_layer .link_suggest{display:block;line-height:18px}
.suggest_layer .suggest_item{display:inline-block;position:relative;max-width:100%;line-height:16px;color:#326edc;vertical-align:top}
.suggest_layer .suggest_item .txt_item{display:block}
.suggest_layer .suggest_item .num_item{margin-left:4px}
.suggest_layer .tit_cpn{display:block;overflow:hidden;margin-top:5px;line-height:20px;font-weight:400;color:#333;line-height:19px}
.suggest_layer .link_cpn{display:block;overflow:hidden;line-height:18px;font-size:12px;color:#326EDC;text-decoration:none}
.suggest_layer .suggest_none{height:120px;text-align:center}
.suggest_layer .suggest_none .txt_none{padding-top:50px;font-size:13px;color:#999}
.suggest_layer .tit_type{display:block;padding:17px 17px 5px 20px;font-weight:500;font-size:12px;color:#333}
.suggest_layer .link_type{display:block;font-size:11px;color:#777;line-height:18px;text-decoration:none}
.suggest_layer .txt_bar{display:inline-block;padding:0 1px}
.suggest_layer .suggest_item .txt_item:hover{text-decoration:underline}
.suggest_layer .link_all{position:absolute;top:17px;right:20px;line-height:17px;font-size:11px;color:#777}
.suggest_layer .link_all:hover{text-decoration:none}
/* lnb - 정렬 */
.wrap_select{float:right;padding-top:1px;text-align:right}
.wrap_select .txt_bar{display:inline-block;width:2px;height:2px;margin:9px 2px 0;background:#888;vertical-align:top}
.wrap_select .opt_select{min-width:auto;border:none}
.wrap_select .opt_select .link_selected{min-height:20px;padding:0 36px 10px 0;font-weight:400;font-size:11px;color:#777;text-align:right}
.wrap_select .opt_select .tit_option{height:22px;line-height:17px;font-weight:400;font-size:11px;color:#999}
.wrap_select .opt_select .link_option{overflow:visible;height:24px;line-height:24px;font-size:12px;letter-spacing:0}
.wrap_select .opt_select .sub_listopt{margin-top:-3px}
.wrap_select .opt_select .sub_listopt .link_option{padding:0 0 0 16px}
.wrap_select .opt_select .ico_arr{display:none;right:19px;margin-top:-7px}/** 추후 사용될 수 있어 숨김처리 */
.wrap_select .link_selected .ico_menu{position:absolute;right:19px;top:50%;width:0;height:0;margin-top:-5px;border-left:4px solid transparent;border-right:4px solid transparent;border-top:5px solid #555;vertical-align:middle}
.wrap_select .opt_open .link_selected .ico_menu{border-top:0 none;border-bottom:5px solid #555}
.wrap_select .link_selected .ico_sort{display:inline-block;width:8px;height:11px;margin:5px 0 0 3px;background-position:-810px -205px;vertical-align:top;opacity:0.7;cursor:pointer}
.wrap_select .opt_select .opt_list{top:23px;left:auto;right:13px;min-width:93px;border-radius:4px;border:none;background:#fff;box-shadow:0px 2px 10px rgba(0,0,0,0.25)}
.nav_campaign .main_nav .ico_nav{top:23px}
.nav_campaign li .ico_nav{overflow:hidden;position:absolute;top:14px;left:20px;width:5px;height:5px;border-radius:5px;background-position:-755px -165px}
.nav_campaign li .ico_nav.ico_on{background-position:-765px -165px}
.nav_campaign li .ico_nav.ico_on:before{display:none}
.sortable .desc_sort{position:absolute;top:50%;right:32px;margin-top:-12px;font-weight:400;font-size:11px;color:#777;line-height:20px;text-decoration:none}
.sortable .menu_layer{left:auto;top:35px;right:-65px}
.sortable .menu_layer .link_menu{position:relative;margin-top:3px}
.wrap_select .opt_select .opt_list .ico_sort,.sortable .ico_sort{position:absolute;top:11px;right:10px;width:7px;height:10px;background-position:-810px -205px;opacity:0.2;cursor:pointer}
.sortable .ico_sort:hover{opacity:0.6}
.sortable .desc_sort .ico_sort{display:inline-block;position:static;margin:5px 0 0 3px;vertical-align:top}
.sortable.sort_on .ico_sort{opacity:0.6}
.sortable .menu_layer .ico_sort{top:13px;right:15px;opacity:0.5}
.wrap_select .opt_select .opt_list .ico_sort{display:none;top:7px;right:15px;opacity:0.5}
.wrap_select .opt_select .opt_list li:hover .ico_sort,.sortable .menu_layer .link_menu:hover .ico_sort{display:block}
.wrap_select .opt_select .opt_list li.on .link_option,.sortable .menu_layer .link_menu.on{color:#326edc;background:#F1F6FF}
.wrap_select .opt_select .opt_list li:hover .link_option,.sortable .menu_layer .link_menu:hover,.wrap_select .opt_select .opt_list li.on:hover .link_option,.sortable .menu_layer .link_menu.on:hover{background:rgba(0,0,0,0.03)}
.wrap_select .opt_select .opt_list li.on .ico_sort,.sortable .menu_layer .link_menu.on .ico_sort{display:block}
.wrap_select .opt_select .ico_sort.sort_up,.wrap_select .ico_sort.sort_up,.sortable .ico_sort.sort_up{background-position:-810px -205px}
.wrap_select .opt_select .ico_sort.sort_down,.sortable .ico_sort.sort_down{background-position:-820px -205px}
.wrap_select .opt_select .ico_sort.sort_up,.wrap_select .opt_select .ico_sort.sort_down{display:none}
.wrap_select .list_opt{padding-bottom:4px}
/* 공통 */
.fc_affirm{color:#37c333}/** 긍정 */
.fc_emph{color:#326edc}/** 강조 */
.fc_emph2{color:#111}/** 강조2 */
.fc_warn{color:#f64949}/** 경고 */
.fc_warn2{color:#F55353}/** 경고2 */
.fc_empty{color:#326edc}/** 링크,선택 */
.fc_info{color:#999}
.fw_bold{font-weight:700}/** 폰트굵게 */
.fw_bold2{font-weight:500}/** 폰트굵게2 */
.fs_line{text-decoration:underline}/** 폰트밑줄 */
.txt_error{font-size:12px;color:#F55353}/** 에러시 문구 */
.limit_line{display:inline-block;width:auto;max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;vertical-align:top}/** 말줄임시 */
.txt_limit{display:inline-block;overflow:hidden;height:21px;max-width:100%;-webkit-line-clamp:1;-webkit-box-orient:vertical;vertical-align:top;text-overflow:ellipsis;white-space:nowrap}
.txt_limit2{display:inline-block;display:-webkit-box;overflow:hidden;max-height:38px;max-width:100%;-webkit-line-clamp:2;-webkit-box-orient:vertical;vertical-align:top}
.txt_limit3{display:inline-block;display:-webkit-box;overflow:hidden;max-height:57px;max-width:100%;line-height:19px;-webkit-line-clamp:3;-webkit-box-orient:vertical;vertical-align:top}
.txt_limit4{display:inline-block;display:-webkit-box;overflow:hidden;max-height:76px;max-width:100%;line-height:19px;-webkit-line-clamp:4;-webkit-box-orient:vertical;vertical-align:top}
.txt_limit5{display:inline-block;display:-webkit-box;overflow:hidden;max-height:95px;max-width:100%;line-height:19px;-webkit-line-clamp:5;-webkit-box-orient:vertical;vertical-align:top}
.single_wrap{float:left;margin-left:10px}/** 버튼여러개 정렬시 공통 박스 */
.single_wrap_r{float:left;margin-right:10px}
.f_left{float:left}
.f_left .single_wrap:first-child{margin-left:0}
.f_right{float:right}
.align_c{text-align:center}
.align_r{text-align:right}
.align_l{text-align:left}
.ico_on{display:inline-block;overflow:hidden;width:10px;height:10px;border-radius:20px;font-size:0;line-height:0;text-indent:-9999px;vertical-align:top}
.ico_on:before{float:left;width:10px;height:10px;border-radius:20px;background-color:#326EDC;content:''}
.ico_off{display:inline-block;overflow:hidden;width:10px;height:10px;font-size:0;line-height:0;text-indent:-9999px;vertical-align:top}
.ico_off:before{float:left;width:10px;height:10px;border-radius:20px;background-color:#ccc;content:''}
.ico_off:after{margin-left:3px}
.ico_on.on_b,.ico_on.on_b:before{background-color:#326edc}
.ico_on.on_p,.ico_on.on_p:before{background-color:#9579fe}
.ico_recom{display:inline-block;width:32px;height:18px;background-position:-220px -100px}/** 추천 */
.ico_master{display:inline-block;width:40px;height:16px;background-position:-830px -165px}/** 마스터 */
.ico_screening{display:inline-block;width:43px;height:18px;background-position:-260px -80px}/** 심사중 */
.ico_fail{display:inline-block;width:14px;height:14px;margin:4px 0 0 5px;background-position:-870px -570px}/** 업데이트실패,지연 */
.ico_success{display:inline-block;width:14px;height:14px;margin:4px 0 0 5px;background-position:-870px -550px}/** 성공 */
.ico_set{display:inline-block;width:43px;height:18px;margin:6px 0 0 6px;background-position:-400px -310px}/** 설정됨 */
.ico_beta{display:inline-block;width:22px;height:9px;background-position:-400px -400px}/** beta */
.ico_info{display:inline-block;width:14px;height:14px;margin-right:5px;background-position:-480px -140px}/** 안내사항 느낌표 */
.ico_del{display:inline-block;width:10px;height:10px;background-position:-715px -500px}
.ico_cancel{display:inline-block;width:31px;height:16px;background-position:-790px -500px}
.link_txt2{color:#326edc;text-decoration:underline}
/* 펼치기-접기 */
.txt_dash{display:inline-block;padding:0 4px;vertical-align:top}
/* 공통 - 아이콘링크 */
.link_g{display:inline-block;width:20px;height:20px;text-decoration:none;text-align:center;vertical-align:top}
.link_g .ico_comm{width:12px;height:12px;margin:4px auto 2px}
.link_g .ico_more{background-position:-480px 0}
.link_g:hover .ico_more{background-position:-480px -20px}
.link_g .ico_modify{width:13px;height:13px;background-position:-885px -180px}
.link_g:hover .ico_modify{background-position:-885px -195px}
.link_g .ico_help{background-position:-865px -30px;opacity:0.25}
th .link_g .ico_help{margin-top:5px}
.link_g:hover .ico_help{background-position:-865px -30px;opacity:1}
.link_g .ico_help2{width:18px;height:18px;margin:1px auto;background-position:0 -140px}
.link_g:hover .ico_help2{background-position:-30px -140px}
.link_g .ico_info{background-position:-885px -210px}
/* 파일업로드버튼 */
.box_inpfile{display:inline-block;position:relative;vertical-align:top}
.box_inpfile .inp_file{overflow:hidden;position:absolute;left:0;top:0;z-index:-1;width:100%;height:100%;text-indent:-9999px;font-size:99px;opacity:0.01;filter:alpha(opacity=0);cursor:pointer}
.box_inpfile .lab_file{cursor:pointer}
/* 공통 - 버튼 */
.btn_gb{border:1px solid transparent;border-radius:2px;font-weight:700;color:#555;background:none;text-align:center}
.btn_gb.in_active{color:#ccc;cursor:default;pointer-events:none}
/* 버튼_대 */
.btn_gb{display:inline-block;height:40px;vertical-align:top}
.btn_gb .inner_g{display:block;padding:9px 20px 9px;font-size:13px;line-height:20px}
button.btn_gb{height:42px}
a.btn_gb{text-decoration:none}
.btn_gb:hover{background-color:rgba(0,0,0,0.08)}
/* 버튼_중 */
.btn_gm{display:inline-block;height:34px;vertical-align:top}
.btn_gm{border:1px solid transparent;border-radius:2px;font-weight:700;color:#555;background:none;text-align:center}
.btn_gm:hover{background-color:rgba(0,0,0,0.08);border-color:transparent}
.btn_gm.in_active{color:#ccc;cursor:default;pointer-events:none}
.btn_gm .inner_g{display:block;padding:7px 15px 8px;font-size:12px;line-height:19px}
.btn_gm .icon_g{overflow:hidden;min-width:34px;height:34px;padding:0}
.btn_gm.in_active .icon_g{background-color:#f5f5f5}
.btn_gm.in_active .ico_comm{opacity:.3}
button.btn_gm{height:36px}
a.btn_gm{text-decoration:none}
.btn_gm .inp_file{overflow:hidden;position:absolute;left:0;top:0;width:100%;height:36px;text-indent:-9999px;font-size:99px;opacity:0.01;filter:alpha(opacity=0);cursor:pointer}
.btn_gm .lab_file{display:block;font-size:12px}
/* 버튼(min-width:114사이즈) - 중앙정렬 */
.btn_gw{display:inline-block;position:relative;vertical-align:top;height:34px;min-width:112px;text-align:center;border:1px solid #ddd;border-radius:2px;font-weight:700;color:#555;background-color:#fff}
button.btn_gw{min-width:114px;height:36px}
.btn_gw .inner_g{display:block;padding:7px 15px 8px;font-size:12px;line-height:19px}
.btn_gw .inp_file{overflow:hidden;position:absolute;left:0;top:0;width:100%;height:36px;text-indent:-9999px;font-size:99px;opacity:0.01;filter:alpha(opacity=0);cursor:pointer}
.btn_gw .lab_file{display:block;font-size:12px}
.btn_gw .ico_add{width:10px;height:10px;margin:5px 7px 0 0;background-position:-50px -120px}
.btn_gw:hover{border-color:rgba(0,0,0,0.08);background:rgba(0,0,0,0.08)}
a.btn_gw:hover{text-decoration:none}
.btn_gw.in_active{color:#ccc;cursor:default;pointer-events:none}
.btn_gw.in_active .ico_add{opacity:0.3}
.btn_gw .area_loading{min-height:20px}
.btn_gw.btn_bl{background-color:#326EDC;border-color:rgba(0,0,0,0.08);color:#fff}
.btn_gw.btn_bl .ico_add{background-position:-20px -30px}
.btn_gw.btn_bl:hover{background-color:#1F57BE}
.btn_gw.btn_bl.in_active{border-color:#ccc;background-color:#ccc}
.btn_gw.btn_bl.in_active .ico_add{opacity:1}
.btn_gw + .btn_gw{margin-left:7px}
/* 버튼_소 */
.btn_gs{display:inline-block;height:28px;border:1px solid rgba(0,0,0,0.15);border-radius:2px;font-weight:700;color:#555;background:#fff;text-align:center;vertical-align:top}
.basic_layer .btn_gs{height:36px}
.btn_gs .inner_g{display:block;padding:6px 9px 7px;font-size:11px;line-height:17px}
.basic_layer .btn_gs .inner_g{padding:10px 9px 11px}
.btn_gs:hover{background-color:#f5f5f5}
.btn_gs.in_active{color:#ccc;cursor:default;pointer-events:none}
.btn_gs .icon_g{overflow:hidden;min-width:24px;height:28px;padding:0}
button.btn_gs{height:30px}
a.btn_gs{text-decoration:none}
/* 강조2 */
.gb_line{border-color:rgba(0,0,0,0.08);background:#f4f4f4}
.dsp_tit .gm_line,.layer_foot .gm_line{border-color:rgba(0,0,0,0.08);background:#f4f4f4}
.gm_line{border-color:rgba(0,0,0,0.15);background:#fff}
.gm_line:hover{border-color:rgba(0,0,0,0.15);background-color:rgba(0,0,0,0.08)}
/* 강조 */
.gb_bl{color:#fff;border-color:rgba(0,0,0,0.08);background-color:#326edc}
.gb_bl:hover{background-color:#1F57BE}
.gb_bl.in_active{color:#fff;border-color:rgba(184,156,156,0.03);background-color:#ccc}
.gm_bl{border-color:rgba(0,0,0,0.08);color:#fff;background-color:#326EDC}
.gm_bl:hover{border-color:rgba(0,0,0,0.08);background-color:#1F57BE}
.gm_bl.in_active{color:#fff;border-color:rgba(179,160,160,0.03);background-color:#ccc}
.gs_bl{color:#fff;border-color:rgba(0,0,0,0.08);background-color:#326edc}
.gs_bl:hover{background-color:#1F57BE}
.gs_bl.in_active{color:#fff;border-color:rgba(0,0,0,0.03);background-color:#ccc}
/* 어두운배경 버튼 */
.gm_bk{color:#fff;border-color:rgba(255,255,255,0.8);background:none}
.gm_bk:hover{border-color:white}
.gm_bk.in_active:hover{border-color:rgba(255,255,255,0.8)}
.gs_bk{color:#fff;border-color:rgba(255,255,255,0.8);background:none}
.gs_bk:hover{border-color:#fff}
/* 버튼(대) - 아이콘 */
.btn_gb .ico_add{width:12px;height:12px;margin:4px 7px 0 0;background-position:0 -30px}
/* 버튼(중) - 아이콘 */
.btn_gm .ico_add{width:10px;height:10px;margin:5px 7px 0 0;background-position:-50px -120px}
.btn_gm.gm_bl .ico_add{background-position:-20px -30px}
.btn_gm .ico_download{width:14px;height:18px;margin:8px auto;background-position:-40px -30px}
.btn_gm .ico_download2{display:inline-block;width:14px;height:18px;margin:2px 7px 0 0;background-position:-40px -30px}
.btn_gm .ico_download3{width:10px;height:14px;background-position:-60px -30px}
.btn_gm .ico_modify{width:16px;height:16px;margin:9px auto;background-position:-60px 0}
.btn_gm .ico_del{width:12px;height:14px;margin:9px auto;background-position:-885px -630px}
.btn_gm .ico_copy{width:16px;height:16px;margin:9px auto;background-position:-420px -180px}
.btn_gm .ico_more{width:4px;height:16px;margin:9px auto;background-position:-90px 0}
.btn_gm .ico_on{margin:4px 4px 0 0}
.btn_gm .ico_off{margin:4px 4px 0 0}
.btn_gm .ico_doc{width:15px;height:18px;margin:8px auto;background-position:-40px 0}
.btn_gm .ico_refresh{width:19px;height:19px;margin:8px auto;background-position:-720px -605px}
.btn_gm .ico_refresh2{width:14px;height:13px;margin:0;font-size:0;line-height:0;background-position:-795px -550px}
.btn_gm .ico_prev{width:6px;height:10px;margin:12px auto 0;background-position:-270px -20px}
.btn_gm .ico_next{width:6px;height:10px;margin:12px auto 0;background-position:-280px -20px}
span.btn_gm{cursor:default;pointer-events:none}
span.btn_gm .ico_prev{background-position:-270px 0}
span.btn_gm .ico_next{background-position:-280px 0}
/* 버튼(소) - 아이콘 */
.btn_gs .ico_download{width:10px;height:14px;margin:7px auto;background-position:-60px -30px}
.btn_gs .ico_modify{width:12px;height:12px;margin:6px auto;background-position:-481px -61px}
.btn_gs .ico_add{width:10px;height:10px;margin:5px 8px 0 0;background-position:-50px -120px}
/* 커플버튼(2개이상) */
.couple_btn{display:inline-block;vertical-align:top}
.couple_btn:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.couple_btn .btn_gm,.couple_btn .btn_gs,.couple_btn .layer_btn{float:left;border-radius:2px;margin-left:-1px;border-color:#ddd}
.couple_btn .btn_gm:first-child,.couple_btn .btn_gs:first-child,.couple_btn .layer_btn:first-child{margin-left:0}
.couple_btn .btn_gm + .btn_gm,.couple_btn .btn_gs + .btn_gs,.couple_btn .layer_btn:last-child{border-radius:0 2px 2px 0}
.couple_btn.onoff_btn .btn_gm:first-child,.couple_btn.onoff_btn .btn_gs:first-child,.couple_btn.onoff_btn .layer_btn:first-child{margin-left:0;border-radius:2px 0 0 2px;border-left-color:rgba(0,0,0,0.15)}
.couple_btn.onoff_btn .btn_gm:last-child,.couple_btn.onoff_btn .btn_gs:last-child,.couple_btn.onoff_btn .layer_btn:last-child{border-radius:0 2px 2px 0;border-right-color:rgba(0,0,0,0.15);border-left-color:rgba(0,0,0,0)}
.couple_btn.onoff_btn .btn_gm{min-width:57px}
.couple_btn .btn_gm + .btn_gm{border-left-color:#ddd}
.dashboard_check button.btn_gm + button.btn_gm{border-left-color:rgba(0,0,0,0.15)}
/* 체크박스(선택형) */
.filter_check{overflow:hidden}
.filter_check .choice_item{float:left;margin-left:-1px}
.filter_check .choice_item:first-child{margin:0}
.filter_check .choice_item:first-child .lab_choice{border-radius:2px 0 0 2px}
.filter_check .choice_item:last-child .lab_choice{border-radius:0 2px 2px 0}
.filter_check.in_active .choice_item .lab_choice{color:#ccc;border-color:#ccc;background:#F5F5F5;cursor:default;pointer-events:none}
.filter_check.in_active .choice_item:hover .lab_choice{background-position:0 -180px}
.filter_check.in_active .inp_choice:disabled + .lab_choice,.filter_check.in_active .choice_item.on .lab_choice{color:#ccc;border-color:#ccc;background:#F5F5F5;cursor:default;pointer-events:none}
.choice_item{display:inline-block;position:relative;vertical-align:top}
.choice_item .inp_choice{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.choice_item .lab_choice{display:block;position:relative;height:34px;padding:0 13px;border-width:1px;border-style:solid;border-color:#ddd;font-size:13px;line-height:33px;color:#333;background-position:0 -180px;text-align:center;cursor:pointer}
.area_choice .choice_item:first-child .lab_choice{border-radius:2px 0 0 2px}
.area_choice .choice_item:last-child .lab_choice{border-radius:0 2px 2px 0}
.choice_item + .choice_item .lab_choice{border-left-color:#d9d9d9}
.choice_item.on .lab_choice,.choice_item .inp_choice:checked + .lab_choice{z-index:1;border-color:#326edc;background:#ECF3FF;font-weight:500;color:#326edc}
.choice_item.in_active .lab_choice{cursor:default;pointer-events:none}
.choice_item.in_active .inp_choice:disabled + .lab_choice,.choice_item.in_active .lab_choice{color:#ccc;border-color:#ccc;background:#F5F5F5;cursor:default;pointer-events:none}
.choice_item.in_active:hover .lab_choice{background-position:0 -180px}
.choice_item2{display:block;position:relative}
.choice_item2 .inp_choice{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.choice_item2 .lab_choice{display:block;padding-right:14px;font-size:14px;color:#111;cursor:pointer}
.choice_item2 .lab_choice .link_txt{display:block;overflow:hidden;white-space:nowrap;letter-spacing:-0.06em}
.choice_item2 .lab_choice .ico_check{display:none;position:absolute;right:0;top:50%;width:14px;height:10px;margin-top:-5px;background-position:-40px -500px}
.choice_item2.on .lab_choice .ico_check{display:block}
.choice_item2 .inp_choice:checked + .lab_choice .ico_check{display:block}
/* 스케줄러 */
.sch_wrap{position:relative;width:609px;margin-top:17px;border-radius:2px;border:1px solid #e5e5e5}
.sch_wrap.in_error{border-color:#F55353}
.sch_wrap .info_sch{overflow:hidden;position:absolute;right:0;top:-36px}
.sch_wrap .info_sch .sch_choice{float:left;margin-left:29px;font-size:14px;color:#333}
.sch_wrap .info_sch .sch_choice:before{float:left;width:16px;height:16px;margin:1px 10px 0 0;border-radius:2px;border:1px solid #dae3f0;content:''}
.sch_wrap .info_sch .sch_choice.on:before{border-color:#2d63c6;background-color:#326edc}
.sch_wrap .info_sch .sch_choice.off:before{border-color:#ebebeb;background-color:#f5f5f5}
.sch_wrap .drag_wrap{position:absolute;background-color:rgba(0,0,0,0.1)}
.itembox_set .tbl_sch tbody td{border-top:1px solid #ddd}
.tbl_sch{width:100%;background-color:#fff;border-collapse:collapse;border-spacing:0;text-align:center}
.tbl_sch thead{border-bottom:1px solid #ddd}
.tbl_sch th,.tbl_sch td{width:auto;text-align:center}
.tbl_sch thead th{height:25px;font-size:12px;font-weight:400;color:#333;border-left:1px solid #ddd;background-color:#fff;-webkit-user-select:none}
.tbl_sch thead th:first-child{border-left:0 none}
.tbl_sch tbody th{height:24px;font-size:12px;color:#333;font-weight:400;background-color:#fff;border-bottom:1px solid #ddd;border-right:1px solid #ddd;background-color:#fff;-webkit-user-select:none}
.tbl_sch tbody td{position:relative;height:22px;border-left:1px solid #ddd;border-top:1px solid #ddd}
.tbl_sch tbody tr:first-child td{border-top:0 none}
.tbl_sch tbody tr:last-child th,.tbl_sch tbody tr:last-child td{border-bottom:0 none}
.tbl_sch tbody tr:last-child td:last-child.on .check_sch .lab_option{border-radius:0 0 2px 0}
.tbl_sch tbody tr:last-child td.over .check_sch .lab_option:after,.tbl_sch tbody tr:last-child td.check_sch:hover .lab_option:after{bottom:0;border-bottom:none}
.tbl_sch .th_tit .tit_wrap{position:relative;width:100%;height:100%}
.tbl_sch .th_tit .tit_week,.tbl_sch .th_tit .week_time{display:block;visibility:hidden;height:0;font-size:0;clear:both}
.tbl_sch .txt_week{display:block;position:relative;z-index:1;margin:0 auto}
.tbl_sch .check_sch{position:relative;width:100%;height:100%}
.tbl_sch .check_sch .inp_check{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;border:0 none;opacity:.01}
.tbl_sch .check_sch .lab_option{display:block;position:relative;width:100%;height:100%;box-sizing:border-box;line-height:24px}
.tbl_sch thead .check_sch .lab_option{line-height:25px}
.tbl_sch tbody td .check_sch{height:24px}
.tbl_sch td .check_sch .lab_option{background-color:#f5f5f5}
.tbl_sch .on .check_sch .lab_option{background-color:#467cdf}
.tbl_sch .over .check_sch .lab_option:after,.tbl_sch .check_sch:hover .lab_option:after{position:absolute;left:-1px;top:-1px;right:-1px;bottom:-1px;border:1px solid #cbcbcb;background-color:rgba(0,0,0,0.08);content:''}
.tbl_sch .none .check_sch .lab_option:after{display:none}
.tbl_sch tr.in_active th .check_sch{opacity:0.4}
.tbl_sch tr.in_active .check_sch{cursor:default;pointer-events:none}
.in_error .tbl_sch thead .over .check_sch .lab_option:after,.in_error .tbl_sch thead th:hover .lab_option:after{border-top-color:#F55353}
.in_error .tbl_sch tbody th.over .check_sch .lab_option:after,.in_error .tbl_sch tbody th:hover .lab_option:after{border-left-color:#F55353}
.in_error .tbl_sch thead th:last-child .check_sch:hover .lab_option:after,.in_error .tbl_sch tbody td:last-child.over .check_sch .lab_option:after,.in_error .tbl_sch tbody td:last-child .check_sch:hover .lab_option:after{border-right-color:#F55353}
.in_error .tbl_sch tbody tr:last-child th:hover .lab_option:after,.in_error .tbl_sch tbody tr:last-child td:hover .lab_option:after{border-bottom-color:#F55353}
/* 캘린더*/
/* 달력버튼 */
.gm_calendar,.gm_calendar:hover{position:relative;border:none;background:none}
.gm_calendar .link_calendar{position:relative;display:inline-block;min-width:156px;height:36px;padding:0 50px 0 16px;border-radius:2px;border:1px solid #ddd;font-size:14px;line-height:33px;font-weight:400;color:#555;background:#fff;text-decoration:none;text-align:left;box-sizing:border-box}
.gm_calendar .link_calendar:hover{border:1px solid #333;background:#fff}
.gm_calendar .link_calendar + .link_calendar{margin-left:21px}
.gm_calendar .link_calendar + .link_calendar:before{position:absolute;top:0;left:-16px;width:18px;line-height:36px;color:#222;vertical-align:top;content:'~'}
.gm_calendar .ico_calendar{position:absolute;top:10px;right:11px;width:12px;height:14px;background-position:-865px -90px}
.gm_calendar .link_calendar.on{border-color:#333}
.gm_calendar .link_calendar.in_active{border-color:#ccc;color:#ccc;background:#f5f5f5}
.gm_calendar .link_calendar.in_active .ico_calendar{opacity:0.25}
.gm_calendar + .txt_bar{display:inline-block;width:18px;line-height:32px;text-align:center}
.gm_calendar .link_calendar.in_error{border-color:#f55353}
/* 공통 - 날짜선택(달력) 레이어 */
.calendar_layer{display:none;position:absolute;left:0;top:46px;z-index:20;border-collapse:separate}
.calendar_layer_right{left:auto;right:0}
.gm_calendar.open .calendar_layer{display:block}
.calendar_layer .layer_head{display:none}
.calendar_layer .layer_body{position:relative}
.calendar_layer .layer_body:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.calendar_layer .inner_calendar_layer{width:542px;border-radius:2px;background-color:#fff;box-shadow:0 0 10px rgba(0,0,0,0.25)}
.single_calendar .calendar_layer .inner_calendar_layer{width:auto}/** 달력1개인 경우 */
.calendar_layer .list_period{width:145px;padding:16px 0}
.calendar_layer .list_period li{display:block;padding-left:20px;margin-top:6px;text-align:left}
.calendar_layer .list_period li:first-child{margin-top:0}
.calendar_layer .link_period{display:block;height:32px;border-radius:2px;line-height:31px;font-weight:400;font-size:14px;color:#333;text-decoration:none}
.calendar_layer .item_help .link_period{display:inline-block;vertical-align:top}
.calendar_layer .item_help .link_help{margin-top:2px}
.calendar_layer .list_period li:hover{background:rgba(0,0,0,0.03)}
.calendar_layer .list_period li:hover .link_period{color:#333}
.calendar_layer .on{background:#f1f6fc}
.calendar_layer .on .link_period{color:#326edc}
.calendar_layer .date_calendar{position:relative;padding:0 24px 69px}
.calendar_layer .datecalendar_wrap{display:table;width:100%;height:100%}
.calendar_layer .inner_datecalendar{display:table-cell;vertical-align:middle}
.calendar_layer .box_datecalendar{position:relative;padding:0 0 30px}
.calendar_layer .box_datecalendar:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.calendar_layer .setinfo_calendar{display:block;width:100%;height:61px;border-bottom:1px solid #f2f2f2;font-weight:500;font-size:15px;line-height:61px;color:#999;text-align:center}
.calendar_layer .setinfo_calendar .txt_setdate{color:#333}
.calendar_layer .btn_move{position:absolute;top:24px;width:35px;height:30px}
.calendar_layer .btn_move .ico_arr{width:10px;height:16px;margin:0 auto}
.calendar_layer .btn_prev{left:16px}
.calendar_layer .btn_prev .ico_arr{background-position:-875px -60px}
.calendar_layer .btn_next{right:18px}
.calendar_layer .btn_next .ico_arr{background-position:-885px -60px}
.calendar_layer .btn_move.in_active .ico_arr{opacity:0.4;filter:alpha(opacity=40)}
.calendar_layer .date_calendar .btn_wrap{position:absolute;bottom:0;left:0;right:0;padding:16px 24px;border-top:1px solid rgba(0,0,0,0.08)}
.calendar_layer .date_calendar:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.calendar_layer .setdetail_calendar{position:relative}
.calendar_layer .setdetail_calendar:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.calendar_layer .area_calendar{float:right;position:relative;width:239px;margin:25px -4px 0 0}
.calendar_layer .setinfo_calendar + .area_calendar,.calendar_layer .setinfo_calendar + .area_calendar + .area_calendar{margin-top:87px}
.calendar_layer .area_calendar:first-of-type{float:left;margin-left:-3px}
.single_calendar .calendar_layer .box_datecalendar{padding-top:2px}
.calendar_layer .tit_date{display:block;padding-top:2px;padding-bottom:28px;font-weight:500;font-size:14px;color:#111;text-align:center}
.calendar_layer .tbl_calendar{border:0 none;border-collapse:collapse;text-align:center}
.calendar_layer .tbl_calendar th{height:28px;line-height:28px;font-weight:400;font-size:12px;color:#555;width:36px}
.calendar_layer .tbl_calendar th,.calendar_layer .tbl_calendar td{border:0 none;background:transparent;text-align:center}
.calendar_layer .tbl_calendar td:first-child .link_day{color:#f8626c}
.calendar_layer .tbl_calendar td:last-child .link_day{color:#409eff}
.calendar_layer .tbl_calendar td:first-child .link_day:before,.calendar_layer .tbl_calendar td.fst .link_day:before{position:absolute;top:0;left:0;width:14px;height:28px;background:#fff;content:''}
.calendar_layer .tbl_calendar td:last-child .link_day:before,.calendar_layer .tbl_calendar td.lst .link_day:before{position:absolute;top:0;right:0;width:14px;height:28px;background:#fff;content:''}
.calendar_layer .tbl_calendar .link_day{position:relative;display:inline-block;width:100%;height:28px;margin-top:8px;font-size:14px;line-height:27px;font-weight:400;color:#333;text-decoration:none;vertical-align:top}
.calendar_layer .tbl_calendar .inner_link_day{position:absolute;left:50%;top:0;z-index:2;width:28px;height:28px;margin-left:-14px;border-radius:14px}
.calendar_layer .tbl_calendar .inner_link_day:hover{background-color:rgba(0,0,0,0.05)}
.calendar_layer .tbl_calendar .today .inner_link_day{border-radius:14px;color:#fff;background-color:#37c333}
.calendar_layer .tbl_calendar .today .inner_link_day:hover{background-color:#03bc13}
.calendar_layer .tbl_calendar .selected .link_day,.calendar_layer .tbl_calendar .selected .inner_link_day{background-color:#f1f6fc}
.calendar_layer .tbl_calendar .selected .inner_link_day:hover{background:#e5e9ef}
.calendar_layer .tbl_calendar .selected .link_day{color:#333;background-color:#f1f6fc}
.calendar_layer .tbl_calendar .selected .link_day:hover{border-radius:0}
.calendar_layer .tbl_calendar .selected_only .link_day{background-color:#fff;color:#fff}
.calendar_layer .tbl_calendar .selected_start .link_day,.calendar_layer .tbl_calendar .selected_end .link_day{position:relative;color:#fff}
.calendar_layer .tbl_calendar .selected_start .link_day:hover,.calendar_layer .tbl_calendar .selected_end .link_day:hover{background-color:transparent}
.calendar_layer .tbl_calendar .selected.selected_start .link_day,.calendar_layer .tbl_calendar .selected.selected_end .link_day{background-color:#f1f6fc}
.calendar_layer .tbl_calendar .selected_only .link_day .inner_link_day,.calendar_layer .tbl_calendar .selected_start .inner_link_day,.calendar_layer .tbl_calendar .selected_end .inner_link_day{color:#fff;background:#326edc}
.calendar_layer .tbl_calendar .selected_only .link_day:hover .inner_link_day,.calendar_layer .tbl_calendar .selected_start .link_day:hover .inner_link_day,.calendar_layer .tbl_calendar .selected_end .link_day:hover .inner_link_day{background:#1e57be}
.calendar_layer .tbl_calendar .selected_start .link_day:after,.calendar_layer .tbl_calendar .selected_end .link_day:after{position:absolute;top:0;z-index:1;width:14px;height:28px;background:#fff;content:''}
.calendar_layer .tbl_calendar .selected_start .link_day:after{left:0}
.calendar_layer .tbl_calendar .selected_end .link_day:after{right:0}
.calendar_layer .tbl_calendar .period .link_day,.calendar_layer .tbl_calendar .period .inner_link_day,.calendar_layer .tbl_calendar .period_start .inner_link_day,.calendar_layer .tbl_calendar .period_end .inner_link_day{background-color:#f7f7f7}
.calendar_layer .tbl_calendar .period_only .link_day .inner_link_day:hover,.calendar_layer .tbl_calendar .period .inner_link_day:hover{background-color:#eaeaea}
.calendar_layer .tbl_calendar .period .link_day{color:#333;background-color:#f7f7f7}
.calendar_layer .tbl_calendar .period_only .link_day{background-color:#fff}
.calendar_layer .tbl_calendar .period_only .link_day .inner_link_day{background-color:#f7f7f7}
.calendar_layer .tbl_calendar .period_start .link_day,.calendar_layer .tbl_calendar .period_end .link_day{position:relative}
.calendar_layer .tbl_calendar .period_start .link_day:hover,.calendar_layer .tbl_calendar .period_end .link_day:hover{background-color:#f7f7f7}
.calendar_layer .tbl_calendar .period_start .link_day:after,.calendar_layer .tbl_calendar .period_end .link_day:after{position:absolute;top:0;z-index:1;width:14px;height:28px;background:#fff;content:''}
.calendar_layer .tbl_calendar .period_start .link_day:after{left:0}
.calendar_layer .tbl_calendar .period_end .link_day:after{right:0}
.calendar_layer .layer_foot{padding:12px 0;border-top:1px solid #e6e6e6;background-color:#f5f5f5;text-align:center}
.calendar_layer .btn_wrap{display:inline-block;height:36px;vertical-align:top}
.calendar_layer .btn_wrap .btn_gm{float:right;height:34px;margin-left:8px}
.calendar_layer .btn_wrap .gm_bl{border-color:rgba(0,0,0,0.07)}
.calendar_layer .btn_wrap button.btn_gm{height:36px}
.calendar_layer .btn_wrap .btn_gm .inner_g{padding:7px 15px 8px;font-size:12px;line-height:19px}
.os_mac .calendar_layer .btn_wrap .btn_gm .inner_g{padding:7px 15px 8px}
.calendar_layer .area_calendar.in_active{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
.calendar_layer .in_active .link_day{opacity:0.4;filter:alpha(opacity=50);cursor:default;pointer-events:none}
.calendar_layer .in_active .link_day:hover{background:none}
.freeset_calendar .calendar_layer .inner_calendar_layer{width:688px}
.freeset_calendar .calendar_layer .inner_calendar_layer .layer_body{display:block;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-height:445px;background:url(https://t1.daumcdn.net/biz/ui/keywordad/bg_freesetcalendar.gif) repeat-y 0 0}
.freeset_calendar .calendar_layer .inner_calendar_layer .date_calendar{width:494px}
/* 광고그룹생성 - 날짜선택(달력) 레이어 */
.calendar_layer .inner_calendar_layer2{width:584px;border:0 none;border-radius:0;-webkit-box-shadow:0 0 8px rgba(0,0,0,0.3);box-shadow:0 0 8px rgba(0,0,0,0.3)}
.calendar_layer .inner_calendar_layer2 .date_calendar{padding:14px 0 20px}
.calendar_layer .area_calendar .btn_move{top:-4px}
.calendar_layer .area_calendar .btn_prev{left:0}
.calendar_layer .area_calendar .btn_next{right:0}
/* 광고그룹생성 - 요일선택 레이어 */
.calendar_layer .inner_calendar_layer3{width:566px;border:0 none;border-radius:0;-webkit-box-shadow:0 0 8px rgba(0,0,0,0.3);box-shadow:0 0 8px rgba(0,0,0,0.3)}
.calendar_layer .date_detailtool{padding-bottom:20px}
.calendar_layer .detailtool_wrap{padding-top:10px}
.calendar_layer .detailtool_wrap dt{float:left;width:120px;height:36px;padding-left:20px;line-height:33px;color:#555;text-align:left}
.calendar_layer .detailtool_wrap dd{min-height:36px;margin-left:140px}
.calendar_layer .detailtool_wrap .list_day{overflow:hidden}
.calendar_layer .detailtool_wrap .list_day li{float:left;margin-left:20px}
.calendar_layer .detailtool_wrap .list_day li:first-child{margin-left:0}
.calendar_layer .detailtool_wrap .list_day .box_checkinp{height:36px;line-height:33px}
.calendar_layer .detailtool_wrap .opt_select{float:left}
.calendar_layer .detailtool_wrap{text-align:left}
.calendar_layer .detailtool_wrap .txt_bar{float:left;width:20px;height:36px;line-height:33px;text-align:center}
.calendar_layer .detailtool_wrap .txt_notice{float:none;clear:both;padding:11px 20px 0 0;margin-bottom:-4px;font-size:13px;color:#777}
.calendar_layer .detailtool_wrap .txt_error{margin:9px 0 -6px}
/* 소재생성 - 날짜선택 하루만 선택 레이어 */
.layer_choiceday{display:none;position:absolute;left:0;top:46px;z-index:20;width:253px;padding:14px 20px 20px;background-color:#fff;-webkit-box-shadow:0 0 8px rgba(0,0,0,0.3);box-shadow:0 0 8px rgba(0,0,0,0.3)}
.layer_choiceday .tit_date{display:block;padding-bottom:8px;color:#111;font-weight:500;text-align:center}
.layer_choiceday .tbl_calendar{border:0 none;border-collapse:collapse;text-align:center}
.layer_choiceday .tbl_calendar th{width:36px;padding-bottom:6px;font-weight:500;font-size:11px;color:#777;text-align:center}
.layer_choiceday .tbl_calendar th,.layer_choiceday .tbl_calendar td{border:0 none;background:transparent}
.layer_choiceday .tbl_calendar th:first-child{color:#f05a5e}
.layer_choiceday .tbl_calendar .link_day{display:block;height:27px;margin-top:5px;font-size:12px;line-height:27px;font-weight:500;color:#555;text-decoration:none}
.layer_choiceday .tbl_calendar .link_day:hover{border-radius:2px;color:#111;background-color:#e5e5e5}
.layer_choiceday .tbl_calendar td:first-child .link_day{color:#f05a5e}
.layer_choiceday .tbl_calendar .selected .link_day{background-color:#f1f6ff}
.layer_choiceday .tbl_calendar .selected .link_day:hover{border-radius:0}
.layer_choiceday .tbl_calendar .selected:first-child .link_day{border-radius:2px 0 0 2px}
.layer_choiceday .tbl_calendar .selected.fst .link_day{border-radius:2px 0 0 2px}
.layer_choiceday .tbl_calendar .selected.lst .link_day{border-radius:0 2px 2px 0}
.layer_choiceday .tbl_calendar .selected_only .link_day{border-radius:2px;background-color:#326edc;color:#fff}
.layer_choiceday .tbl_calendar .selected_start .link_day{position:relative;color:#fff;border-radius:2px 0 0 2px}
.layer_choiceday .tbl_calendar .selected_end .link_day{position:relative;color:#fff;border-radius:0 2px 2px 0}
.layer_choiceday .tbl_calendar .selected_start .img_comm{position:absolute;left:0;right:6px;top:0;z-index:1;width:100%;height:100%;padding-right:6px;background-position:-220px -20px}
.layer_choiceday .tbl_calendar .selected_end .img_comm{position:absolute;left:-6px;right:0;top:0;z-index:1;width:100%;height:100%;padding-left:6px;background-position:-220px -50px}
.layer_choiceday .in_active .link_day{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
.layer_choiceday .in_active .link_day:hover{background:none}
.layer_choiceday .btn_move{position:absolute;top:10px;width:35px;height:30px}
.layer_choiceday .btn_move .ico_arr{width:6px;height:10px;margin:0 auto}
.layer_choiceday .btn_prev{left:18px}
.layer_choiceday .btn_prev .ico_arr{background-position:-160px -40px}
.layer_choiceday .btn_next{right:18px}
.layer_choiceday .btn_next .ico_arr{background-position:-170px -40px}
.layer_choiceday .btn_move.in_active .ico_arr{opacity:0.4;filter:alpha(opacity=40)}
/* 라디오버튼(선택형) */
.filter_radio:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.filter_radio .box_radioinp{float:left;margin-left:40px}
.filter_radio .box_radioinp:first-child{margin-left:0}
.filter_radio .box_radioinp .lab_radio{color:#333}
/* 토글버튼 - 라디오(기본형) */
.toggle_radio{display:inline-block;vertical-align:top}
.toggle_radio:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.toggle_radio .radio_g{float:left;position:relative;width:36px;height:36px;margin-left:-1px}
.toggle_radio .inp_radio{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.toggle_radio .lab_radio{display:block;position:relative;height:34px;border-width:1px;border-style:solid;border-color:#ddd;background:#fff;cursor:pointer}
.toggle_radio .radio_g + .radio_g .lab_radio{border-left-color:#d9d9d9}
.toggle_radio .radio_g:first-child{margin:0}
.toggle_radio .radio_g:hover .lab_radio{background-position:0 -220px}
.toggle_radio .radio_g.on .lab_radio{z-index:1;border-color:#ddd;background-position:0 -300px}
/* .toggle_radio .inp_radio:checked + .lab_radio{z-index:1;border-color:#ddd;background-position:0 -300px} */
.toggle_radio .ico_comm{display:block;width:16px;height:16px;margin:9px auto}
.toggle_radio .ico_layout1{background-position:-80px -30px}
.toggle_radio .ico_layout2{background-position:-100px -30px}
.toggle_radio .ico_layout3{background-position:-120px -30px}
.toggle_radio .radio_g.on .ico_layout1{background-position:-80px -50px}
.toggle_radio .radio_g.on .ico_layout2{background-position:-100px -50px}
.toggle_radio .radio_g.on .ico_layout3{background-position:-120px -50px}
.toggle_radio .inp_radio:checked + .lab_radio .ico_layout1{background-position:-80px -50px}
.toggle_radio .inp_radio:checked + .lab_radio .ico_layout2{background-position:-100px -50px}
.toggle_radio .inp_radio:checked + .lab_radio .ico_layout3{background-position:-120px -50px}
.toggle_radio2{display:inline-block;vertical-align:top}
.toggle_radio2:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.toggle_radio2 .radio_g{float:left;position:relative;width:46px;height:28px;margin-left:-1px}
.toggle_radio2 .radio_g + .radio_g .lab_radio{border-left-color:#d9d9d9}
.toggle_radio2 .radio_g:first-child{margin:0}
.toggle_radio2 .radio_g:first-child .lab_radio{border-radius:2px 0 0 2px}
.toggle_radio2 .radio_g:last-child .lab_radio{border-radius:0 2px 2px 0}
.toggle_radio2 .inp_radio{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.toggle_radio2 .lab_radio{display:block;position:relative;height:26px;border-width:1px;border-style:solid;border-color:#d9d9d9;font-size:12px;line-height:26px;color:#555;background:#eee;text-align:center;cursor:pointer}
.toggle_radio2 .radio_g.on .lab_radio,.toggle_radio2 .inp_radio:checked + .lab_radio{z-index:1;color:#333;background:#fff;font-weight:500}
/* 토글버튼 - 체크박스(스위치형) */
.switch_check{display:inline-block;position:relative;width:32px;height:10px;vertical-align:top;text-align:center}
.switch_check .inp_check{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.switch_check .lab_check{display:block;position:relative;height:10px;border-radius:5px;background-color:#ccc;cursor:pointer}
.switch_check .ico_switch{overflow:hidden;position:absolute;left:0;top:50%;width:16px;height:16px;margin-top:-8px;border-radius:16px;font-size:0;line-height:0;background-color:#fafafa;box-shadow:0 0 3px rgba(0,0,0,0.4);text-indent:-9999px}
.switch_check.on .lab_check{border-color:#00BEE1;background-color:#00BEE1}
.switch_check .inp_check:checked + .lab_check{border-color:#00BEE1;background-color:#00BEE1}
.switch_check.on .ico_switch{left:auto;right:0}
.switch_check .inp_check:checked + .lab_check .ico_switch{left:auto;right:0}
.switch_check.in_active .lab_check{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
.switch_check .inp_check:disabled + .lab_check{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
/* 드롭다운 - 셀렉트박스 */
.opt_select{display:inline-block;position:relative;min-width:128px;max-width:225px;border:1px solid rgba(0,0,0,0.15);border-radius:2px;font-weight:400;font-size:14px;color:#999;background:#fff;vertical-align:top}
.opt_select.in_error{border-color:#f55353}
.opt_select:hover{border-color:#333}
.opt_select .link_selected{display:block;overflow:hidden;max-width:215px;min-height:34px;padding:7px 25px 8px 15px;border-radius:2px;line-height:19px;color:#333;background:#fff;text-align:left;text-decoration:none;white-space:nowrap;text-overflow:ellipsis;box-sizing:border-box}
.opt_select .link_default{color:#999}
.opt_select .link_selected .ico_and{margin-top:5px}
.opt_select > .ico_arr{position:absolute;right:9px;top:50%;width:0;height:0;margin-top:-3px;border-left:4px solid transparent;border-right:4px solid transparent;border-top:5px solid #555;vertical-align:middle}
.opt_select .ico_on{margin:5px 7px 0 0}
.opt_select .opt_list{display:none;position:absolute;left:-1px;top:34px;z-index:100;min-width:100%;max-width:100%;border:1px solid #000;border-radius:0 0 2px 2px;background-color:#fff}
.opt_select.opt_limited .opt_list{max-width:none}
.opt_select.opt_top .opt_list{top:auto;bottom:34px}
.opt_select.opt_right .opt_list{left:auto;right:-1px}
.opt_select .opt_scroll .list_opt{overflow-x:hidden;overflow-y:auto;max-height:268px;margin:3px 3px 3px 0;box-sizing:border-box}/** 디자인 스크롤 여백 */
.opt_select .list_opt{padding:6px 0;border-top:1px solid #ebebeb;max-height:196px;overflow-x:hidden}
.opt_select .list_opt:first-child{border-top:0 none}
.opt_select .list_opt li{position:relative}
.opt_select .tit_optrow + .list_opt{border-top:0 none}
.opt_select .tit_option{display:block;overflow:hidden;height:31px;padding:5px 15px 0;line-height:31px;color:#777;font-size:12px;font-weight:400;text-align:left;white-space:nowrap;text-overflow:ellipsis;text-decoration:none}
.opt_select .link_option{display:block;overflow:hidden;height:36px;padding:0 15px;font-size:13px;line-height:36px;color:#555;text-align:left;white-space:nowrap;text-overflow:ellipsis;text-decoration:none}
.opt_select .sub_listopt .user_opt .link_option{height:23px;padding:0 15px;line-height:18px}
.opt_select .user_opt:hover .link_option{background:none}
.opt_select .link_option .ico_and{margin-top:9px}
.opt_select .tit_option + .list_opt{padding-bottom:10px}
.opt_select .list_opt + .tit_option{padding-top:0;margin-top:0}
.opt_select .user_opt .link_option{padding-right:62px}
.opt_select .user_opt .btn_del{position:absolute;right:12px;top:50%;margin-top:-10px;padding:3px}
.opt_select .user_opt .ico_del{width:13px;height:13px;background-position:-770px -135px;opacity:0.2}
.opt_select .user_opt .btn_del:hover .ico_del{background-position:-770px -135px;opacity:0.5}
.opt_select .user_opt .btn_edit{position:absolute;right:32px;top:50%;margin-top:-10px;padding:3px}
.opt_select .user_opt .ico_edit{width:15px;height:15px;background-position:-885px -180px}
.opt_select .user_opt .btn_edit:hover{background:none}
.opt_select .user_opt .btn_edit:hover .ico_edit{background-position:-885px -195px}
.opt_select .user_opt:hover{background-color:rgba(0,0,0,0.03)}
.opt_select .user_opt:hover .link_option{color:#333}
.opt_select .box_checkinp{display:block;height:29px;line-height:29px}
.opt_select .box_checkinp .lab_check{display:block;overflow:hidden;position:relative;padding:0 26px 0 41px;font-size:13px;color:#333;white-space:nowrap;text-overflow:ellipsis}
.opt_select .box_checkinp .inp_check{left:15px}
.opt_select .box_checkinp .ico_check{left:15px}
.opt_select .box_checkinp .lab_check:hover{background-color:rgba(0,0,0,0.03)}
.opt_select .box_checkinp .lab_check .link_help{margin-top:5px}
.opt_select .sub_listopt{max-height:100%;padding:0;border-top:0 none}
.opt_select .sub_listopt .link_option{padding:0 27px}
.opt_select .detail_listopt .link_option .ico_comm{float:left;width:6px;height:6px;margin:11px 4px 0 0;background-position:-410px -330px}
.opt_select li > .link_option:hover{background:rgba(0,0,0,0.03)}
.opt_select li.on > .link_option,.opt_select .user_opt.on > .link_option{color:#326edc;background:#f1f6fc}
.opt_select .on > .box_checkinp .ico_check{background-position:-855px -50px}
.opt_select .on > .box_checkinp .lab_check,.opt_select .box_checkinp .inp_check:checked + .lab_check{color:#326edc}
.opt_select.opt_open{border-color:#000;border-radius:2px 2px 0 0;color:#333}
.opt_select.opt_open .link_default{color:#333}
.opt_select.opt_open > .ico_arr{border-top:0 none;border-bottom:5px solid #555}
.opt_select.opt_open .opt_list,.opt_select.opt_open .optlist_data,.opt_select.opt_open .optlist_row{display:block}
.opt_select.in_active{background:#f5f5f5;cursor:default;pointer-events:none}
.opt_select.in_active:hover,.opt_select.in_active.over,.opt_select.in_active.opt_open{border-color:#ccc}
.opt_select.in_active .link_selected{background:#f5f5f5}
.opt_select.in_active.opt_open .opt_list{display:none}
.opt_select.in_active.opt_open .ico_arr{border-top:5px solid #888;border-bottom:0 none}
.opt_select.in_active .link_selected{color:#ccc;cursor:default;outline:0 none}
.opt_select.in_active > .ico_arr,.opt_select.in_active:hover > .ico_arr,.opt_select.in_active.over > .ico_arr,.opt_select.in_active.opt_open > .ico_arr{border-top-color:#ccc}
.opt_select li.in_active .link_option{color:#ccc}
.opt_select li.in_active .link_option:hover{background-color:#fff}
.opt_select li.in_active .link_option .ico_comm{display:inline-block;width:14px;height:14px;margin:10px 0 0 6px;background-position:-715px -130px;vertical-align:top}
.opt_select .list_opt2{padding:0;border-top:1px solid #ebebeb}
.opt_select .list_opt2:first-child{border-top:0 none}
.opt_select .link_rowset{display:block;overflow:hidden;height:40px;line-height:38px;border-top:1px solid #e6e6e6;background-color:#f5f5f5;text-align:center;color:#555}
.opt_level{width:170px}
.opt_select .list_opt .btn_colset{height:28px;margin:4px 0 4px 15px}
.opt_select .list_opt .btn_colset .inner_g{padding:5px 15px 5px}
.opt_select .opt_list.opt_scroll{max-height:none}
.opt_select .opt_list .sublist_opt{margin:4px 15px 15px}
.opt_select .opt_list .sublist_opt{border:1px solid #ccc}
.opt_select .opt_list.opt_scroll .list_opt{margin:3px 3px 3px 0;padding-top:4px;padding-top:4px}/** 디자인 스크롤 여백 */
.opt_select .opt_list.opt_scroll.img_opt .list_opt{max-height:260px}/**프로필 셀렉트 스크롤*/
.opt_select .subtit_opt{display:block;padding:10px 15px 3px;color:#777}
.opt_select .opt_img img{width:100%}
.opt_select .opt_imglink{position:relative}
.opt_select .link_selected .opt_img{overflow:hidden;display:inline-block;width:24px;height:24px;margin:-2px 7px -3px 0;border-radius:12px;vertical-align:top}
.opt_select .opt_list li .opt_img{overflow:hidden;position:absolute;top:6px;left:15px;z-index:10;width:36px;height:36px;margin:1px 0 0 0;border-radius:18px}
.opt_select .opt_imglink.link_selected{padding-left:46px}
.opt_select .opt_img + .link_option{height:52px;padding-left:60px;line-height:52px}
.opt_select .tooltip_basic.ly_tooltip{display:none;min-width:200px}
.opt_select.opt_open .tooltip_basic{display:block}
.gm_calendar + .box_checkinp{margin:7px 0 0 20px}
.gm_calendar + .box_checkinp .lab_check{font-weight:400;color:#555}
.opt_select .util_btn{border-top:1px solid #CCC;text-align:right}
.opt_select .util_btn .link_register{display:inline-block;padding:0 18px;font-weight:bold;font-size:12px;line-height:48px;color:#555;vertical-align:top}
.opt_select .util_btn .ico_arr{width:6px;height:10px;margin:20px 0 0 8px;background-position:-710px -520px}
.opt_adgroup{padding-right:11px}
.opt_adgroup .sub_listopt .link_option{padding:0 15px}
/* 셀렉트박스_작은사이즈 */
.opt_select.opt_small{min-width:auto;max-width:none;min-height:25px;font-size:11px;background-position:0 -340px}
.opt_select.opt_small:hover,.opt_select.opt_small.over{background-position:0 -370px}
.opt_select.opt_small .link_selected{min-height:18px;padding:2px 26px 3px 9px;line-height:18px}
.opt_select.opt_small .ico_arr{margin-top:-2px;border-left-width:3px;border-right-width:3px;border-top-width:4px}
.opt_select.opt_small .opt_list{top:23px}
.opt_select.opt_small .link_option{height:21px;padding:0 9px;line-height:21px}
.opt_select.opt_small.opt_open{background-position:0 -500px}
.opt_select.opt_small.opt_open .ico_arr{border-bottom-width:4px}
/* 행갯수 셀렉트 */
.opt_row{width:104px;min-width:0;max-width:none;background:#fff}
.opt_row .opt_list{min-width:104px;max-width:none}
.opt_row .link_selected{padding-right:26px}
.opt_row:hover,.opt_row.over,.opt_row.opt_open{background-color:#f5f5f5}
/* 필터 셀렉트 */
.opt_filter .opt_list{left:auto;right:-1px;max-width:none;padding:0}
.opt_filter .list_opt .list_opt .box_checkinp .inp_check,.opt_filter .list_opt .list_opt .box_checkinp .ico_check{left:38px}
.opt_filter .list_opt .list_opt{border-top:0 none}
.opt_filter .list_opt .list_opt .box_checkinp .lab_check{padding-left:62px}
.opt_filter2{min-width:76px}
.opt_filter2 .opt_list{min-width:76px}
/* 분석데이터 옵션레이어 */
.optlist_data{display:none;position:absolute;left:-1px;top:34px;z-index:20;border:1px solid #000;border-radius:0 0 2px 2px;background-color:#fff}
.optlist_data .list_data{display:flex;overflow:hidden;width:257px}
.optlist_data .list_data li{float:left;position:relative;width:50%;height:100%;padding-bottom:10px;box-sizing:border-box;vertical-align:top}
/* .optlist_data .list_data li:nth-child(odd){border-right:1px solid #ccc} */
.optlist_data > .list_data{display:block;position:relative}
.optlist_data > .list_data:after{content:'';position:absolute;top:0;left:50%;bottom:0;width:1px;height:100%;background-color:#ccc}/** 2021-06-18 추가 */
.optlist_data .list_data li:first-child{border-left:0 none;margin-left:0}
.optlist_data .list_data .tit_data{display:block;overflow:hidden;height:25px;padding:15px 15px 0;font-weight:700;font-size:12px;line-height:18px;color:#333;text-align:left;white-space:nowrap;text-overflow:ellipsis;text-decoration:none}
.optlist_data .list_data li:first-child .tit_data,.optlist_data .list_data li:nth-child(2) .tit_data{border-top:none}
.optlist_data .list_data .link_help{margin-left:2px}
.optlist_data .link_option.on{color:#326edc;background:#f1f6fc}
.optlist_data .link_option.on:hover{background-color:#f1f6fc}
.optlist_data .link_option.in_active{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
.optlist_data .link_option.in_active:hover{background:none;color:#555}
.optlist_data .btn_datadel{display:block;width:100%;height:41px;border-top:1px solid #ccc;font-weight:500;font-size:13px;background-color:#f5f5f5}
.optlist_data .list_data_type1{width:129px}/** 옵션이 1개일때 */
.optlist_data .list_data_type1 li{width:100%}
.optlist_data .txt_empty{margin-top:36px;color:#777;text-align:center}/** 2뎁스 옵션이 없을경우 */
.optlist_data .link_option .txt_limit{display:block;overflow:hidden;height:36px;font-size:13px;line-height:36px;color:#555;white-space:nowrap;text-overflow:ellipsis;text-decoration:none}
.optlist_data .link_option.on .txt_limit{color:#326edc}
.optlist_data .list_data .data_loading{min-height:190px}
.optlist_data .list_data .reform_area_loading{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%)}
.optlist_data .nodata_info{position:absolute;top:40px;right:0;left:1px;height:256px;padding:0 16px;border-top:1px solid #ddd;background:#fff;z-index:30}
.optlist_data .nodata_info .txt_empty{position:relative;top:50%;transform:translateY(-50%);margin-top:0;font-size:14px;line-height:20px;color:#999;word-break:keep-all}
.optlist_data .nodata_info.nodata_info2{left:154px}
.optlist_data .inner_optlist{position:relative}
.optlist_data .inner_optlist:before{position:absolute;top:0;left:153px;bottom:0;width:1px;height:100%;background-color:#ddd;z-index:20;content:''}/** 2021-06-18 추가 */
.optlist_data .inner_optlist:after{position:absolute;top:0;right:232px;bottom:0;width:1px;height:100%;background-color:#ddd;z-index:20;content:''}
/* 열기본지표 옵션레이어 타입2 */
.optlist_row{display:none;width:530px;position:absolute;left:-1px;top:34px;z-index:20;border:1px solid #000;border-radius:0 0 2px 2px;background:#fff}
.optlist_row .optrow_wrap{position:relative}
.optlist_row .optrow_wrap:before{position:absolute;left:180px;top:0;width:1px;height:100%;background-color:#ebebeb;content:''}
.optlist_row .optrow_wrap:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.optlist_row .optrow_cell{float:left;width:225px;height:100%}
.optlist_row .optrow_cell:first-child{width:176px}
.optlist_row .optrow_cell:first-child .btn_gm{position:absolute;left:16px;bottom:16px;width:132px}
.optlist_row .optrow_cell .tit_optrow{display:block;height:32px;padding:6px 5px 0 15px;line-height:32px;font-size:13px;font-weight:400;color:#777}
.optlist_row .optrow_cell .list_opt.sub_listopt{overflow-x:initial}
.optlist_row .list_opt{clear:both;border-color:#e6e6e6}
.opt_right .optlist_row{left:auto;right:-1px}
.optlist_row .scroll_listopt{overflow-y:auto;height:313px;margin-bottom:13px}
.optlist_row .scroll_listopt .desc_nodata{padding-top:120px;font-size:14px;line-height:20px;color:#aaa;text-align:center}
.optlist_row .btn_colset{width:193px;margin:0 0 16px 16px}
.optlist_row .desc_none{height:179px;margin-top:134px;line-height:20px;color:#aaa;text-align:center}
.optlist_row .optrow_wrap > .desc_nodata{min-height:404px;padding-top:173px;font-size:13px;line-height:20px;color:#aaa;text-align:center;box-sizing:border-box}
.optlist_row .optrow_wrap .optrow_cell + .optrow_cell:before{position:absolute;left:0;top:0;width:1px;height:100%;background-color:#ebebeb;content:''}
.optlist_multi{width:767px}
.optlist_multi .optrow_cell:first-child{width:241px}
.optlist_multi .optrow_wrap:before{left:241px}
.optlist_multi .cell_filter2{position:relative;width:262px}
.optlist_multi .cell_filter2:before{position:absolute;right:0;top:0;width:1px;height:100%;background-color:#ebebeb;content:''}
.optlist_multi .cell_filter3{width:262px}
.optlist_multi .item_row{padding:0 15px;margin-bottom:15px}
.optlist_multi .item_row .desc_row{margin-bottom:5px;font-size:13px;color:#333}
.optlist_multi .item_row .box_inptxt{display:inline-block;width:62px;margin-left:6px}
.optlist_multi .item_row .box_inptxt .inp_txt{text-align:right}
.optlist_multi .item_row .opt_select .link_selected{padding:7px 0 8px 15px}
.optlist_multi .wrap_btn{padding:16px 29px 16px 0;border-top:1px solid #EBEBEB;text-align:right}
/* 커플셀렉트 2개이상일때 */
.couple_select{display:inline-block;vertical-align:top}
.couple_select:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.couple_select .opt_select{float:left;min-width:122px;max-width:none;margin-left:-1px;border-radius:0}
.couple_select .opt_select:first-child{border-radius:2px 0 0 2px}
.couple_select .opt_select:last-child{border-radius:0 2px 2px 0}
.couple_select .opt_select.opt_open + .opt_select{border-left-color:#000}
.couple_select .opt_select:hover + .opt_select{border-left-color:#000}
.couple_select .opt_list{width:122px;min-width:0;max-width:none}
/* 행갯수 셀렉트 */
.opt_time{width:104px;min-width:0;max-width:none;background:#fff}
.opt_time .opt_list{min-width:104px;max-width:none}
.opt_time .link_selected{padding-right:26px}
/* 필터 셀렉트 */
.opt_filter{min-width:98px}
.opt_filter .list_opt .list_opt .box_checkinp .inp_check,.opt_filter .list_opt .list_opt .box_checkinp .ico_check{left:38px}
.opt_filter .list_opt .list_opt{border-top:0 none}
.opt_filter .list_opt .list_opt .box_checkinp .lab_check{padding-left:62px}
.opt_filter2{min-width:76px}
.opt_filter2 .opt_list{min-width:76px}
/* 공통 input-radio */
.radio_inp{display:inline-block;position:relative;width:18px;height:18px;vertical-align:middle}
.radio_inp .inp_radio{position:absolute;left:0;top:0;z-index:-1;width:18px;height:18px;opacity:0.01;filter:alpha(opacity=0)}
.radio_inp .ico_radio{position:absolute;left:0;top:0;width:18px;height:18px;background-position:-715px -50px}
.radio_inp:hover .ico_radio,.radio_inp.over .ico_radio{background-position:-735px -50px}
.radio_inp.on .ico_radio,.radio_inp .inp_radio:checked + .ico_radio{background-position:-775px -50px}
.radio_inp.in_active,.radio_inp.in_active .inp_radio{cursor:default;pointer-events:none}
.radio_inp.in_active .ico_radio{background-position:-755px -50px}
.box_radioinp{display:inline-block;position:relative;vertical-align:top}
.box_radioinp .inp_radio{position:absolute;left:0;top:50%;z-index:-1;width:18px;height:18px;margin-top:-8px;opacity:0.01;filter:alpha(opacity=0)}
.box_radioinp .ico_radio{position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;background-position:-715px -50px}
.box_radioinp .lab_radio{display:block;height:100%;padding-left:28px;color:#555;cursor:pointer}
.box_radioinp:hover .ico_radio,.box_radioinp.over .ico_radio{background-position:-735px -50px}
.box_radioinp .inp_radio:checked + .lab_radio .ico_radio,.box_radioinp.on .lab_radio .ico_radio{background-position:-775px -50px}
.box_radioinp.in_active,.box_radioinp.in_active .inp_radio{cursor:default;pointer-events:none}
.box_radioinp.in_active .link_help{pointer-events:auto}
.box_radioinp.in_active .ico_radio{background-position:-755px -50px}
.box_radioinp.not_active{cursor:default;pointer-events:none;opacity:0.4;filter:alpha(opacity=40)}
/* 공통 input-checkbox */
.check_inp{display:inline-block;position:relative;width:18px;height:18px;vertical-align:middle}
.check_inp .inp_check{position:absolute;left:0;top:50%;z-index:-1;width:18px;height:18px;margin-top:-8px;opacity:0.01;filter:alpha(opacity=0)}
.check_inp .ico_check{position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;background-position:-795px -50px}
.check_inp:hover .ico_check,.check_inp.over .ico_check{background-position:-815px -50px}
.check_inp.on .ico_check,.check_inp .inp_check:checked + .ico_check{background-position:-855px -50px}
.check_inp.in_active,.check_inp.in_active .inp_check{cursor:default;pointer-events:none}
.check_inp.in_active .ico_check{background-position:-835px -50px}
.check_inp.in_active .inp_check:checked + .ico_check{background-position:-695px -50px}
/* 체크박스 - 기본사이즈 */
.box_checkinp{display:inline-block;position:relative}
.box_checkinp .inp_check{position:absolute;left:0;top:50%;z-index:-1;width:18px;height:18px;margin-top:-9px;opacity:0.01;filter:alpha(opacity=0)}
.box_checkinp .ico_check{position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;background-position:-795px -50px}
.box_checkinp .lab_check{display:inline-block;height:100%;padding-left:28px;color:#555;cursor:pointer;vertical-align:top}
.box_checkinp:hover .ico_check,.box_checkinp.over .ico_check{background-position:-815px -50px}
.box_checkinp.on .ico_check,.box_checkinp .inp_check:checked + .lab_check .ico_check{background-position:-855px -50px}
.box_checkinp.in_active,.box_checkinp.in_active .inp_check{cursor:default;pointer-events:none}
.box_checkinp.in_active .ico_check{background-position:-835px -50px}
.box_checkinp.not_active{cursor:default;pointer-events:none}
.box_checkinp.not_active .lab_check .ico_check{opacity:0.4;filter:alpha(opacity=40)}
.box_checkinp.in_active .inp_check:checked + .lab_check .ico_check{background-position:-695px -50px}
.box_checkinp.in_active .link_help{pointer-events:auto}
/* 공통 input-text */
.box_inptxt{display:block;position:relative;height:20px;padding:7px 11px 7px 15px;border-radius:2px;border:1px solid rgba(0,0,0,0.15);color:#333;background-color:#fff}
.box_inptxt .inner_inp{display:block;overflow:hidden;height:100%}
.box_inptxt::placeholder,.box_inptxt .inp_txt::placeholder,.box_inptxt .inp_txt::-webkit-input-placeholder,.box_inptxt .inp_txt::-ms-input-placeholder{color:#999}
.box_inptxt .lab_txt{position:absolute;left:15px;top:50%;margin-top:-11px;font-size:14px;line-height:20px;color:#999;cursor:text}
.box_inptxt .inp_txt{display:block;width:100%;height:100%;border:0 none;padding:0;font-size:14px;line-height:20px;background-color:transparent;color:#333;outline:0 none}
.box_inptxt .num_byte{float:right;margin-top:-1px;margin-right:-1px;padding-left:40px;font-weight:400;font-size:14px;line-height:20px;color:#333;text-align:right}
.inp_num .inp_txt{text-align:right}
.inp_num .num_byte{padding-left:10px}
.inp_num .lab_txt{left:auto;right:33px;margin-top:-10px}
.box_inptxt:hover,.box_inptxt.over{border-color:#555}
.box_inptxt.on{border-color:#326edc;color:#333}
.box_inptxt.in_active{background-color:#f5f5f5;border-color:rgba(0,0,0,0.08);cursor:default;pointer-events:none}
.box_inptxt.in_active.over,.box_inptxt.in_active:hover{border-color:#ccc}
.box_inptxt.in_active .inp_txt{color:#ccc}
.box_inptxt.in_active .inp_txt[disabled]{color:#ccc;background-color:#f5f5f5}
.box_inptxt.in_active .txt_won{color:#ccc}
.box_inptxt.in_active .num_byte{color:#ccc}
.box_inptxt.in_error{border-color:#f55353}
.box_inptxt.in_error .inp_txt.fc_disable{color:#999}
.box_inptxt.on .lab_txt,.box_inptxt.on_inp .lab_txt,.box_inptxt.in_active .lab_txt,.box_inptxt.in_error .lab_txt{display:none}
.box_inptxt.help_inp{padding-right:43px}
.box_inptxt.help_inp .link_g{vertical-align:top}
.box_inptxt.help_inp .help_wrap{position:absolute;right:14px;top:50%;z-index:1;margin-top:-10px}
.box_inptxt.help_inp .help_wrap.on .link_g .ico_help2{background-position:-30px -140px}
/* 공통 - group_inp */
.group_inp:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.group_inp .box_inptxt{float:left}
.group_inp .box_radioinp{float:left;height:16px;margin-left:29px;line-height:13px}
.group_inp .box_radioinp:first-child{margin-left:0}
.group_inp .lab_radio{padding-left:23px}
.verifi_inp .txt_noti{float:left;height:36px;margin-left:10px;font-size:13px;line-height:31px;color:#777;letter-spacing:-0.05em}
.verifi_inp .txt_noti.fc_warn{color:#f64949}
.group_radioinp{padding:10px 0}
.group_inp.hyphen_inp .box_num{width:42px;padding:7px 13px 7px 13px}
.group_inp.hyphen_inp .txt_hyphen{float:left;position:relative;width:37px;height:36px}
.group_inp.hyphen_inp .txt_hyphen:before{position:absolute;top:17px;left:15px;width:7px;height:1px;background-color:#333;content:''}
.group_inp.hyphen_inp .inp_txt{text-align:center}
.group_inp.upload_inp{display:inline-block;position:relative;vertical-align:top}
.group_inp.email_inp .txt_email{float:left;width:37px;height:36px;line-height:36px;text-align:center}
.group_inp.email_inp .inp_mailname{width:149px}
.group_inp.email_inp .inp_mailaddr{width:158px}
.group_inp.email_inp .opt_select{min-width:155px;max-width:inherit}
.group_inp.email_inp .opt_select .opt_list{min-width:155px;max-width:inherit}
.group_inp.verifi_inp .box_inptxt{width:256px}
.group_inp + .verifi_inp{margin-top:15px}
/* textarea 공통요소 */
.box_textarea{overflow:hidden;position:relative;width:100%;height:55px;padding-right:3px;border:1px solid transparent;border-color:#ddd;border-radius:2px;-webkit-box-sizing:border-box;box-sizing:border-box;background-color:#fff}/** 디자인 스크롤 여백 */
.box_textarea:hover{border-color:#555}
.box_textarea .tf_area{display:block;overflow-x:hidden;width:100%;height:100%;padding:7px 15px 7px;border:0 none;outline:none;resize:none;line-height:18px;color:#333;background:transparent;-webkit-box-sizing:border-box;box-sizing:border-box}
.box_textarea .lab_txt{position:absolute;left:15px;right:15px;top:6px;color:#999;cursor:text}
.textarea_code .tf_area{overflow-y:auto;padding-top:10px;padding-bottom:13px;color:#111}
.textarea_code + .group_inp{margin-top:10px}
.box_textarea.on{border-color:#326edc}
.box_textarea.in_active{background-color:#f5f5f5;border-color:#ddd;cursor:default}
.box_textarea.in_error{border-color:#f55353}
.box_textarea.on .lab_txt,.box_textarea.in_active .lab_txt,.box_textarea.in_error .lab_txt{display:none}
/* 스크롤없는타입 */
.box_textarea2{overflow:hidden;position:relative;height:90px;border:1px solid transparent;border-color:#ddd;border-radius:2px;-webkit-box-sizing:border-box;box-sizing:border-box;background-color:#fff}
.box_textarea2:hover{border-color:#555}
.box_textarea2 .tf_area{display:block;overflow:hidden;width:100%;height:100%;padding:9px 55px 13px 15px;border:0 none;outline:none;resize:none;line-height:20px;color:#333;background:transparent;-webkit-box-sizing:border-box;box-sizing:border-box}/** 2021-04-01_v2 수정 */
.box_textarea2 .lab_txt{position:absolute;left:15px;top:10px;color:#999;cursor:text}
.box_textarea2 .num_byte{position:absolute;right:10px;top:6px;font-size:14px;font-weight:400;line-height:23px;color:#333;text-align:right}
.box_textarea2.textarea_s{height:56px}
.box_textarea2.textarea_s .tf_area{padding:5px 55px 9px 15px}/** 2021-04-01_v2 수정 */
.box_textarea2.textarea_s .num_byte{top:7px}
.box_textarea2.textarea_m{height:70px}
.box_textarea2.on{border-color:#326edc}
.box_textarea2.in_active .tf_area{background-color:#f5f5f5;cursor:default;color:#ccc;pointer-events:none}
.box_textarea2.in_active .num_byte{color:#ccc}
.box_textarea2.in_active:hover{border-color:#ddd}
.box_textarea2.in_error{border-color:#f55353}
.box_textarea2.on .lab_txt,.box_textarea2.on_inp .lab_txt,.box_textarea2.in_active .lab_txt,.box_textarea2.in_error .lab_txt{display:none}
/* 검색 input-text */
.search_inp .ico_search{display:none;position:absolute;left:9px;top:50%;width:17px;height:17px;margin-top:-9px;background-position:-170px 0}
.search_inp.on .ico_search{background-position:-190px 0}
.search_inp2{padding-right:31px}
.search_inp2 .btn_search{position:absolute;right:0;top:0;width:31px;height:34px}
.search_inp2 .ico_search{width:20px;height:20px;margin:7px 0 9px 5px;background-position:-880px -20px}
.search_inp2 .btn_del{display:none;position:absolute;right:31px;top:0;width:20px;height:34px}
.search_inp2 .ico_del{width:10px;height:10px;margin:12px auto;background-position:0 -50px}
.search_inp2.on .btn_del,.search_inp2.on_inp .btn_del{display:block}
.search_inp3{padding-left:34px}
.search_inp3 .ico_search{position:absolute;left:9px;top:50%;width:17px;height:17px;margin-top:-9px;background-position:-170px 0}
.search_inp3.on .ico_search{background-position:-190px 0}
.search_inp3 .lab_txt{left:35px}
/* 검색 input-text - 버튼이 붙은 type */
.box_search{position:relative;padding-right:69px}
.box_search .box_inptxt{padding:9px 16px 13px}
.box_search .box_inptxt .btn_del{display:none;position:absolute;right:0;top:0;width:40px;height:42px}
.box_search .box_inptxt .ico_del{width:10px;height:10px;margin:16px auto 0;background-position:0 -50px}
.box_search .btn_gb{position:absolute;right:0;top:0;width:59px;height:44px}
.box_search .btn_gb .inner_g{padding-right:0;padding-left:0}
.box_search .txt_error{display:none;padding-top:5px;margin-bottom:-2px}
.box_search.error .box_inptxt{border-color:#f64949}
.box_search.error .txt_error{display:block}
.box_search .box_inptxt.on{padding-right:40px}
/* 검색 input-text 작은타입 */
.box_search2{position:relative;padding-right:66px}
.box_search2 .box_inptxt{width:198px}
.box_search2 .btn_gm{position:absolute;right:0;top:0;width:56px}
.box_search2 .btn_gm .inner_g{padding-right:0;padding-left:0}
.search_inp .btn_del{display:none}
.search_inp.on .btn_del,.search_inp.on_inp .btn_del{display:block}
/* 공통 - 로딩 */
.area_loading{position:relative;display:table;width:100%;height:100%;line-height:8px;text-align:center;vertical-align:middle}
.area_loading .group_loading{display:table-cell;width:100%;height:100%;text-align:center;vertical-align:middle}
.area_loading .ico_loading{display:inline-block;width:8px;height:8px;margin:2px;border-radius:100%;background-color:#326edc}
.area_loading .loading_size2{line-height:2px}
.area_loading .loading_size3{line-height:12px}
.area_loading .loading_size2 .ico_loading{width:4px;height:4px;margin:0}/** 작은 로딩 (버튼,작은공간 사용) */
.area_loading .loading_size3 .ico_loading{width:12px;height:12px;margin:1px}/** 큰 로딩 (전체 로딩시) */
.area_loading .ani_loading{animation:0.6s ease-in-out 0.07s infinite normal both running animation-10yals0}
.area_loading .ani_loading2{animation:0.6s ease-in-out 0.14s infinite normal both running animation-10yals0}
.area_loading .ani_loading3{animation:0.6s ease-in-out 0.21s infinite normal both running animation-10yals0}
.area_loading .group_loading .txt_loading{padding-top:25px;line-height:22px;font-size:14px;color:#555}
.area_loading .group_loading .subtxt_loading{padding-top:0;line-height:22px;font-size:12px;color:#777}
/* 새로고침 */
.area_refresh{position:relative;display:table;width:100%;height:100%;text-align:center;vertical-align:middle}
.area_refresh .group_refresh{display:table-cell;width:100%;height:100%;text-align:center;vertical-align:middle}
.area_refresh .desc_refresh{margin:0 auto 15px;line-height:20px}
/* 공통 - 페이징 */
.paging_wrap{height:36px;text-align:center}
.paging_wrap .inner_paging{display:inline-block;height:36px}
.paging_wrap .num_paging{float:left;width:34px;height:34px;margin-left:-1px;border:1px solid #ddd;background-color:#fff;line-height:33px;text-align:center;color:#777;text-decoration:none}
.paging_wrap em.num_paging{position:relative;z-index:1;border-color:#326edc;font-weight:500;color:#326edc;cursor:default}
.paging_wrap .num_paging:first-child{margin:0}
.paging_wrap .ico_comm{height:10px;margin:12px auto 0}
.paging_wrap .ico_prevs{width:13px;background-position:-740px -500px}
.paging_wrap .ico_prev{width:6px;background-position:-730px -500px}
.paging_wrap .ico_nexts{width:13px;background-position:-760px -500px}
.paging_wrap .ico_next{width:6px;background-position:-775px -500px}
.paging_wrap a.num_paging .ico_prevs{background-position:-740px -510px}
.paging_wrap a.num_paging .ico_prev{background-position:-730px -510px}
.paging_wrap a.num_paging .ico_nexts{background-position:-760px -510px}
.paging_wrap a.num_paging .ico_next{background-position:-775px -510px}
.paging_wrap .num_paging:hover{background-color:#f5f5f5;border-color:#ccc}
.paging_wrap .num_paging:hover + .num_paging{border-left-color:#ccc}
.paging_wrap em.num_paging:hover{border-color:#326EDC}
.paging_wrap span.num_paging:hover{background-color:transparent;border-color:#ddd}
.paging_wrap span.num_paging:hover + em.num_paging{border-left-color:#326EDC}
.paging_wrap span.num_paging:hover + span.num_paging{border-color:#ddd}
/* 공통 - 타이틀 */
.dsp_tit{padding-top:40px;margin-bottom:19px}
.dsp_tit .tit_dsp:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tit_dsp .sub_title{float:left;position:relative;padding-left:12px;margin-left:14px;font-size:20px;font-weight:500;color:#111}
.tit_dsp .sub_title:before{position:absolute;left:-3px;top:6px;width:1px;height:19px;background-color:#ccc;content:''}
.tit_dsp .sub_code{float:left;margin-left:6px;font-size:20px;color:#777;font-weight:500}
.dsp_tit .info_navigation + .tit_dsp{margin-top:5px}
.dsp_tit .tit_subject{overflow:hidden;float:left;padding-right:3px;margin-right:-3px;font-weight:500}
.dsp_tit .tit_subject .link_help{margin:6px 0 0 7px}
.dsp_tit .switch_check{float:left;margin:8px 0 0 10px}
.tit_dsp2{text-align:center;padding-top:70px;margin-bottom:50px}
.tit_dsp2 .txt_subject{margin-top:18px}
.dsp_subtit:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.dsp_subtit .subtit_subject{float:left}
.dsp_subtit .subtit_subject + .subtxt_subject{float:left;margin-left:20px}
.dsp_subtit .check_aim{float:left}
.dsp_subtit .check_aim .box_checkinp{margin:1px 0 0 23px}
.dsp_subtit .check_aim .link_help{margin-left:2px}
/* 공통 - 컨텐츠 */
.info_navigation{display:block;font-size:12px;color:#777}
.info_navigation:before{overflow:hidden;font-size:0;line-height:0;text-indent:-9999px;content:'사이트맵'}
.info_navigation .ico_arrow{width:5px;height:8px;margin:7px 6px 0 7px;background-position:-160px -60px}
.info_navigation .link_navi{color:#777}
.info_navigation .current_navi{color:#111}/** 현재페이지 */
.tit_subject{display:block;font-size:20px;color:#111}/** 메인타이틀 */
.txt_subject{font-size:18px;line-height:21px;color:#111;letter-spacing:-1px}/** 메인타이틀의 보조 텍스트 */
.subtit_subject{display:block;font-size:16px;line-height:24px;color:#111}/** 서브타이틀 */
.subtit_subject .link_help{margin:-2px 0 0 9px}
.subtit_subject .tit_msgname{margin-right:14px}
.subtit_subject .txt_category{display:inline-block;font-weight:400;font-size:14px}
.subtit_subject .txt_category:before{display:inline-block;width:1px;height:16px;margin:6px 14px 0 0;background-color:#ccc;vertical-align:top;content:''}
.subtit_subject .txt_category .link_help{margin-left:1px}
.subtxt_subject{display:block;font-size:14px;color:#777}/** 서브타이틀의 보조 텍스트 */
.box_card{border:1px solid #ccc;background-color:#fff}
/* 공통 - 페이지내 하단 가운데 정렬 버튼 */
.page_btn{margin-top:28px;text-align:right}
.page_btn .inner_btn{display:inline-block;vertical-align:top}
.page_btn .btn_gb,.page_btn .btn_gm{float:left;height:40px;margin:0 0 0 10px}
.page_btn .btn_gb:first-child,.page_btn .btn_gm:first-child{margin-left:0}
/* 공통 - 레이어 */
.layer_on{overflow:hidden}
.layer_dimmed{z-index:990 !important}/** 레이어를 딤드아래로 내리고 싶을경우 사용하는 클래스 필요없는경우엔 꼭 빠지게 해주세요 */
.dimmed_layer{position:fixed;left:0;top:0;z-index:998;width:100%;height:100%;background-color:#000;opacity:0.4;filter:alpha(opacity=40)}
.dimmed_layer2{position:fixed;left:0;top:0;z-index:1000;width:100%;height:100%;background-color:#fff;opacity:0.5;filter:alpha(opacity=50)}
.dimmed_layer3{position:fixed;left:0;top:0;z-index:998;width:100%;height:100%;background-color:#000;opacity:0.85;filter:alpha(opacity=85)}
.dimmed_layer4{position:fixed;left:0;top:0;z-index:998;width:100%;height:100%;background-color:#1e1e1e}
.layer_wrap{overflow-x:auto;position:fixed;left:0;top:0;right:0;bottom:0;z-index:999}
/* tooltip layer */
.tooltip_layer{z-index:99999999}
.tooltip_layer .inner_tooltip_layer{position:relative;width:240px;padding:20px 40px 20px 71px;background-color:#39a4e4;background-color:rgba(57,164,228,0.9);border-radius:4px}/** 정보 */
.tooltip_layer .inner_tooltip_layer1{background-color:#326EDC}/** 긍정 */
.tooltip_layer .inner_tooltip_layer2{background-color:#F55353}/** 부정 */
.tooltip_layer .tit_tooltip{position:absolute;left:30px;top:50%;width:21px;height:40px;margin-top:-20px}
.tooltip_layer .ico_info{width:40px;height:40px;margin:4px auto 0;background-position:-80px -70px}
.tooltip_layer .ico_good{width:22px;height:16px;margin:11px auto 0;background-position:-820px -580px}
.tooltip_layer .ico_bad{width:22px;height:22px;margin:9px auto 0;background-position:-820px -550px}
.tooltip_layer .desc_tooltip{display:table;min-height:40px}
.tooltip_layer .desc_tooltip .inner_desc{display:table-cell;font-weight:500;line-height:20px;color:#fff;vertical-align:middle}
.tooltip_layer .btn_close{position:absolute;right:0;top:0;width:11px;height:11px;padding:10px;opacity:.7}
.tooltip_layer .ico_close{width:11px;height:11px;background-position:-850px -550px}
/* 안내메시지 */
.guide_msg{position:relative;padding:11px 46px 14px 66px;margin-bottom:20px;border:1px solid #326edc;border-radius:2px;background-color:#fff}
.guide_msg .desc_msg{min-height:23px;font-weight:500;line-height:22px;color:#555}
.guide_msg .tit_msg{position:absolute;left:0;top:0;width:50px;height:100%;background-color:#326edc}
.guide_msg .ico_msg{position:absolute;left:50%;top:50%;width:18px;height:18px;margin:-9px 0 0 -9px;background-position:-210px 0}
.guide_msg .link_close{position:absolute;right:13px;top:14px;width:16px;height:16px;padding:2px}
.guide_msg .ico_close{width:16px;height:16px;background-position:-120px -120px}
.guide_msg .link_size{color:#326edc}
.guide_msg.msg_good{border-color:#37c333}
.guide_msg.msg_good .tit_msg{background-color:#37c333}
.guide_msg.msg_good .ico_msg{background-position:-230px 0}
.guide_msg.msg_bad{border-color:#f64949}
.guide_msg.msg_bad .tit_msg{background-color:#f64949}
.guide_msg.msg_bad .ico_msg{background-position:-250px 0}
#mArticle .guidemsg_wrap{margin:0 -15px}
.guide_msg2{position:relative;height:50px;text-align:center;line-height:49px;color:#fff;background-color:#f99900}
.guide_msg2 + .guide_msg2{margin-top:1px}
.guide_msg2 .desc_msg{display:inline-block;height:100%;margin-right:12px;font-weight:500;vertical-align:top}
.guide_msg2 .gs_bk{margin:12px 0 0 8px}
.guide_msg2 .gs_bk:hover{background:rgba(0,0,0,0.08)}
.guide_msg2 .link_close{position:absolute;right:0;top:0;padding:20px}
.guide_msg2 .link_close .ico_close{width:10px;height:10px;background-position:-200px -100px}
.guide_msg3{position:fixed;left:0;top:0;right:0;z-index:102;height:44px;text-align:center;line-height:42px;color:#fff;background-color:rgba(240,89,94,0.9)}
.guide_msg3 .desc_msg{overflow:hidden;padding:0 50px;height:100%;font-weight:500;white-space:nowrap;text-overflow:ellipsis}
.guide_msg3 .link_close{position:absolute;right:3px;top:0;padding:17px}
.guide_msg3 .link_close .ico_close{width:10px;height:10px;background-position:-200px -100px}
/* 레이어용 공통 테이블 */
.wrap_tbl .inner_wrap{padding:25px 25px 30px}
.wrap_tbl.wrap_scroll{overflow-x:hidden;overflow-y:auto;max-height:619px}
.wrap_tbl.wrap_scroll .inner_wrap{padding-right:0}
.wrap_tbl .tit_details{display:block;margin:24px 0 6px;color:#111}
.wrap_tbl .tit_details.fst{margin-top:0}
.wrap_tbl .tit_details .link_help .ico_help{margin-top:2px}
.wrap_tbl .tit_top{display:block;margin:-1px 0 27px;font-size:16px;line-height:24px}
.wrap_tbl .txt_top{position:relative;margin-top:-1px;margin-bottom:27px;line-height:22px}
.wrap_tbl .txt_top.txt_correct{padding-right:110px}
.wrap_tbl .txt_correct button.btn_gs{position:absolute;right:0;top:-3px}
.wrap_tbl .tit_top + .tit_details{margin-top:-5px}
.wrap_tbl .txt_top + .tit_details{margin-top:-5px}
.tbl_layer{width:100%;border:1px solid #ddd;border-left:0 none;border-right:0 none;border-collapse:collapse;text-align:left;table-layout:fixed}
.tbl_layer th{padding:15px 0 15px 31px;line-height:22px;font-weight:500;color:#555;background-color:#f5f5f5;border-top:1px solid rgba(0,0,0,0.05);text-align:left;vertical-align:middle}
.tbl_layer tr:first-child th{border-top:0 none}
.tbl_layer th .link_help{margin:1px 0 0 2px;vertical-align:top}
.tbl_layer .th_cate{border-right:1px solid #e6e6e6;font-weight:500;color:#111;background-color:#fafafa}
.tbl_layer td{line-height:22px;color:#333;border-top:1px solid rgba(0,0,0,0.05)}
.tbl_layer tr:first-child td{border-top:0 none}
.tbl_layer .inner_cell{padding:15px 30px 15px 31px;word-wrap:break-word;word-break:break-all}
.tbl_layer .txt_append{margin:4px 0 -3px;color:#777}
.tbl_layer .txt_append.fc_affirm{color:#37c333}
/* .tbl_layer .txt_error{margin:8px 0 -3px;line-height:16px} */
.tbl_layer .tr_box .inner_cell{padding-top:20px;padding-bottom:20px}
.tbl_layer .tr_box2 th{vertical-align:middle}
.tbl_layer .tr_box2 td .inner_cell{padding-top:20px;padding-bottom:20px}
.tbl_layer .box_checkinp,.tbl_layer .box_radioinp{height:22px;line-height:20px;vertical-align:top}
.tbl_layer .wrap_selinp{margin:-3px 0}
.tbl_layer .wrap_selinp:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_layer .wrap_selinp .box_checkinp,.tbl_layer .wrap_selinp .box_radioinp{float:left;margin-left:20px}
.tbl_layer .wrap_selinp .box_checkinp:first-child,.tbl_layer .wrap_selinp .box_radioinp:first-child{margin-left:0}
/* basic layer */
.basic_layer{position:relative;margin:60px auto}
.basic_layer .inner_basic_layer{position:relative;width:400px;margin:0 auto;border-radius:4px;background-color:#fff}
.basic_layer .layer_head{overflow:hidden;height:60px;padding:0 76px 0 30px;border-bottom:1px solid #ddd}
.basic_layer .btn_group{display:inline-block;vertical-align:top}
.basic_layer .btn_group .btn_gm{float:left;margin-left:10px}
.basic_layer .btn_group .btn_gm:first-child{margin-left:0}
.basic_layer .btn_close{position:absolute;top:14px;right:19px;width:16px;height:16px;padding:10px}
.basic_layer .ico_close{width:16px;height:16px;background-position:-810px -90px}
.basic_layer .tit_layer{display:block;overflow:hidden;font-weight:700;font-size:16px;line-height:60px;color:#111;text-overflow:ellipsis;white-space:nowrap}
.basic_layer .tit_layer .keyword_limit{display:inline-block;overflow:hidden;max-width:320px;text-overflow:ellipsis;white-space:nowrap;vertical-align:top}/** 2021-04-01 추가 */
.basic_layer .subtit_layer{display:block;margin:30px 0 20px;font-weight:500;font-size:16px;color:#111}
.basic_layer .subtit_layer:first-child{margin-top:0}
.basic_layer .txt_layer{padding:22px 30px 25px;font-size:16px;line-height:28px;letter-spacing:-1px}
.basic_layer .txt_layer2{padding:23px 30px 26px;font-size:14px;line-height:24px}
.basic_layer .txt_layer2 .link_txt{text-decoration:underline;color:#555}
.basic_layer .layer_subtit{height:36px;margin:30px 0 10px;line-height:24px}
.basic_layer .layer_subtit:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.basic_layer .layer_subtit .subtit_layer{float:left;margin-top:0;margin-bottom:0;height:100%}
.basic_layer .paging_wrap{margin-top:20px}
.basic_layer .itemtbl_wrap + .paging_wrap{margin-top:28px}
.basic_layer .modify_cause{overflow:hidden;position:relative;width:100%;border-bottom:1px solid #e5e5e5;background-color:#fff}
.basic_layer .modify_cause .inner_cause{padding:14px 25px 17px;font-weight:500;line-height:22px;color:#f64949}
.basic_layer .modify_cause .link_view{margin-left:8px;font-weight:400;text-decoration:underline;color:#777}
.basic_layer .layer_foot{padding:0 30px 30px;text-align:right}
.basic_layer .list_guide{padding:15px 30px 17px 28px;border:1px solid #ddd;border-radius:2px}
.basic_layer .list_guide li{position:relative;padding-left:8px;line-height:22px}
.basic_layer .list_guide li:after{position:absolute;left:0;top:10px;width:3px;height:3px;border-radius:50%;background-color:#555;content:''}
.basic_layer .list_guide .link_txt{color:#326edc}
.basic_layer .desc_agreeindivi{padding:13px 0 0 0;margin-bottom:-3px}
.basic_layer .txt_guidemsg{display:block;margin:16px 0 -4px;font-size:13px;color:#777}
.basic_layer .txt_guidemsg .txt_name{color:#111}
.basic_layer .txt_error{margin:8px 0 -2px;line-height:16px}
/* basic layer2 */
.basic_layer2{position:absolute;left:50%;top:50%;z-index:999}
.basic_layer2 .inner_basic_layer{position:relative;width:350px;margin:0 auto;padding:40px 25px 25px;border-radius:4px;text-align:center;background-color:#fff}
.basic_layer2 .inner_box{overflow:hidden}
.basic_layer2 .tit_layer{display:block;margin:0 0 20px;font-size:16px;line-height:24px;color:#111;text-align:center}
.basic_layer2 .txt_layer{line-height:22px;text-align:center}
.basic_layer2 .txt_layer + .tbl_layer{margin-top:25px}
.basic_layer2 .layer_foot{padding:0 30px 25px}
.basic_layer2 .btn_group{display:block;padding-top:26px;text-align:right}
.basic_layer2 .btn_group .align_c{margin-bottom:15px}
.basic_layer2 .btn_group .btn_gm{margin-left:10px}
.basic_layer2 .btn_group .btn_gm:first-child{margin-left:0}
.basic_layer2 .btn_close{position:absolute;top:0;right:0;width:12px;height:12px;padding:13px}
.basic_layer2 .ico_close{width:12px;height:12px;background-position:-880px -140px}
.basic_layer2 .tbl_layer{margin-top:27px}
.basic_layer2 .tbl_layer th{padding:15px 0 15px 21px}
.basic_layer2 .tbl_layer .inner_cell{padding:15px 20px 15px 21px}
.basic_layer2 .tbl_layer + .btn_group{padding-top:30px}
.basic_layer2 .txt_error{margin:8px 0 -2px;line-height:16px}
/* layer 내 회색박스(스크롤 있음) */
.adbox_scroll{padding:3px;margin-top:25px;border:1px solid #f5f5f5;background-color:#f5f5f5;text-align:left}
.inner_adbox_scroll{overflow-x:hidden;overflow-y:auto;max-height:437px;padding:1px 18px 21px;line-height:24px}
.adbox_scroll .tit_adbox{display:block;margin-top:19px;color:#f64949}
.adbox_scroll .tit_adbox + .txt_{margin-top:4px}
.adbox_scroll .link_wrap{margin-top:15px;text-align:left}
.adbox_scroll .link_adbox{font-size:13px;text-decoration:underline;color:#777}
/* basic layer3 (하단버튼 없음 / 좌측정렬 / 타이틀 텍스트 14px / 대시보드에서 사용) */
.basic_layer3{position:absolute;z-index:99}
.basic_layer3 .inner_basic_layer{position:relative;width:342px;padding:13px 20px;border-radius:4px;text-align:left;background-color:rgba(255,255,255,0.92);box-shadow:1px 1px 6px rgba(0,0,0,0.16)}
.basic_layer3 .tit_layer{display:block;font-weight:500;font-size:14px;line-height:26px;letter-spacing:-1px;color:#333}
.basic_layer3 .tit_loading{float:left;width:20px;padding:8px 16px 0 0}
.basic_layer3 .txt_layer{padding-top:21px;font-size:14px;line-height:24px;color:#555}
.basic_layer3 .txt_layer + .tbl_layer{margin-top:25px}
.basic_layer3 .btn_close{position:absolute;top:8px;right:8px;width:12px;height:12px;padding:12px}
.basic_layer3 .ico_close{width:12px;height:12px;background-position:-880px -140px}
.basic_layer3 .tbl_layer{margin-top:6px;margin-bottom:4px;border:none}
.basic_layer3 .tbl_layer th{padding:0;border:none;font-size:13px;font-weight:500;color:#326EDC;background:none}
.basic_layer3 .tbl_layer tr:last-child th{color:#F55353}
.basic_layer3 .tbl_layer th .ico_comm{margin-right:6px;margin-left:0}
.basic_layer3 .tbl_layer td{padding:0;border:none;font-size:13px;color:#555}
.basic_layer3 .txt_error{margin:8px 0 -2px;line-height:16px}
.basic_layer3 .link_help{text-decoration:underline}
/* basic layer4 (하단버튼 없음 / 타이틀 영역 블랙 / 세금계산서 사용) */
.basic_layer4 .inner_basic_layer{position:relative;width:218px;padding:0 16px 16px;margin:0 auto;border-radius:4px;text-align:left;background-color:#fff}
.basic_layer4 .tit_layer .logo_layer{display:inline-block;width:135px;height:18px;margin:26px 0 0 30px;background:url("../images/logo_keyword.png") 0 0 no-repeat;background-size:135px 18px;vertical-align:top}
.basic_layer4 .layer_body{padding:30px}
.basic_layer4 .layer_body .subtit_layer{font-size:18px;font-weight:700;color:#111}
.basic_layer4 .layer_body .wrap_table{margin-top:16px;width:659px}
.basic_layer4 .tbl_layer_tax .t_right{text-align:right}
.basic_layer4 .tbl_layer_tax .t_left{text-align:left}
.basic_layer4 .tbl_layer_tax .t_total{font-weight:500}
.basic_layer4 .tbl_layer_tax .t_italic{font-style:italic;font-weight:700}
.basic_layer4 .tbl_layer_tax,.basic_layer4 .tbl_inner_tax,.basic_layer4 .tbl_inner_tax2{width:100%;border-collapse:collapse;border-spacing:0}
.basic_layer4 .tbl_layer_tax tr + tr .tbl_inner_tax2{border:2px solid #111;border-top:0 none}
.basic_layer4 .tbl_inner_tax{border:2px solid #111;border-bottom:0 none}
.basic_layer4 .tbl_inner_tax tr:first-child th:first-child{padding:0 19px;text-align:left;font-weight:700;color:#111}
.basic_layer4 .tbl_inner_tax tr .tit_tax{font-size:16px;letter-spacing:0}
.basic_layer4 .tbl_inner_tax th,.basic_layer4 .tbl_inner_tax td{background:#FBFBFB}
.basic_layer4 .tbl_inner_tax th{height:24px;border-right:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax tr:last-child th,.basic_layer4 .tbl_inner_tax tr:last-child td{padding:0 5px;border-top:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax th,.basic_layer4 .tbl_inner_tax td,.basic_layer4 .tbl_inner_tax2 th,.basic_layer4 .tbl_inner_tax2 td{padding:0 2px;font-size:12px;line-height:18px;font-weight:500;letter-spacing:-0.6px}
.basic_layer4 .tbl_inner_tax2 th{height:23px;color:#777;background:#FBFBFB}
.basic_layer4 .tbl_inner_tax2 td{height:22px;text-align:center;color:#111}
.basic_layer4 .tbl_inner_tax2 tr:first-child th{border-bottom:1px solid #777}
.basic_layer4 .tbl_inner_tax2 th + th,.basic_layer4 .tbl_inner_tax2 td + td{border-left:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax2 tbody th{border-right:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax2 tbody tr + tr th,.basic_layer4 .tbl_inner_tax2 tbody tr + tr td{border-top:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax2 tr:first-child .t_total{border-bottom:0 none;background:#fff;color:#111}
.basic_layer4 .tbl_inner_tax3{width:100%;border:2px;border-style:solid;border-image-slice:2;border-image:linear-gradient(to right,#D95F5B 50%,#4E88D0 50%) 10}
.basic_layer4 .tbl_inner_tax3 tr th{height:34px;font-size:12px;font-weight:700;color:#D95F5B;border-right:1px solid #D95F5B;background:#FDF3F3}
.basic_layer4 .tbl_inner_tax3 tr td{padding:0 6px;font-size:12px;font-weight:500;letter-spacing:-0.6px;background:#FBFBFB;color:#111}
.basic_layer4 .tbl_inner_tax3 .f_right{font-size:11px;font-weight:400;color:#777}
.basic_layer4 .tbl_inner_tax3 tr:not(:first-child) th,.basic_layer4 .tbl_inner_tax3 tr:not(:first-child) td{border-top:1px solid #E5E5E5}
.basic_layer4 .tbl_inner_tax3 tr:not(:first-child) th:nth-child(n+2),.basic_layer4 .tbl_inner_tax3 tr:first-child th:nth-child(n+3){color:#4E88D0;border-right:1px solid #4E88D0;background:#F1F9FD}
.basic_layer4 .tbl_inner_tax3 tr:first-child th:nth-child(4){border-left:1px solid #4E88D0}
.basic_layer4 .tbl_layer_tax tfoot tr .tbl_inner_tax2{border-top:2px solid #111}
.basic_layer4 .layer_foot{padding:0 33px 37px 30px}
.basic_layer4 .layer_foot .list_layer li{overflow:hidden;position:relative;padding-left:7px;font-size:12px;line-height:16px;color:#555}
.basic_layer4 .layer_foot .list_layer .ico_dot{position:absolute;top:0;left:0}
/* 공통 - 툴팁 */
.tooltip_basic{z-index:100;white-space:normal}
.tooltip_basic:after,.tooltip_basic:before{display:none !important}
.tooltip_basic .link_txt{display:inline;color:#326edc}
.tooltip_basic .txt_tooltip{display:block;text-align:left;word-break:break-all}
.tooltip_basic .txt_tooltip + .txt_tooltip{margin-top:5px}
.tooltip_basic .txt_tooltip + .thumb_tipimg{margin-top:10px}
.tooltip_basic .thumb_tipimg + .txt_tooltip{margin-top:10px}
.tooltip_basic .thumb_tipimg{margin:5px 0 2px;text-align:center}
.tooltip_basic .thumb_tipimg .img_tipimg{vertical-align:top}
.tooltip_basic .tit_tooltip{display:block;text-align:left;font-weight:700;color:#333}
.tooltip_basic .tit_tooltip2{display:block;margin-bottom:6px;text-align:left;font-weight:700;color:#333}
.tooltip_basic .txt_tooltip2{display:block;text-align:left;color:#555;word-break:keep-all}
.tooltip_basic .txt_tooltip2{padding-bottom:3px}
.tooltip_basic .txt_tooltip2 + .tit_tooltip{margin-top:7px}
.tooltip_basic .desc_tooltip{line-height:27px}
.tooltip_basic .desc_tooltip dt{float:left;width:61px;font-weight:700;color:#111}
.tooltip_basic .desc_tooltip dd{overflow:hidden;color:#555}
.tooltip_basic.ly_tooltip{overflow-x:hidden;overflow-y:auto;max-width:275px;max-height:400px;padding:11px 16px;border-radius:4px;font-weight:400;font-size:12px;line-height:20px;background-color:#fff !important;color:#555 !important;text-align:left;box-shadow:0 2px 10px rgba(0,0,0,0.25)}
.tooltip_basic.ly_tooltip .ico_arrtip{display:none;position:absolute;right:auto;top:auto;left:11px;bottom:-6px;width:13px;height:6px;border:0 none;background-position:-140px -20px}
.tooltip_basic.ly_tooltip_type2{max-width:472px}
.tooltip_basic.ly_tooltip2{max-width:252px;padding:10px 16px;/*border:1px solid #0099e6;*/ border-radius:4px;font-size:12px;line-height:20px;background-color:#f0faff !important;color:#555 !important;box-shadow:0 2px 10px rgba(0,0,0,0.25)}
.tooltip_basic.ly_tooltip2 .ico_arrtip{display:none;position:absolute;left:11px;bottom:-6px;width:13px;height:6px;background-position:-290px -120px}
.tooltip_basic.ly_tooltip3{padding:1px 5px 2px;margin:0 !important;border:1px solid #888;border-radius:2px;font-size:12px;line-height:18px;color:#000 !important;background-color:#fff !important}
.tooltip_basic.ly_tooltip4{max-width:305px}
.tooltip_basic.ly_tooltip .list_tooltip{padding-top:7px}
.tooltip_basic.ly_tooltip .list_tooltip li{position:relative;padding-left:6px}
.tooltip_basic.ly_tooltip .list_tooltip .ico_dot{position:absolute;left:0;top:9px;width:2px;height:2px;background-color:#878787}
.tooltip_basic .list_tooltip.list_audience .ico_dot{position:absolute;top:2px;left:0}
.tooltip_basic .list_tooltip.list_audience .ico_dot + .limit_line{padding-left:6px}
.tooltip_basic .list_tooltip.list_audience .limit_line{display:block;overflow:hidden;text-overflow:ellipsis;/*white-space:nowrap*/ white-space:normal}/**확인필요*/
.tooltip_basic.ly_tooltip .list_tooltip.list_audience{padding-top:4px}
.tooltip_basic.ly_tooltip .list_tooltip.list_audience li{position:relative;padding-top:2px}
.tooltip_basic .list_tooltip.list_audience + .tit_tooltip{padding-top:10px}
.tooltip_basic .list_audience .subtit_audience{display:block;position:relative;padding-left:6px;line-height:18px;font-weight:700;font-size:12px;color:#555}
.tooltip_basic .list_audience .subtit_audience .ico_dot{position:absolute;top:0;left:0;width:3px;height:3px;border-radius:3px;background:#555}
.tooltip_basic .list_audience .desc_audience{display:inline-block;max-width:100%;padding-left:6px;font-size:12px;line-height:18px;color:#555;vertical-align:top}
.tooltip_basic .list_audience .desc_audience .f_right{padding-left:2px}
.tooltip_basic .list_audience li + li{margin-top:8px}
.tooltip_basic.tooltip_url{max-width:520px}
/* 공통 - table type */
.hide_caption{overflow:hidden;width:1px;font-size:1px;line-height:0;text-indent:-9999px}
.link_depthitem{display:block;position:relative;height:22px;line-height:22px;padding-right:30px;color:#333}
.link_depthitem:after{display:block;clear:both;content:''}
.link_depthitem:hover{text-decoration:none}
.link_depthitem .inner_depthitem{float:left;width:calc(50% + 14px);vertical-align:top}
.link_depthitem .inner_depthitem + .inner_depthitem{position:relative;width:calc(50% - 26px);padding-left:12px}
.link_depthitem .inner_depthitem + .inner_depthitem:after{position:absolute;top:-14px;left:0;width:1px;height:48px;background:#ebebeb;content:''}
.link_depthitem .ico_depth{overflow:visible;position:absolute;right:1px;top:0;width:10px;height:10px;margin:5px;background-position:-800px -215px}
.link_depthitem .ico_depth:after{position:absolute;right:-6px;top:-6px;width:20px;height:20px;border:1px solid #ddd;border-radius:2px;content:'열기'}
.link_depthitem:hover .ico_depth:after{content:'열기'}
.link_depthitem.on .ico_depth{background-position:-815px -215px}
.link_depthitem.on .ico_depth:after{content:'닫기'}
.tbl_g .tr_depthitem td,.tbl_g2 .tr_depthitem td{position:relative;background:#F1F6FC}
.tbl_g .tr_depthitem:hover td,.tbl_g2 .tr_depthitem:hover td{background:#F1F6FC}
.tbl_g .tr_depthitem:hover td:before,.tbl_g2 .tr_depthitem:hover td:before{position:absolute;top:0;right:0;width:100%;height:100%;background:rgba(0,0,0,0.03);content:''}
/* 테이블 타입 1 */
.tbl_g{width:100%;border-collapse:collapse;background-color:#fff}
.tbl_g + .tbl_g{margin-top:-1px;border-top:1px solid #ddd}
.tbl_g th,.tbl_g td{border-width:0 1px 1px 0;border-style:solid;font-size:13px;color:#333}
.tbl_g th{height:55px;white-space:nowrap;border-right-color:#ebebeb;border-bottom-color:#ebebeb;border-top:1px solid #ddd;color:#555;font-weight:500;background-color:#f5f5f5;text-align:left}
.tbl_g thead th{border-top:0 none}
.tbl_g tbody tr:first-child th{border-top:0 none}
.tbl_g td{height:47px;border-color:#ebebeb;border-bottom-color:#ebebeb}
.tbl_g .td_empty{background-color:#f5f5f5}
.tbl_g tbody tr:last-child td{border-bottom-color:#ddd}
.tbl_g th:last-child,.tbl_g td:last-child{border-right:0 none}
.tbl_g tbody tr.over td{background-color:rgba(0,0,0,0.03)}
.tbl_g tbody .thumb_link .link_txt:hover,.tbl_g .modify_budget .link_budget:hover{text-decoration:none}
.tbl_g tbody tr.on td{background-color:#f1f6ff}
.tbl_g tbody tr.over .thumb_link .link_more{opacity:1;filter:alpha(opacity=100)}
.tbl_g .inner_tbl{position:relative;padding:6px 12px 7px}
.tbl_g .inner_tbl .box_bidding + .txt_error{text-align:left}
.tbl_g .sortable .inner_tbl{padding-right:30px}
.tbl_g .sortable .hide_shim{margin-right:-30px}
.tbl_g .sortable .btn_menu{position:absolute;right:3px;top:5px;width:20px;height:20px}
.tbl_g .sortable .ico_menu{display:inline-block;width:0;height:0;margin-top:8px;border-left:4px solid transparent;border-right:4px solid transparent;border-top:5px solid #555;vertical-align:top}
.tbl_g .sortable.open_menu .ico_menu{border-top:0 none;border-bottom:5px solid #555}
.tbl_g .sortable.sort_on{background-color:#f9fbff}
.tbl_g .sortable.sort_on:hover{background-color:#f1f6ff}
.tbl_g .align_c{text-align:center}
.tbl_g .align_r{text-align:right}
.tbl_g .align_l{text-align:left}
.tbl_g .link_help{margin-top:0}
.tbl_g .tr_summary td{font-weight:500}
.tbl_g .nodata_info{height:330px;font-size:14px;color:#333;text-align:center;vertical-align:middle}
.tbl_g .nodata_info:hover{background-color:transparent}
.tbl_g .tr_nodata td{border-bottom:0 none}
.tbl_g .tr_loading td{height:527px;vertical-align:top}
.tbl_g .tr_loading .loading_wrap{padding-top:100px}
.tbl_g .tr_refresh td{height:527px;border-bottom:0 none;vertical-align:top}
.tbl_g .tr_refresh .refresh_wrap{padding-top:95px}
.tbl_g .th_select{width:36px}
.tbl_g .link_txt{color:#326edc;text-decoration:none}
.tbl_g .thumb_link{display:block;position:relative;padding-right:22px}
.tbl_g .thumb_link:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_g .thumb_link .link_txt{overflow:hidden;float:left;max-width:323px;line-height:17px;text-overflow:ellipsis;white-space:nowrap}
.tbl_g .thumb_link .link_txt:hover{text-decoration:underline}
.tbl_g .thumb_link .link_more{position:absolute;right:-2px;top:50%;margin-top:-10px;opacity:0;filter:alpha(opacity=0)}
.tbl_g .thumb_link .link_more .menu_layer{display:none}
.tbl_g .thumb_link .link_more .ico_more{width:15px;height:15px;margin:2px auto 2px;background-position:-885px -180px}
.tbl_g .thumb_link .link_more:hover .ico_more{background-position:-885px -195px}
.tbl_g .thumb_link .link_more.on .menu_layer{display:block}
.tbl_g .td_thumb .inner_tbl{padding-left:70px}
.tbl_g .td_thumb .img_thumb{width:60px;height:47px}
.tbl_g .td_thumb .link_thumb{position:absolute;left:0;top:50%;width:60px;height:47px;margin-top:-23.5px}
.tbl_g .td_thumb .link_thumb .inner_thumb{display:block;width:100%;height:100%;background-repeat:no-repeat;background-size:contain;background-position:50% 50%}
.tbl_g .td_thumb .link_thumb.thumb_mov:before{position:absolute;left:0;top:0;width:100%;height:100%;background-color:#000;opacity:0.15;filter:alpha(opacity=15);content:''}
.tbl_g .td_thumb .link_thumb .ico_mov{position:absolute;left:22px;top:12px;width:17px;height:23px;background-position:-210px -120px}
.tbl_g .switch_check{vertical-align:middle}
.ad_state{position:relative;padding-left:16px;line-height:17px}
.tbl_g .ad_state.btn_state{padding-right:45px}
.ad_state .ico_on,.ad_state .ico_off{position:absolute;top:4px;left:0;padding-right:6px}
.tbl_g .ad_state .ico_off:after{float:left}
.tbl_g .ad_state .btn_gs{position:absolute;top:50%;right:5px;height:19px;margin-top:-11px}
.tbl_g .ad_state .btn_gs .inner_g{width:30px;padding-left:0;padding-right:2px;line-height:13px;font-size:11px;text-indent:0}
.tbl_g .wrap_btn{display:inline-block;vertical-align:top}
.tbl_g .wrap_btn:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_g .wrap_btn .btn_gs{float:left;margin-left:5px}
.tbl_g .wrap_btn .btn_gs:first-child{margin-left:0}
/* 테이블 행 비활성화시 */
.tbl_g .tr_inactive td,.tbl_g .tr_inactive .link_txt{color:rgba(17,17,17,0.4);background:#FAFAFA}
.tbl_g .tr_inactive .td_thumb .img_thumb{opacity:0.4;filter:alpha(opacity=40)}
.tbl_g .tr_inactive .modify_budget{cursor:default;pointer-events:none}
.tbl_g .tr_inactive .modify_budget .link_budget{color:rgba(17,17,17,0.4)}
.tbl_g .tr_inactive .onoff_select .ico_on,.tbl_g .tr_inactive .onoff_select .ico_off{opacity:0.4;filter:alpha(opacity=40)}
.tbl_g .tr_inactive .onoff_select,.tbl_g .tr_inactive .onoff_select .link_selected{cursor:default;pointer-events:none}
.tbl_g .tr_inactive .onoff_select .link_selected:hover{border-color:transparent}
.tbl_g .tr_inactive .onoff_select .link_selected:hover:after{display:none}
.tbl_g .tr_inactive .check_inp,.tbl_g .tr_inactive .check_inp .inp_check{cursor:default;pointer-events:none}
.tbl_g .tr_inactive .check_inp .ico_check{background-position:-835px -50px}
.tbl_g .tr_inactive .box_inptxt{background-color:#e6e6e6;border-color:#ccc;border-left-color:#a3a3a3;border-top-color:#a3a3a3;cursor:default;pointer-events:none}
.tbl_g .tr_inactive .box_inptxt .inp_txt{color:#777}
.tbl_g .tr_inactive .ico_on,.tbl_g .tr_inactive .ico_off{opacity:0.4}
.tbl_g .td_inactive,.tbl_g .td_inactive .link_txt{color:rgba(17,17,17,0.4)}
.tbl_g .td_inactive.td_thumb .img_thumb{opacity:0.4;filter:alpha(opacity=40)}
.tbl_g .td_inactive .modify_budget{cursor:default;pointer-events:none}
.tbl_g .td_inactive .modify_budget .link_budget{color:rgba(17,17,17,0.4)}
.tbl_g .td_inactive .onoff_select .ico_on,.tbl_g .td_inactive .onoff_select .ico_off{opacity:0.4;filter:alpha(opacity=40)}
.tbl_g .td_inactive .onoff_select,.tbl_g .td_inactive .onoff_select .link_selected{cursor:default;pointer-events:none}
.tbl_g .td_inactive .onoff_select .link_selected:hover{border-color:transparent}
.tbl_g .td_inactive .onoff_select .link_selected:hover:after{display:none}
.tbl_g .td_inactive .check_inp,.tbl_g .td_inactive .check_inp .inp_check{cursor:default;pointer-events:none}
.tbl_g .td_inactive .check_inp .ico_check{background-position:-835px -50px}
.tbl_g .td_inactive .box_inptxt{background-color:#e6e6e6;border-color:#ccc;border-left-color:#a3a3a3;border-top-color:#a3a3a3;cursor:default;pointer-events:none}
.tbl_g .td_inactive .box_inptxt .inp_txt{color:#777}
.tbl_g .td_inactive .ico_on,.tbl_g .td_inactive .ico_off{opacity:0.4}
.tbl_g tr:hover td{background:rgba(0,0,0,0.03)}
.tbl_g .txt_limit{display:inline-block;display:-webkit-box;overflow:hidden;height:20px;max-width:100%;-webkit-line-clamp:1;-webkit-box-orient:vertical;vertical-align:top;white-space:normal}
.tbl_g .wrap_btn.after_item{margin-left:10px;vertical-align:middle}/** 글자뒤에 버튼이 올 경우 */
/* 테이블 타입 2 */
.tblg2_wrap{border-width:1px 0 1px;border-style:solid;border-top-color:#ddd;border-bottom-color:#ddd}
.tblg2_wrap .loading_wrap{display:table;width:100%}
.tblg2_wrap .loading_wrap .img_loading{display:table-cell;margin:0 auto}
.tbl_g2{table-layout:fixed;width:100%;border-collapse:collapse;background-color:#fff;border:0 none}
.tbl_g2 th{height:56px;border-width:0 0 1px 1px;border-style:solid;border-left-color:#ebebeb;border-bottom-color:#ebebeb;background-color:#f5f5f5;text-align:left;font-size:13px;font-weight:500;color:#555}
.tbl_g2 th:first-child{border-left:0 none}
.tbl_g2 td{height:47px;border-width:1px 0 0 1px;border-style:solid;border-color:#ebebeb;font-size:13px;color:#333;word-break:break-all}
.tbl_g2 tr:first-child td{border-top:0 none}
.tbl_g2 tr td:first-child{border-left:0 none}
.tbl_g2 th:first-child .inner_tbl{padding-left:20px}
.tbl_g2 td:first-child .inner_tbl{padding-left:20px}
.tbl_g2 th.align_c,.tbl_g2 td.align_c{text-align:center}
.tbl_g2 th.align_c .inner_tbl{padding-left:0;padding-right:0}
.tbl_g2 th.align_r,.tbl_g2 td.align_r{text-align:right}
.tbl_g2 th.align_l,.tbl_g2 td.align_l{text-align:left}
.tbl_g2 td.align_c .inner_tbl{padding-left:0;padding-right:0}
.tbl_g2 .tr_important td{background-color:#f1f6fd}
.tbl_g2 .inner_tbl{position:relative;padding:12px;line-height:22px}
.tbl_g2 .sortable{cursor:pointer}
.tbl_g2 .sortable .inner_tbl{padding-right:30px}
.tbl_g2 .sortable:hover{background-color:#ededed}
.tbl_g2 .sortable.sort_on{background-color:#f1f6ff}
.tbl_g2 .sortable.sort_on:hover{background-color:#f1f6ff}
.tbl_g2 tr.on td{background-color:#f1f6ff}
.tbl_g2 tr.over td{background-color:rgba(0,0,0,0.03)}
.tbl_g2 .tr_inactive td{color:rgba(17,17,17,0.4)}
.tbl_g2 .tr_inactive .check_inp,.tbl_g2 .tr_inactive .check_inp .inp_check{cursor:default;pointer-events:none}
.tbl_g2 .tr_inactive .check_inp .ico_check{background-position:-835px -50px}
.tbl_g2 .show_del{padding-right:196px}
.tbl_g2 .show_del .btn_del{position:absolute;right:0;top:50%;width:50px;height:40px;margin-top:-20px}
.tbl_g2 .show_del .ico_del{width:10px;height:10px;margin:0 auto;background-position:0 -50px}
.tbl_g2 .show_del .btn_del:hover .ico_del{background-position:-180px -100px}
.tbl_g2 .wrap_btn{display:inline-block;margin:-1px 0 -4px;vertical-align:top}
.tbl_g2 .wrap_btn:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_g2 .wrap_btn button,.tbl_g2 .wrap_btn a{float:left;margin-left:10px}
.tbl_g2 .wrap_btn button:first-child,.tbl_g2 .wrap_btn a:first-child{margin-left:0}
.tbl_g2 .wrap_btn.after_item{margin-left:10px}
.tbl_g2 .nodata_info{height:117px;font-size:14px;color:#333;text-align:center;vertical-align:middle}
.tbl_g2 .onoff_select{margin:-2px 0 -3px;vertical-align:top}
.tbl_g2 .onoff_select .link_selected{margin:0 0 0 12px}
.tbl_g2 .onoff_select .opt_list{left:12px}
.tbl_g2 .link_txt,.itemtbl_wrap .link_txt{color:#326edc}
.tbl_g2 .link_filedown{color:#333}
.tbl_g2 th .link_g{margin:2px 0 0 2px;vertical-align:top}
.tbl_g2 .tr_inactive .check_star .ico_check{background-position:-250px -120px}
.tbl_g2 .tr_inactive .link_txt{color:rgba(17,17,17,0.4)}
.tbl_g2 .txt_arrow{display:inline-block;margin-top:-1px;vertical-align:top}
.tbl_g2 .tr_depthitem td{background-color:#F1F6FC}
.tbl_g2_type2 td{vertical-align:top}
.tbl_g2 .tr_summary td{font-weight:700}
.tbl_g2 .tr_inactive td,.tbl_g2 .tr_inactive td.td_state,.tbl_g2 .td_inactive{color:#ccc}
.tbl_g2 .desc_entrust{display:inline-block;vertical-align:middle}
.imgnotice_wrap .tbl_g2 .link_txt{overflow:hidden;display:block;width:850px;text-overflow:ellipsis;white-space:nowrap;color:#333}
.imgnotice_wrap .tbl_g2 tr:hover td{background-color:rgba(0,0,0,0.03)}
.imgnotice_wrap .tbl_g2 .tr_depthitem:hover td{background-color:#F1F6FC}
.imgnotice_wrap .tbl_g2 .ico_new{display:inline-block;width:35px;height:16px;margin:3px 0 0 7px;background-position:-790px -165px;vertical-align:top}
/* 테이블내 스몰버튼 컬러,보더 변경 */
.tbl_g .btn_gs .inner_g,.tbl_g2 .btn_gs .inner_g,.tbl_g3 .btn_gs .inner_g{padding:5px 9px 6px;font-size:11px}
.tbl_g .in_active .btn_gs,.tbl_g2 .in_active .btn_gs,.tbl_g3 .in_active .btn_gs{opacity:0.4;outline:none;cursor:default}
/* 테이블 타입 3 */
.tbl_g3{width:100%;border-collapse:collapse;border:1px solid #ddd;table-layout:fixed}
.tbl_g3 th{width:190px;border-top:1px solid rgba(0,0,0,0.05);line-height:22px;color:#111;font-size:14px;font-weight:500;text-align:left;vertical-align:top}
.tbl_g3 tr:first-child th{border-top:0 none}
.tbl_g3 td{border-top:1px solid rgba(0,0,0,0.05);line-height:20px;font-size:14px;color:#333;background-color:#fff;word-break:break-all;border-radius:0 0 4px 0}
.tbl_g3 tr:first-child td{border-top:0 none}
.tbl_g3 th .inner_tbl{padding:24px 0 0 20px;line-height:20px}
.tbl_g3 td .inner_tbl{padding:24px 29px 24px 30px}
.tbl_g3 .tr_box th .inner_tbl{height:96px;line-height:92px}
.tbl_g3 .tr_box td .inner_tbl{padding-top:30px;padding-bottom:30px}
.tbl_g3 .tr_box2 th{vertical-align:middle}
.tbl_g3 .tr_box2 th .inner_tbl{height:auto;padding-top:24px;padding-bottom:27px;line-height:inherit}
.tbl_g3 .tr_box2 td .inner_tbl{padding-top:30px;padding-bottom:30px}
.tbl_g3 .tr_box3 td .inner_tbl{padding:22px 29px 25px 30px}
.tbl_g3 .wrap_btn{display:inline-block;vertical-align:top}
.tbl_g3 .wrap_btn:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_g3 .wrap_btn button,.tbl_g3 .wrap_btn a{float:left;margin-left:10px}
.tbl_g3 .wrap_btn button:first-child,.tbl_g3 .wrap_btn a:first-child{margin-left:0}
.tbl_g3 .wrap_btn.after_item{margin-left:10px;vertical-align:middle}/** 글자뒤에 버튼이 올 경우 */
.tbl_g3 .link_g{margin:1px 0 0 2px;vertical-align:top}
.tbl_g3 .link_help{margin:-2px 0 0 2px;vertical-align:middle}
.tbl_g3 .box_large{width:410px}
.tbl_g3 .opt_small{min-width:98px}
.tbl_g3 .opt_small .opt_list{min-width:98px}
.tbl_g3 .opt_medium{min-width:258px;max-width:inherit}
.tbl_g3 .opt_medium .opt_list{min-width:258px;max-width:inherit}
.tbl_g3 .group_inp .opt_medium{float:left;margin-left:10px}
.tbl_g3 .group_inp .opt_medium:first-child{margin-left:0}
.tbl_g3 .txt_append{margin:4px 0 -1px;font-size:13px;line-height:22px;color:#777}
.tbl_g3 .txt_append:first-child{margin-top:0}
.tbl_g3 .group_inp .txt_append{float:left;margin:0;line-height:34px}
.tbl_g3 .group_inp .txt_noti{float:left;margin-left:14px;font-size:13px;line-height:34px;color:#777}
.tbl_g3 .tr_box .txt_append{margin-bottom:-4px}
.tbl_g3 .detail_append,.tbl_g3 .list_append{margin:4px 0 -1px;font-size:13px;line-height:22px;color:#777}
.tbl_g3 .detail_append dd{margin-left:4px}
.tbl_g3 .list_append li{margin:10px 0 0 15px;text-indent:-10px}
.tbl_g3 .txt_append + .detail_append,.tbl_g3 .txt_append + .list_append{margin-top:14px}
.tbl_g3 .tr_box .detail_append,.tbl_g3 .tr_box .list_append{margin-bottom:-4px}
.tbl_g3 .limit_cell{width:600px}
.tbl_g3 .limit_cell .box_inptxt{overflow:hidden}
.tbl_g3 .wrap_selinp{margin-top:3px;line-height:18px}
.tbl_g3 .wrap_selinp:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.tbl_g3 .wrap_selinp .box_checkinp{float:left;height:22px;margin-left:30px}
.tbl_g3 .wrap_selinp .box_checkinp:first-child{margin-left:0}
.tbl_g3 .wrap_selinp .box_checkinp .lab_check{color:#333}
.tbl_g3 .wrap_selinp .box_radioinp{float:left;height:22px;margin-left:30px}
.tbl_g3 .wrap_selinp .box_radioinp:first-child{margin-left:0}
.tbl_g3 .wrap_selinp .box_radioinp .lab_radio{color:#333}
.tbl_g3 .wrap_selinp + .group_inp{margin-top:17px;margin-bottom:3px}
.tbl_g3 .wrap_selinp + .limit_cell{margin-top:17px;margin-bottom:3px}
.tbl_g3 .list_selinp li{margin-top:20px}
.tbl_g3 .list_selinp li:first-child{margin-top:0}
.tbl_g3 .list_selinp .box_checkinp .lab_check{color:#333}
.tbl_g3 .list_selinp + .txt_error{margin-top:10px}
.tbl_g3 .tr_box .wrap_selinp{margin-top:0}
.tbl_g3 .tr_box2 .wrap_selinp{margin:-3px 0}
.tbl_g3 .define_selinp{margin:2px 0 1px}
.tbl_g3 .txt_append + .define_selinp{margin-top:14px}
.tbl_g .txt_error,.tbl_g2 .txt_error,.tbl_g3 .txt_error{margin:4px 0 -4px}/** 2021-10-15 수정 */
.tbl_g3 .list_dot li{position:relative;padding-left:9px}
.tbl_g3 .list_dot li:before{position:absolute;left:0;top:9px;width:4px;height:4px;border-radius:4px;background-color:#333;content:''}
.tbl_g3 .txt_bar{display:inline-block;width:1px;height:14px;margin:0 11px 0 12px;background-color:#ddd;vertical-align:middle}
.tbl_g3.tbl_g3_type2{border-color:#ddd}
.tbl_g3.tbl_g3_type2 th{width:119px}
.tbl_g3.tbl_g3_type2 th .inner_tbl{height:53px;line-height:50px}
.tbl_g3.tbl_g3_type2 td .inner_tbl{padding:14px 29px 17px 30px}
.tbl_g3.tbl_g3_type2 .tr_box th .inner_tbl{height:76px;line-height:72px}
.tbl_g3.tbl_g3_type2 .tr_box td .inner_tbl{padding-top:20px;padding-bottom:20px}
.tbl_g3 tr.error th{position:relative}
.tbl_g3 tr.error th:before{position:absolute;top:0;left:0;width:4px;height:100%;background-color:red;content:''}
/* 차순정렬 */
.sortable .desc_sort{position:absolute;top:50%;right:32px;margin-top:-12px;font-weight:400;font-size:11px;color:#777;line-height:20px;text-decoration:none}
.sortable.sort_on .desc_sort{color:#555}
.sortable .ico_sort{position:absolute;top:50%;right:10px;width:7px;height:10px;margin-top:-5px;background-position:-810px -205px;opacity:0.2;cursor:pointer}
.sortable .ico_sort:hover{opacity:0.6}
.sortable .desc_sort .ico_sort{display:inline-block;position:static;margin:5px 0 0 3px;vertical-align:top}
.sortable.sort_on .ico_sort{opacity:0.6}
.sortable.sort_up .ico_sort{background-position:-810px -205px}
.sortable.sort_down .ico_sort{background-position:-820px -205px}
/* 공통 탭 */
.tab_g{overflow:hidden;height:42px;border:1px solid rgba(0,0,0,0.15);background-color:#fff}
.tab_g li{float:left}
.tab_g .link_tab{display:block;width:130px;height:42px;line-height:40px;font-size:13px;text-decoration:none;color:#555;text-align:center}
.tab_g .on .link_tab{position:relative;z-index:1;background-color:#326edc;font-weight:500;color:#fff}
.tab_g6{height:53px;padding-left:10px;border-bottom:1px solid #e6e6e6}
.tab_g6 li{float:left;height:100%;margin-left:0;font-size:13px}
.tab_g6 .link_tab{display:block;height:100%;padding:15px 10px 0;box-sizing:border-box;font-weight:500;color:#777;text-decoration:none}
.tab_g6 .on .link_tab{position:relative;font-weight:700;color:#326EDC}
.tab_g6 .on .link_tab:after{position:absolute;left:0;bottom:-1px;width:100%;height:2px;background-color:#326edc;content:''}
.tab_g8{height:40px;background-color:#f5f5f5}
.tab_g8 li{float:left}
.tab_g8 .link_tab{display:block;min-width:100px;height:40px;padding:0 20px;line-height:42px;text-decoration:none;color:#777;text-align:center}
.tab_g8 .link_tab:hover{color:#111}
.tab_g8 .on .link_tab{position:relative;z-index:1;font-weight:bold;color:#111;background-color:#fff;border-radius:5px 5px 0 0}
/*  공통 리스트 도트 타입 */
.list_append{margin-top:15px}
.list_append li{position:relative;padding-left:8px;color:#555;line-height:22px}
.list_append li:first-child{padding-top:0px}
.list_append li:after{position:absolute;top:9px;left:0;width:3px;height:3px;font-size:0;background-color:#555;border-radius:50%;vertical-align:top;content:''}
.list_append .link_append{color:#326edc}
/* 공통 폼 레이아웃 */
.managebox_tit{height:36px;padding:12px 20px}
.managebox_tit + .managebox_tit{border-top:1px solid #EBEBEB}
.managebox_tit + .tbladtarget_wrap,.managebox_tit + .tbladtarget_wrap .tbl_adtarget{border-radius:0 0 4px 4px}
.managebox_tit:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.managebox_tit .tit_box{float:left;height:34px;margin-right:30px;font-size:16px;line-height:35px;color:#111}/** 2021-04-01_v2 수정 */
.managebox_tit .tit_box .sub_title{font-size:14px;color:#777;letter-spacing:0}
.managebox_tit .f_left .single_wrap:first-child{margin-left:0}
.managebox_tit .f_left .single_wrap .btn_gm{margin-left:6px}
.managebox_tit .f_left .single_wrap .btn_gm:first-child{margin-left:0}
.managebox_tit .link_g{margin:9px -2px 0 1px}
.managebox_tit .link_help2{margin:-3px 0 0 10px}
.managebox_tit .subtit_box{display:inline-block;height:36px;padding:0;font-size:14px;line-height:34px;color:#111}
.managebox_tit .subtit_box2{display:inline-block;height:36px;font-size:13px;line-height:34px;color:#777}
.managebox_tit .txt_num{color:#326edc}
.managebox_tit .link_dataguide{display:inline-block;padding-top:6px;color:#555;vertical-align:top;text-decoration:underline}
.managebox_tit .member_refuse{margin-top:7px}
.ad_managebox{border:1px solid #ddd;background-color:#fff;box-shadow:none}
.ad_managebox:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.ad_managebox + .ad_managebox{margin-top:20px}
.ad_managebox .hide_head th{overflow:hidden;height:0;border:0 none;line-height:0;text-indent:-9999px}
.ad_managebox .tblg2_wrap{margin-bottom:-1px}
.ad_managebox .tblg2_wrap .tbl_g2{background:none}
.ad_managebox .tbl_g2 .link_txt{text-decoration:none}
.ad_managebox .tbl_g3{border-width:1px 0 1px;border-style:solid;border-top-color:#ddd;border-bottom-color:#ddd;margin-bottom:-1px}
.ad_managebox .managebox_memo{padding:20px;border-top:1px solid #ddd;background-color:#f5f5f5}
.ad_managebox .managebox_memo:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.ad_managebox .managebox_memo .box_inptxt{float:left}
.ad_managebox .managebox_memo .btn_gm{float:left;margin-left:10px}
.ad_managebox .managebox_memo .txt_error{float:left;height:36px;margin-left:9px;line-height:34px}
.ad_managebox .managebox_memo + .tblg2_wrap{border-top-color:#ddd}
.ad_managebox .paging_wrap{margin:28px 0 29px}
.ad_managebox .desc_append{line-height:25px;margin:24px 20px 25px}
.ad_managebox .desc_append .ico_star{display:inline-block;width:16px;height:16px;margin:5px 2px 0 0;background-position:-270px -120px;vertical-align:top}
.ad_managebox .list_append li{position:relative;margin-top:18px;padding-left:10px}
.ad_managebox .list_append li:first-child{margin-top:0}
.ad_managebox .list_append li:before{position:absolute;left:0;top:12px;width:2px;height:2px;font-size:0;background:#555;vertical-align:top;content:''}
.ad_managebox .paging_wrap + .desc_append{margin-top:43px}
/* 회원가입 */
.loginagree_wrap{width:1180px;margin:0 auto}
/* 공통 상단 프로그레스 */
.scrolling .ad_progress{border-bottom:1px solid rgba(0,0,0,0.15)}
.ad_progress{position:fixed;top:60px;left:60px;right:0;overflow:hidden;min-width:1180px;background-color:#fff;z-index:99}
.lnb_on .ad_progress{left:200px}
.ad_progress .info_step{display:table;overflow:hidden;max-width:578px;margin:0 auto;padding:13px 0 12px;font-size:0;text-align:center}
.ad_progress .desc_step{float:left;height:16px;font-size:14px;color:#999;line-height:16px}
.ad_progress .step_num{display:inline-block;width:16px;height:16px;margin:0 8px 0 0;background-color:#ccc;border-radius:20px;text-align:center;line-height:16px;font-size:11px;color:#fff;vertical-align:top}
.ad_progress .step_line{float:left;width:20px;height:1px;margin:8px 10px 0 8px;background-color:#ccc}
.ad_progress .desc_step.on{font-weight:bold}
.ad_progress .desc_step.on,.ad_progress .desc_step.end{color:#111}
.ad_progress .desc_step.on .step_num{background-color:#1E202C;font-weight:700}
.ad_progress .desc_step.end .step_num{display:inline-block;overflow:hidden;width:16px;height:16px;font-size:0;line-height:0;background:url("../images/ico_comm.png") no-repeat;background-size:900px 800px;text-indent:-9999px;vertical-align:top;background-position:-790px -595px}
.lnb_over .ad_progress{left:200px}
.dsp_tit .ad_progress{float:right;position:relative;top:auto;width:auto;min-width:auto;background-color:transparent}
.dsp_tit .info_step{float:right;width:auto;padding:6px 0 1px}
.ad_agreebox{overflow:hidden;padding-bottom:19px;border:1px solid #ddd;background-color:#fff;color:#111}
.ad_agreebox .subtit_subject{font-weight:500;margin-bottom:20px}
.ad_agreebox .fc_info{font-weight:500}
.ad_agreebox .box_checkinp .lab_check{color:#111}
.ad_agreebox .info_allagree{padding:29px 40px 30px;font-weight:700;font-size:16px;border-bottom:1px solid rgba(0,0,0,0.08)}
.ad_agreebox .info_service{padding:30px 40px 21px}
.ad_agreebox .info_service .detail_agree{overflow-y:auto;height:148px;padding:19px 27px 20px 28px;line-height:22px;color:#555;border:1px solid rgba(0,0,0,0.15);border-radius:2px}
.ad_agreebox .info_agree{overflow:hidden;padding:30px 40px 20px;line-height:22px}
.ad_agreebox .info_agree .fc_warn2{font-weight:500}
.ad_agreebox .info_agree .desc_agree{margin-bottom:18px;color:#333}
.ad_agreebox .info_agree .txt_personal{margin-top:20px;color:#555}
.ad_agreebox .info_agree .detail_agree{overflow-y:auto;height:88px;padding:19px 28px 19px;line-height:22px;color:#555;border:1px solid rgba(0,0,0,0.15);border-radius:2px}
.ad_agreebox .tbl_g3{border-left:0 none;border-right:0 none}
.ad_agreebox .tbl_g3 th{color:#555;background-color:#f5f5f5;border-top-color:rgba(0,0,0,0.05)}
.ad_agreebox .tbl_g3 th .inner_tbl{height:41px;padding:21px 0 0 29px}
.ad_agreebox .tbl_g3 td{color:#555;line-height:22px;border-top-color:rgba(0,0,0,0.05)}
.ad_agreebox .tbl_g3 td .inner_tbl{padding:20px 29px 20px 30px}
.wrap_material .ad_managebox .managebox_tit + .tbl_g3 td .inner_tbl{padding-left:0}
.ad_agreebox .tbl_g3 .list_dot li:before{top:11px;background-color:#555}
.login_userinfowrap{width:1180px;margin:0 auto}
.login_userinfowrap .box_inpname{width:256px}
.login_userinfowrap .group_inp .single_wrap{margin-left:15px}
.settingmyinfo_wrap .group_num .txt_num{display:inline-block;margin-right:15px;vertical-align:top}
.settingmyinfo_wrap .group_num .btn_gm{margin-top:-6px}
.settingmyinfo_wrap .box_inpname{width:284px}
.timer_set{float:left;position:relative;height:36px;margin-left:20px;padding-left:24px;font-size:13px;line-height:35px;color:#111;vertical-align:top}
.timer_set .ico_timer{position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;background-position:-720px -550px}/** 광고계정만들기 **/
.account_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.account_wrap .dsp_tit{margin-bottom:15px;padding-bottom:15px;border-bottom:1px solid rgba(0,0,0,0.05)}
.account_wrap .tit_done{border-bottom:0 none}
.account_wrap .list_append{margin-bottom:29px;font-size:13px}
.account_type{margin:29px -10px 0}
.account_type:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.account_type .box_radioinp{float:left;width:calc(33.3333% - 20px);margin:0 10px}
.account_type .box_radioinp:last-child{margin-right:-2px}
.account_type .inp_radio{top:0;width:100%;height:100%;margin-top:0}
.account_type .lab_radio{position:relative;width:100%;height:278px;padding:0;border:1px solid #ddd;text-align:center;background-color:#fff}
.account_type .ico_check{display:none;position:absolute;top:58px;left:50%;width:30px;height:30px;margin-left:-15px;background-position:-700px -570px}
.account_type .on .ico_check,.account_type .inp_radio:checked + .lab_radio .ico_check{display:block}
.account_type .inp_radio:checked + .lab_radio:after,.account_type .box_radioinp.on .lab_radio:after{position:absolute;top:-1px;left:-1px;right:-1px;bottom:-1px;width:100%;height:277px;background-color:rgba(50,110,220,0.05);border:2px solid #326EDC;content:''}
.account_type .in_active{pointer-events:none;opacity:.4}
.account_type .tit_type{display:block;margin:108px 0 15px;line-height:29px;font-size:20px;color:#111}
.account_type .desc_type{color:#333}
/* 사업자등록번호검색 */
.account_wrap .ad_managebox .box_inptxt{width:698px}
.account_wrap .group_inp.hyphen_inp .box_inptxt{width:46px;padding:7px 11px}
.account_wrap .group_inp.hyphen_inp .lst{width:76px}
.account_wrap .hyphen_inp .box_inptxt .lab_txt{left:0;width:100%;text-align:center}
.account_wrap .single_wrap{margin-left:15px}
.account_wrap .multi_post .box_inptxt{margin-top:15px}
.account_wrap .multi_post .group_inp .box_inptxt{width:286px;margin-top:0}
.account_wrap .managebox_info .tbl_g3{width:1140px;margin:0 auto 20px}
.account_wrap .managebox_info .tbl_g3 th{color:#555;background-color:#f5f5f5}
.account_wrap .managebox_new td .inner_tbl{padding-right:234px}
.managebox_info .tbl_g3 td .inner_tbl{padding:20px 29px 20px 20px}
.managebox_info .tbl_g3 th .inner_tbl{padding:20px 0 0 30px}
/* 광고계정생성 완료 */
.account_table{border:1px solid #ddd}
.tbl_account{overflow:hidden;width:100%;border:0 none;border-collapse:collapse;text-align:left;table-layout:fixed}
.tbl_account th{text-align:left}
.account_table th{width:200px;padding:25px 0 25px 20px;border-top:1px solid rgba(0,0,0,0.08);color:#111;line-height:20px;font-weight:500;background-color:#fff;vertical-align:top}
.account_table tr:first-child th{border-top:0 none}
.account_table td{padding:25px 0;border-top:1px solid rgba(0,0,0,0.08);color:#333;line-height:20px;background-color:#fff;vertical-align:top}
.account_table tr:first-child td{border-top:0 none}
.account_wrap .ad_agreebox{margin-top:20px;padding-bottom:0}
.account_wrap .ad_agreebox .info_agree{padding:20px 60px 22px 20px}
.account_wrap .ad_agreebox .info_agree .txt_personal{color:#333}
/* 내 광고계정 */
.myinfo_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.myinfo_wrap .tbl_g2 .td_warn{color:#F55353}
.myinfo_wrap .tbl_g2 .nodata_info{height:330px}
.delcancel_layer .inner_basic_layer{width:820px}
.delcancel_layer .layer_body{padding:30px}
.myinfo_wrap .group_inp.hyphen_inp .box_inptxt{width:46px;padding:7px 11px}
.myinfo_wrap .group_inp.hyphen_inp .lst{width:76px}
.myinfo_wrap .hyphen_inp .box_inptxt .lab_txt{left:0;width:100%;text-align:center}
.myinfo_wrap .group_inp .box_inptxt{width:286px}
/* 초대받은 계정정보 */
.invite_wrap{width:1180px;margin:0 auto}
.invite_wrap .dsp_tit{margin-bottom:15px;padding-bottom:15px;border-bottom:1px solid rgba(0,0,0,0.05)}
.invite_wrap .tit_done{border-bottom:0 none}
.invite_wrap .desc_append{margin-bottom:29px;font-size:13px;color:#555;line-height:22px}
.ad_invitebox{overflow:hidden;border-radius:4px;border:1px solid rgba(0,0,0,0.15);background-color:#fff;box-shadow:0 1px 3px 1px rgba(0,0,0,0.04)}
.ad_invitebox:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.ad_invitebox .tbl_g3{border:1px solid transparent}
.ad_invitebox .tbl_g3 th{vertical-align:middle}
.ad_invitebox .tbl_g3 .inner_tbl{padding:15px 20px 15px 20px}
.ad_invitebox .tbl_g3 .txt_limit{max-width:336px}
.ad_invitebox .tbl_g3 .btn_full{width:90px}/** 내정보 **/
/* 개인정보 설정 */
.settingmyinfo_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.settingmyinfo_wrap .ad_managebox{margin-top:20px}
.settingmyinfo_wrap .ad_managebox .tbl_g3{border-top:0 none}
/* 정보 수신 설정 */
.settingmyinfo_wrap .ad_agreebox{margin-top:20px}
.settingmyinfo_wrap .ad_agreebox .info_agree{padding:24px 19px 20px}
.settingmyinfo_wrap .ad_agreebox .info_agree .detail_agree{height:44px}
/* 알림설정 */
.adnotify_managebox:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.adnotify_managebox .ad_managebox{float:left;position:relative;width:348px;height:588px}
.adnotify_managebox .managebox_tit{padding:14px 20px 13px}
.adnotify_managebox .choose_account{float:inherit;max-width:958px;min-width:818px;width:100%;margin-left:360px;margin-right:-1px;vertical-align:top}
.adnotify_managebox .txt_guide{display:inline-block;padding-top:9px;font-size:13px;vertical-align:top}
.adnotify_managebox .detail_adaccound{overflow-x:hidden;overflow-y:auto;position:relative;height:454px;margin:0 19px;border:1px solid #ddd;border-radius:2px;background-color:#fff}
.adnotify_managebox .detail_adaccound .choice_num{position:absolute;right:20px;top:18px;font-size:14px;line-height:20px}
.adnotify_managebox .detail_adaccound .choice_num .empty_g{font-weight:bold;color:#326EDC}
.adnotify_managebox .detail_adaccound .list_targetitem:first-child{padding:8px 0 9px;border-bottom:1px solid #f2f2f2}
.adnotify_managebox .detail_adaccound .list_targetitem:first-child .lab_check{font-weight:500}
.adnotify_managebox .detail_adaccound .targetitem_scroll{padding:9px 0;margin-bottom:9px}
.adnotify_managebox .detail_adaccound .box_checkinp{display:block;letter-spacing:-0.04em}
.os_mac .adnotify_managebox .detail_adaccound .box_checkinp{letter-spacing:0}
.adnotify_managebox .detail_adaccound .box_checkinp .inp_check{left:19px;top:12px;margin:0}
.adnotify_managebox .detail_adaccound .box_checkinp .lab_check{width:100%;padding:10px 19px 12px 45px;font-size:14px;line-height:20px;color:#333;box-sizing:border-box}
.adnotify_managebox .detail_adaccound .box_checkinp .ico_check{left:19px;top:12px;margin:0}
.adnotify_managebox .detail_adaccound .targetitem_scroll .box_checkinp:hover .lab_check{background-color:#f7f7f7}
.adnotify_managebox .detail_adaccound .targetitem_scroll .box_checkinp.on .lab_check{background-color:#f1f6fc;color:#326EDC}
.adnotify_managebox .detail_adaccound .targetitem_scroll .box_checkinp .inp_check:checked + .lab_check{background-color:#f1f6fc;color:#326EDC}
.adnotify_managebox .detail_chooseaccount{padding:0 19px 19px}
.adnotify_managebox .detail_chooseaccount .tbl_g3{overflow:visible;border-top:1px solid #ddd;border-bottom:1px solid #ddd}
.adnotify_managebox .detail_chooseaccount .tbl_g3 th{background-color:#f5f5f5}
.adnotify_managebox .detail_chooseaccount .box_checkinp .link_help{margin:0 0 0 2px;vertical-align:top}
.adnotify_managebox .tbl_g3 th .inner_tbl{height:auto;min-height:41px;padding:21px 0 0 20px}
.adnotify_managebox .tbl_g3 td .inner_tbl{padding:21px 30px}
.adnotify_managebox .list_selinp li{margin-top:22px}
.adnotify_managebox .list_guide{margin-top:21px}
.adnotify_managebox .list_guide li{position:relative;padding-left:8px;font-size:14px;line-height:22px}
.adnotify_managebox .list_guide li:after{position:absolute;left:0;top:9px;width:4px;height:4px;border-radius:4px;background-color:#555;content:''}
.adnotify_managebox .nodata_wrap{display:table;width:100%;height:100%;text-align:center}
.adnotify_managebox .nodata_wrap .inner_nodata{display:table-cell;vertical-align:middle}
.adnotify_managebox .nodata_wrap .txt_nodata{color:#333}
.adnotify_managebox .nodata_wrap .txt_nodata + .btn_gm{margin-top:19px}
/* 공통 - 에러 */
.error_wrap{margin-top:205px}
.error_wrap .tit_error{display:block;font-weight:500;font-size:24px;line-height:26px;color:#111;text-align:center}
.error_wrap .txt_error{margin-top:35px;font-size:16px;line-height:24px;color:#333;text-align:center}
.error_wrap .page_btn{margin-top:50px;text-align:center}
/* 유사타겟 만들기(유사타겟) 레이어 */
.itemtbl_wrap{overflow:hidden;border:1px solid #ddd}
.itemtbl_wrap .nodata_wrap{display:table;width:100%;height:354px}
.itemtbl_wrap .nodata_wrap .inner_nodata{display:table-cell;line-height:24px;text-align:center;vertical-align:middle;color:#777}
.itemtbl_wrap .nodata_wrap .tit_nodata{display:block;margin-bottom:9px;color:#555}
.itemtbl_wrap .itemtbl_head{display:table;width:100%;border-bottom:1px solid #e5e5e5}
.itemtbl_wrap .itemtbl_body{overflow-y:auto;max-height:275px}
.itemtbl_wrap .itemtbl_body .tbl_itemchoice thead tr{display:none}
.itemtbl_wrap .thumb_loading{display:table;width:100%;height:275px;text-align:center}
.itemtbl_wrap .thumb_loading .inner_loading{display:table-cell;vertical-align:middle}
.itemtbl_wrap .thumb_loading .inner_loading .img_loading{margin-top:0}
.tbl_itemchoice{table-layout:fixed;border-collapse:collapse;width:100%}
.tbl_itemchoice .inner_cell{display:block;padding:0 19px}
.tbl_itemchoice th{height:45px;border-left:1px solid #ebebeb;font-weight:400;background-color:#f5f5f5;text-align:left}
.tbl_itemchoice td{height:45px;border-top:1px solid #ebebeb;border-left:1px solid #ebebeb}
.tbl_itemchoice tbody tr:first-child td{border-top:0 none}
.tbl_itemchoice tbody tr:hover{background-color:rgba(0,0,0,0.03)}
.tbl_itemchoice tbody tr.on{background-color:#f1f6fc}
.tbl_itemchoice th:first-child,.tbl_itemchoice td:first-child{border-left:0 none}
.tbl_itemchoice .tr_mosu{text-align:center}
.tbl_itemchoice .td_mosu{font-size:13px;color:#777;text-align:right}
.tbl_itemchoice .td_mosu .inner_cell{padding:0 11px 0 0}
.tbl_itemchoice .tr_pixelmosu,.tbl_itemchoice .td_pixelmosu{line-height:16px}
.tbl_itemchoice .sub_time{display:block;font-size:12px;color:#777}
.tbl_itemchoice .box_radioinp{display:block;height:45px;line-height:45px}
.tbl_itemchoice .box_radioinp .inner_inp{display:block}
.tbl_itemchoice .box_radioinp .lab_radio{overflow:hidden;color:#111;white-space:nowrap;text-overflow:ellipsis}
.tbl_itemchoice .box_radioinp .lab_radio .ico_sub{display:inline-block;width:7px;height:7px;margin:17px 10px 0 0;border-left:1px solid #b2b2b2;border-bottom:1px solid #b2b2b2;vertical-align:top}
.tbl_itemchoice .tr_inactive tr:hover{background:none}
.tbl_itemchoice .tr_inactive td{cursor:default;pointer-events:none}
.tbl_itemchoice .tr_inactive .box_radioinp{cursor:default;pointer-events:none}
.tbl_itemchoice .tr_inactive .box_radioinp .inp_radio{cursor:default;pointer-events:none}
.tbl_itemchoice .tr_inactive .box_radioinp .ico_radio{background-position:-755px -50px}
.tbl_itemchoice .tr_inactive .box_radioinp .lab_radio{color:rgba(17,17,17,0.4)}
.tbl_itemchoice .tr_inactive .td_mosu .inner_cell{opacity:0.4;filter:alpha(opacity=40)}
.tbl_itemchoice .tr_inactive .td_pixelmosu .inner_cell{opacity:0.4;filter:alpha(opacity=40)}
/* ip제한설정 */
.iplimit_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.iplimit_wrap .iplimit_tit{padding-bottom:14px;margin-bottom:15px;border-bottom:1px solid rgba(0,0,0,0.05)}
.iplimit_wrap .list_append{margin-bottom:30px}
.iplimit_wrap .list_append li{font-size:13px}
.iplimit_wrap .ad_managebox{margin-top:30px}
.iplimit_wrap .tbl_g2 .nodata_info{height:330px}
.iplimit_wrap .tbl_g2 .inner_tbl{position:relative}
.iplimit_wrap .tbl_g2 tr .link_g{display:none}
.iplimit_wrap .tbl_g2 tr:hover .link_g{display:block;position:absolute;top:8px;right:12px;width:33px;height:33px}
.iplimit_wrap .tbl_g2 tr .link_g .ico_comm{margin:10px auto}
/* 광고노출제한 레이어 */
.iplimit_layer .inner_basic_layer{width:630px}
.iplimit_layer .layer_body{padding:22px 30px 30px}
.iplimit_layer .box_textarea2 .lab_txt{top:5px;width:258px}/** 설정 **/
/* 광고계정관리 */
.settingmanage_wrap{width:100%;min-width:1180px;max-width:1320px;margin:0 auto}
.settingmanage_wrap .tab_g{margin:-6px 0 20px 0}
.settingmanage_wrap .ad_managebox .opt_select{min-width:145px}
.settingmanage_wrap .btn_cancel{width:90px}
.settingmanage_wrap .tbl_g2 .td_state{color:#777}
.settingmanage_wrap .tbl_g2 .desc_info{padding-left:26px;color:#777;line-height:23px}
.settingmanage_wrap .desc_append{margin-top:20px;line-height:22px;color:#777;font-size:13px}
.settingmanage_wrap .inp_modify{display:inline-block;width:260px;vertical-align:middle}
.settingmanage_wrap .thead_type2 .th_multi{border-left:1px solid #ebebeb}
.account_present{margin:-8px 0 0 -8px}
.account_present:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.account_present .thumb_refresh{display:table;width:100%;height:168px}
.account_present .thumb_refresh .inner_thumb{display:table-cell;height:100%;vertical-align:middle;text-align:center}
.account_present .thumb_refresh .txt_refresh{padding:45px 0 15px;color:#555;line-height:20px}
.account_present .ico_clear{display:inline-block;width:31px;height:16px;margin-left:11px;background-position:-790px -500px}
.account_present .card_summary{float:left;overflow:hidden;position:relative;width:calc(33.333% - 8px);height:296px;margin:8px 0 0 8px;padding:30px;border:1px solid #ddd;background-color:#fff;box-sizing:border-box}
.account_present .card_summary:nth-child(3n){margin-right:-2px}
.card_summary .tit_card{display:block;margin-bottom:10px;font-weight:500;font-size:12px;color:#333}
.card_summary .txt_sub:before{display:inline-block;width:1px;height:11px;margin:4px 5px 0 5px;background-color:#ccc;vertical-align:top;content:''}
.card_summary .txt_info{display:block;overflow:hidden;margin-bottom:33px;font-weight:700;font-size:25px;line-height:37px;color:#111;white-space:nowrap;text-overflow:ellipsis;letter-spacing:-0.06em}
.os_mac .card_summary .txt_info{letter-spacing:0}
.card_summary .ico_master{margin:4px 0 0 8px}
.card_summary .ico_cancel{margin:2px 0 0 10px}
.card_summary .btn_gs{position:absolute;top:15px;right:15px}
.card_summary .area_loading{margin-top:-28px}
.card_summary .area_refresh{margin-top:-28px}
.card_summary .desc_refresh{width:256px}
.card_summary.clear .txt_info,.txt_info.clear{color:#777;opacity:0.4;filter:alpha(opacity=40)}
.card_summary.clear .define_adinfo{opacity:0.4;filter:alpha(opacity=40)}
.card_summary.clear .define_adinfo dt,.card_summary.clear .define_adinfo dd{color:#555}
.account_personal .card_summary .desc_refresh{width:155px;word-break:keep-all}
.define_adinfo dl{width:100%;padding:7px 0 8px;border-top:1px solid rgba(0,0,0,0.05)}
.define_adinfo dl:first-child{border-top:0 none}
.define_adinfo dt{float:left;width:77px;font-weight:500}
.define_adinfo dd{overflow:hidden}
.define_adinfo .txt_limit{display:block;overflow:hidden;float:left;max-width:100%;text-overflow:ellipsis;white-space:nowrap}
.define_adinfo .limit_info{overflow:hidden}
.define_adinfo .limit_info .txt_limit{max-width:50%}
.card_member .define_adinfo dt{min-width:72px;width:auto;padding-right:10px;box-sizing:border-box}
.card_member .define_adinfo .data_adinfo{overflow:hidden;display:block;text-overflow:ellipsis;white-space:nowrap}
.card_member .define_adinfo .user_adinfo,.card_member .define_adinfo .ico_comm{float:right}
.card_cash .define_adinfo dt{width:60px}
.card_cash .define_adinfo dd{text-align:right}
.card_tax .define_adinfo dt{width:59px}
.card_agency .state_agency{overflow:hidden;padding-top:7px}
.card_agency .state_agency .desc_info{margin-bottom:10px;line-height:22px}
.card_agency .state_agency .desc_state{height:21px;padding:10px;background-color:#FFF2F2;border-radius:4px}
.card_agency .state_agency .ico_fail{margin:4px 8px 0 0}
.card_adtask dt{width:111px}
/* 개인계정일경우 */
.account_personal .card_summary{width:calc(25% - 8px);height:335px}
.account_personal .card_member dt{float:none;width:auto}
.account_personal .card_cash .txt_bank{display:block}
.account_personal .card_payment dt{float:none;width:auto}
/* 비활성화시 */
.account_present .in_active .txt_info{margin-right:-5px;color:#ccc}
.account_present .in_active dl{color:#ccc}
.account_present .in_active .txt_sub{color:#ccc}
/* 멤버관리 */
.check_member{position:relative;margin-top:7px}
.check_member .link_g{margin-top:2px}
.check_member .box_checkinp .lab_check{font-size:13px}
.settingmanage_wrap .managebox_member .opt_select{min-width:177px}
/* 세금계산서 */
.managebox_agency{height:42px;padding:39px 0 40px 20px;border-top:1px solid #ebebeb;background-color:#F5F5F5;line-height:19px;font-size:13px}
.managebox_agency .txt_agency{display:block;margin-bottom:-8px;font-weight:500;color:#333}
.managebox_agency .desc_agency{margin-top:13px}
.managebox_agency .desc_agency .ico_fail{margin:3px 10px 0 0}
/* 계정 불가사유 리스트 */
.list_cause{border:1px solid #ddd;border-radius:2px}
.list_cause li{position:relative;padding:20px 147px 20px 20px;border-top:1px solid rgba(0,0,0,0.05);line-height:22px}
.list_cause li:first-child{border-top:0 none}
.list_cause .tit_cause{display:block;font-weight:500;color:#111}
.list_cause .btn_gs{position:absolute;right:20px;top:50%;width:95px;margin-top:-19px}
.list_cause .btn_gs .inner_g{padding-left:0;padding-right:0}
/* 계정삭제-레이어 */
.accdel_layer .inner_basic_layer1{width:857px}
.accdel_layer .inner_basic_layer2{width:742px}
.accdel_layer .inner_basic_layer3{width:604px}
.accdel_layer .layer_body{padding:30px}
.accdel_layer .txt_accdel{margin:16px 0 -4px;font-size:14px;color:#555}
.accdel_layer .fld_accdel{margin:20px 0 -2px}
.accdel_layer .box_checkinp .lab_check{color:#333}
.accdel_layer .list_guide{margin:20px 0 -5px}
.accdel_layer .tbl_layer + .list_guide{padding:0;border:0 none}
.accdel_layer .btn_gs{height:28px}
/* 광고계정명 수정 */
.accountname_layer .inner_basic_layer{width:632px}
.accountname_layer .layer_body{padding:30px}
/* 마스터권한이전 */
.masterauth_layer .inner_basic_layer{width:632px}
.masterauth_layer .inner_basic_layer1{width:837px}
.masterauth_layer .inner_basic_layer2{width:880px}
.masterauth_layer .inner_basic_layer3{width:757px}
/* 마스터권한 이동 요청 레이어 */
.masterauth_layer .layer_body{padding:22px 30px 30px}
.masterauth_layer .agree_master{border:1px solid #e5e5e5}
.masterauth_layer .agree_master + .agree_master{margin-top:20px}
.masterauth_layer .agree_master .tit_agreemaster{display:block;height:56px;padding-left:30px;border-bottom:1px solid #e5e5e5;font-weight:400;line-height:53px;font-size:13px;background-color:#f5f5f5}
.masterauth_layer .agree_master .desc_agreemaster{display:inline-block;overflow:hidden;vertical-align:top;line-height:22px;padding:15px 30px 7px}
.masterauth_layer .agree_master .list_guide{padding-left:30px;border:0 none}
/* 회원 탈퇴 레이어 */
.withdrawal_layer .inner_basic_layer{width:820px}
.withdrawal_layer .layer_body{padding:30px}
.withdrawal_layer .subtit_layer{margin-top:0}
.withdrawal_layer .box_checkinp{margin-top:19px}
.withdrawal_layer .desc_layer{margin:-15px 0 19px}
.withdrawal_layer .tbl_layer .inner_cell{padding:20px 30px 20px 31px}
.withdrawal_layer .th_phone{padding-top:20px;vertical-align:top}
.withdrawal_layer .group_inp .txt_phone{float:left;width:110px;line-height:36px}
.withdrawal_layer .single_wrap{margin-left:15px}
.withdrawal_layer .group_inp.verifi_inp .box_inptxt{width:82px}
.withdrawal_layer .gm_line.in_active{border-color:rgba(184,156,156,0.03);background-color:#ccc;color:#fff}
/* 광고캐시관리 */
.multibox_top{margin-bottom:20px}
.multibox_top:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.multibox_top .managebox_cash{float:left;width:778px;margin-right:20px}
.multibox_top .managebox_cash .managebox_detail{display:block;width:auto;min-height:164px;padding:0 19px;border-top:0 none}
.multibox_top .managebox_detail{display:table;width:100%;min-height:163px;border-top:1px solid #ebebeb}
.multibox_top .managebox_detail .nodata_info{display:table-cell;line-height:20px;text-align:center;vertical-align:middle}
.multibox_top .managebox_refund{overflow:hidden}
.multibox_top .managebox_refund .define_adinfo{margin:14px 20px 29px 19px}
.multibox_top .managebox_refund .link_txt{color:#326edc}
.define_adinfo{border:0 none;border-collapse:collapse;table-layout:fixed}
.define_adinfo th{padding-right:17px;line-height:22px;font-weight:normal;color:#777;text-align:left;white-space:nowrap;vertical-align:top}
.define_adinfo td{color:#333;line-height:22px;font-weight:500;word-break:break-all;text-align:right}
.detail_helf .listcashreport_wrap{float:left;width:330px;margin-left:50px}
.detail_helf .listcashreport_wrap:first-child{width:360px;margin-left:0}
.list_cashreport{display:table;table-layout:fixed;width:100%;border-top:1px solid #ebebeb;border-bottom:1px solid #ebebeb;background-color:#fff}
.list_cashreport dt,.list_cashreport dd{display:table-cell;height:71px;padding-left:20px;vertical-align:middle}
.list_cashreport dt{width:108px;font-size:14px;color:#777;background-color:#F5F5F5}
.list_cashreport dd{font-size:14px;color:#333}
.list_cashreport .txt_balance{font-weight:bold;font-size:22px}
.list_cashreport + .list_cashreport,.list_cashreport + .set_listcashreport,.set_listcashreport + .list_cashreport{margin-top:-1px}
.set_listcashreport{position:relative;padding:7px 0 6px;border-top:1px solid #ebebeb;border-bottom:1px solid #ebebeb}
.set_listcashreport:before{position:absolute;left:0;top:0;bottom:0;width:128px;background-color:#F5F5F5;content:''}
.set_listcashreport .list_cashreport{position:relative;border:0 none}
.set_listcashreport .list_cashreport + .list_cashreport{margin-top:0}
.set_listcashreport .list_cashreport dt,.set_listcashreport .list_cashreport dd{height:29px}
.basic_layer .list_selinp li + li{padding-top:16px}
.basic_layer .item_cash .radio_inp{margin-right:7px}
.basic_layer .item_cash .box_inptxt{display:inline-block;width:132px;margin-top:-6px;vertical-align:top}
.basic_layer .item_cash .box_radioinp + .box_inptxt{margin-left:6px}
.basic_layer th.align_c{text-align:center}
.basic_layer .td_mail .inner_cell{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.basic_layer .tbl_itemchoice th{height:55px}
.basic_layer .tbl_itemchoice td{height:48px}
.basic_layer .tbl_itemchoice th + th .inner_cell{padding:0 12px}
.basic_layer .tbl_itemchoice td + td .inner_cell{padding:0 12px}
.basic_layer .tbl_itemchoice .btn_gs{height:28px;margin:14px 0}
.basic_layer .tbl_itemchoice .btn_gs .inner_g{padding:6px 9px 7px;line-height:14px}
/* .basic_layer .itemtbl_wrap{border-width:1px 0} */
.basic_layer .itemtbl_wrap .nodata_wrap{height:120px}
.basic_layer .itemtbl_wrap .thumb_loading{height:120px}
.basic_layer .itemtbl_wrap .thumb_loading .img_loading{vertical-align:middle}
.basic_layer .desc_guide{margin-top:20px}
/* 충전 레이어 */
.tbl_layer .inner_cell .cash_balance{display:block;padding:5px 0;font-weight:700;font-size:16px;line-height:24px;vertical-align:top}
.tbl_layer .inner_cell .cash_balance.in_active{color:#999}
.charge_layer .inner_basic_layer{width:632px}
.charge_layer .inner_basic_layer2{width:742px}
.charge_layer .layer_body{padding:30px}
.charge_layer .tbl_layer th{border-right:1px solid rgba(0,0,0,0.05)}
.charge_layer .tbl_layer .inner_cell{padding:19px 30px}
.charge_layer .inner_cell .cash_balance{padding:0}
.charge_layer .list_guide{margin-top:20px}
.charge_layer .list_append{margin-top:21px}
.charge_layer .list_append li:before{width:4px;height:4px;border-radius:100%;background-color:#555}
.charge_layer .list_selinp .txt_error{margin:11px 0 -3px 28px}
/* 환불 레이어 */
.refund_layer .inner_basic_layer{width:604px}
.refund_layer .inner_basic_layer2{width:460px}
.refund_layer .layer_body{padding:30px}
.refund_layer .layer_subtit{display:table;position:relative;width:100%;min-height:36px;height:auto;margin:0 0 20px}
.refund_layer .subtit_layer{overflow:hidden;margin-top:0}
.refund_layer .layer_subtit .subtit_layer{display:table-cell;float:none;padding-right:110px;line-height:24px;vertical-align:middle}
.refund_layer .subtit_layer:after{display:block;clear:both;content:''}
.refund_layer .subtit_layer .link_viewmore{float:right;font-size:14px;font-weight:400;line-height:24px;color:#326EDC;text-decoration:underline}
.refund_layer .layer_subtit .btn_gs{position:absolute;top:50%;right:0;margin-top:-18px;height:36px}
.refund_layer .layer_subtit .btn_gs .inner_g{padding:0 18px}
.refund_layer .tbl_layer + .subtit_layer{margin-top:30px}
.refund_layer .tbl_layer td{line-height:20px}
.refund_layer .tbl_layer .inner_cell{padding:20px 10px 20px 30px}
.refund_layer .td_refundcash .inner_cell{font-weight:500;font-size:16px}
.refund_layer .td_warn .inner_cell{color:#F55353}
.refund_layer .opt_select{width:223px}
.refund_layer .opt_select .opt_list{overflow:hidden;height:196px}
/* 2021-05-17 추가 */
.refund_layer .subtit_refund,.refund_layer .layer_refund{margin-bottom:0}
.refund_layer .list_append{margin:10px 0 20px}
/* //2021-05-17 추가 */
.refund_layer .box_inptxt{width:197px}
.refund_layer .view_agree{margin-top:19px}
.refund_layer .view_agree .box_agree{padding-top:10px}
.refund_layer .view_agree .tbl_layer{margin-top:8px}
.refund_layer .view_agree .tbl_layer td{color:#555}
.refund_layer .view_agree .desc_agree{margin-top:10px}
.box_norefund{position:relative;padding:18px 20px 15px 45px;border-radius:4px;background-color:#FFF2F2}
.refund_layer .box_norefund{margin-top:20px}
.box_norefund .ico_fail{position:absolute;top:16px;left:15px}
.box_norefund .tit_norefund{display:block;font-weight:500;font-size:14px;line-height:16px;color:#333}
.box_norefund .desc_norefund{display:block;padding-top:3px;font-size:14px;line-height:21px;color:#555}
/* 결제 상세 내역 레이어 */
.paydetail_layer .inner_basic_layer{width:850px}
.paydetail_layer .layer_body{padding:30px}
.paydetail_layer .subtit_layer{margin-top:0}
/* 후불한도 변경이력 레이어 */
.postpay_layer .inner_basic_layer{width:660px}
.postpay_layer .layer_body{padding:30px}
.postpay_layer .layer_subtit{margin:0 0 21px}
.postpay_layer .layer_subtit .subtit_layer{margin-top:6px}
.postpay_layer .layer_subtit .opt_select{width:160px}
.postpay_layer .td_postpay .ico_arr{display:inline-block;width:14px;height:8px;margin:7px 5px;vertical-align:top;background-position:-740px -550px}
/* 가상계좌 발급 이력 레이어 */
.virtual_layer .inner_basic_layer{width:850px}
.virtual_layer .layer_body{padding:30px}
.virtual_layer .subtit_layer{margin-top:0}
.virtual_layer .subtit_layer .emph_g{font-weight:500}
.virtual_layer .itemtbl_wrap .itemtbl_body{max-height:294px}
/* 결제 가능 내역 레이어 */
.payment_layer .inner_basic_layer{width:550px}
.payment_layer .layer_body{padding:30px}
.payment_layer .subtit_layer{margin-top:0}
.payment_layer .subtit_layer .date_repay{float:right;margin-top:3px;font-size:13px;color:#777}
.payment_layer .td_balance .inner_cell{display:block;padding:21px 30px;font-weight:500;font-size:16px;vertical-align:top}
.payment_layer .payment_wrap{padding-top:31px}
.payment_layer .tbl_layer{margin-top:11px}
.payment_layer .tbl_layer th{padding-left:20px}
.payment_layer .tbl_layer th .lab_radio{font-weight:500;font-size:14px;color:#555}
.payment_layer .tbl_layer .box_inptxt{width:171px}
/* 소재 - 등록그룹 레이어 */
.registgroup_layer .inner_basic_layer{width:550px}
.registgroup_layer .tbl_itemchoice .inner_cell{font-size:13px}
.registgroup_layer .layer_body{padding:30px 30px}
.registgroup_layer .addauthor_top{overflow:hidden}
.registgroup_layer .itemtbl_wrap .itemtbl_body{max-height:244px}
/* 소재연결 */
.connect_layer .inner_basic_layer{width:855px}
.connect_layer .layer_body{padding:20px 30px 32px}
.connect_layer .desc_info{font-size:13px;line-height:19px;color:#999}
.connect_layer .material_managebox{overflow:hidden;width:100%;margin-top:20px}
.material_managebox .ad_managebox{float:left;width:320px;height:600px;box-sizing:border-box}
.material_managebox .ad_managebox + .ad_managebox{width:460px;margin:0 0 0 15px}
.material_managebox .itemtbl_wrap{border:0}
.material_managebox .ad_managebox .itemtbl_wrap .itemtbl_head th{height:50px}
.material_managebox .ad_managebox .itemtbl_wrap .itemtbl_head{border-bottom:0}
.material_managebox .itemtbl_head th .inner_cell{position:relative;font-weight:400;font-size:12px;line-height:50px;color:#555}
.material_managebox .tbl_itemchoice th + th .inner_cell{padding:0 9px 0 13px}
.material_managebox .tbl_itemchoice td + td .inner_cell{padding:14px 9px 11px 13px}
.material_managebox .itemtbl_head th .inner_cell .link_g{margin:14px -4px 0 -4px}
.material_managebox .itemtbl_head th .inner_cell .fw_bold{font-size:13px;line-height:19px;color:#000}
.material_managebox .itemtbl_head th .inner_cell .fc_emph{margin-left:4px;font-weight:700}
.connect_layer .material_managebox .itemtbl_wrap .itemtbl_body{height:549px;max-height:549px}
.material_managebox .itemtbl_wrap .itemtbl_body td{border-top:0;border-bottom:1px solid #EBEBEB;font-size:13px;color:#333}
.itemset_list .sortable{display:block;position:relative;padding:0 10px 0 20px;font-weight:700;font-size:13px;line-height:50px;color:#000;background:#FAFAFA}
.btn_dash{position:absolute;top:0;right:5px}
.btn_dash .desc_sort{display:inline-block;position:relative;top:auto;left:auto;right:auto;height:50px;padding:10px;box-sizing:border-box;vertical-align:top}
.connect_layer .sortable .desc_sort .ico_sort{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);margin:0}
.itemset_list .list_itemset{overflow-x:hidden;overflow-y:auto;height:549px}
.itemset_list .list_itemset li{min-height:47px;padding:14px 0;border-bottom:1px solid #EBEBEB;box-sizing:border-box}
.itemset_list .list_itemset li.over,.itemset_list .list_itemset li:hover{background:rgba(0,0,0,0.03)}
.itemset_list .list_itemset li.on{background:#F1F6FC}
.itemset_list .list_itemset .link_itemset{display:block;display:-webkit-box;overflow:hidden;max-height:38px;padding:0 17px 0 19px;font-size:13px;line-height:19px;color:#333;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;white-space:normal;text-overflow:ellipsis;text-decoration:none}
.material_managebox .box_checkinp .lab_check{display:block;display:-webkit-box;overflow:hidden;max-height:38px;font-size:13px;line-height:19px;color:#333;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;white-space:normal}
.material_managebox .tbl_itemchoice td .inner_cell{padding:14px 11px 11px 19px}
.os_mac .material_managebox .sortable .desc_sort{margin-top:0}
.os_mac .material_managebox .sortable .desc_sort .ico_sort{margin:0}
.connect_layer .itemtbl_wrap .itemtbl_body{overflow-y:auto;overflow-x:hidden;max-height:374px}
.connect_layer .itemtbl_wrap .itemtbl_body td{font-size:13px;line-height:19px;color:#333}
.connect_layer .itemtbl_wrap .itemtbl_body td.nodata_info{height:374px;font-size:13px;line-height:19px;color:#333;background:#fff;text-align:center;vertical-align:middle}
.itemset_list .nodata_info{display:table-cell;width:320px;height:549px;font-size:13px;line-height:19px;color:#333;text-align:center;vertical-align:middle}
.material_managebox .itemtbl_wrap .itemtbl_body td.nodata_info{height:548px;font-size:13px;line-height:19px;color:#333;background:#fff;text-align:center;vertical-align:middle}
.basic_layer .subtit_layer2{display:block;margin:12px 0 10px;font-weight:500;font-size:14px;line-height:20px;color:#333}
.basic_layer .subtit_layer2 .fc_warn2{margin-left:4px}
.basic_layer .itemtbl_wrap + .subtit_layer2{margin-top:20px}
.connect_layer .itemtbl_wrap .itemtbl_head th{height:55px;font-size:13px;color:#555;font-weight:500}
.connect_layer .itemtbl_wrap .fc_warn2{font-weight:700;font-size:15px}
.connect_layer .itemtbl_wrap .fw_bold{font-size:15px}
.connect_layer .tbl_itemchoice td{height:56px}
.connect_layer .tbl_itemchoice .inner_cell{padding:0 19px 0 16px}
/* 변경이력관리 */
.settinghistory_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.settinghistory_wrap .managebox_tit .box_radioinp{height:36px;line-height:36px;margin-right:40px}
.settinghistory_wrap .opt_select{width:133px}
.settinghistory_wrap .managebox_tit .single_wrap:first-child .opt_select .opt_list{min-width:290px;max-width:290px}
.settinghistory_wrap .managebox_tit .single_wrap:first-child .opt_select .opt_list .link_option{white-space:nowrap;text-overflow:ellipsis}
.settinghistory_wrap .opt_select .opt_list{min-width:354px;max-width:354px}
.settinghistory_wrap .opt_select .opt_list .link_option{white-space:normal;text-overflow:inherit}
.settinghistory_wrap .list_opt{overflow-x:hidden;max-height:296px}
.settinghistory_wrap .list_opt .link_option{height:auto;padding:8px 12px 11px 15px;line-height:normal}
/* .settinghistory_wrap .list_opt .limit_line{width:150px} */
.settinghistory_wrap .txt_addr{display:block;overflow:hidden;margin-top:3px;font-size:12px;color:#9f9f9f;white-space:normal;text-overflow:inherit}
.settinghistory_wrap .txt_addr + .txt_addr{margin-top:0}
.settinghistory_wrap .opt_filter{width:138px}
.settinghistory_wrap .opt_filter .list_opt{width:auto;max-height:100%}
.settinghistory_wrap .opt_filter .opt_list{width:197px}
.opt_filter .on > .box_checkinp .lab_check,.opt_filter .box_checkinp .inp_check:checked + .lab_check{color:#333}
.settinghistory_wrap .opt_filter .wrap_btn{padding:16px 0;border-top:1px solid #ddd;text-align:center}
.settinghistory_wrap .search_box{float:left;font-size:0}
.settinghistory_wrap .search_box .opt_select{border-radius:2px 0 0 2px}
.settinghistory_wrap .search_box .list_opt{width:auto;max-height:100%}
.settinghistory_wrap .search_box .search_inp2{display:inline-block;width:212px;margin-left:-1px;border-radius:0 2px 2px 0}
.search_box .opt_select:hover:after,.search_box .opt_open:after{position:absolute;top:-1px;right:-1px;width:1px;height:36px;background-color:#333;z-index:1;content:''}
.settinghistory_wrap .tbl_g2 .nodata_info{height:330px;font-size:14px;color:#333;text-align:center;vertical-align:middle}
.settinghistory_wrap .search_inp2{width:132px}
/* 심사서류관리 - 셀렉트박스 */
.customerfile_wrap .group_select .opt_select:first-child .list_opt{overflow-x:hidden;max-height:296px}
.customerfile_wrap .list_opt .link_option{height:auto;padding:8px 12px 11px 15px;line-height:normal}
.customerfile_wrap .txt_addr{display:block;overflow:hidden;margin-top:3px;font-size:12px;color:#9f9f9f;white-space:normal;text-overflow:inherit}
.customerfile_wrap .item_tit{overflow:hidden;margin-bottom:10px}
.customerfile_wrap .tit_detail{display:block;font-weight:400}
/* 변경이력관리 - 상세보기 레이어 */
.changehistory_layer .inner_basic_layer{width:604px}
.changehistory_layer .inner_basic_layer2{width:850px}
.changehistory_layer .layer_body{padding:30px}
.changehistory_layer .tbl_layer_cont th{border:1px solid;border-color:rgba(0,0,0,0.05);border-width:1px 1px 0px 0px}
.changehistory_layer tbody th,.changehistory_layer .tbl_layer_cont tr + tr th{border-right:1px solid rgba(0,0,0,0.08)}
.changehistory_layer .tbl_layer_cont tr:first-child th{border-bottom:1px solid rgba(0,0,0,0.08)}
.changehistory_layer .tbl_layer_cont td{border:1px solid;border-color:rgba(0,0,0,0.05);border-width:1px 0px 0px 1px}
.changehistory_layer .tbl_layer_cont .th_sch{vertical-align:top}
.changehistory_layer .tbl_layer_cont td .sch_wrap{width:100%;margin-top:5px;margin-bottom:5px}
.changehistory_layer .tbl_layer_cont td .tbl_sch th,.changehistory_layer .tbl_layer_cont td .tbl_sch td{padding:0;border-color:#ddd;text-align:center}
.changehistory_layer .tbl_layer_cont td .tbl_sch tr th:last-child,.changehistory_layer .tbl_layer_cont td .tbl_sch tr td:last-child{border-right:none}
.changehistory_layer .tbl_layer_cont .btn_gs{height:28px}
.changehistory_layer .tbl_layer_cont .btn_gs .inner_g{padding:5px 9px 6px;box-sizing:border-box}
.changehistory_layer .inner_cell{overflow-y:auto;overflow-x:hidden;max-height:60px;line-height:20px;color:#555}
.changehistory_layer .inner_cell .txt_url{display:block}
.changehistory_layer .inner_cell .txt_url .fw_bold{display:inline-block;margin-right:6px;vertical-align:top}
.changehistory_layer .inner_cell .txt_url .link_txt{color:#555}
/* 2020-12-29 시작 :대량관리 */
.ico_standby{display:inline-block;overflow:hidden;width:10px;height:10px;border-radius:20px;background-color:#00BEE1;font-size:0;line-height:0;text-indent:-9999px;vertical-align:top}
.ad_state .ico_standby{margin-right:6px}
.filemanage_wrap{width:100%;min-width:1180px;max-width:1320px;margin:0 auto}
.filemanage_wrap .list_append{margin-top:21px;font-size:13px}
.filemanage_wrap .ad_managebox{margin-top:21px}
.filemanage_wrap .tbl_g2 .wrap_btn{margin-bottom:-2px}
.filemanage_wrap .tbl_g2 .btn_gs{height:24px}
.filemanage_wrap .tbl_g2 button.btn_gs{height:26px}
.filemanage_wrap .tbl_g2 .btn_gs .inner_g{line-height:11px}
.filemanage_wrap .tbl_g2 .nodata_info{height:330px}
.filemanage_wrap .ad_state{letter-spacing:-0.08em}
.filemanage_wrap .ad_state .ico_on,.filemanage_wrap .ad_state .ico_off{top:50%;margin-top:-6px}
.filemanage_wrap .ad_state .ico_standby{position:absolute;top:50%;left:0;margin-top:-6px}
.filemanage_wrap .ad_state .btn_gs{margin-left:4px;vertical-align:middle}
/* 2020-12-29 종료 :대량관리 */
/* 2021-01-08 추가 :로그인페이지_최적 브라우저 안내 얼럿창 */
.layer_alert{display:table;position:absolute;top:0;left:0;z-index:10000;width:100%;height:50px;line-height:28px;color:#fff;background-color:#f04a4e;text-align:center}
.layer_alert .txt_alert{display:table-cell;vertical-align:middle}
.layer_alert .link_browser{position:relative;color:#fff;text-decoration:none}
.layer_alert .link_browser:after{content:'';position:absolute;bottom:0;left:0;width:100%;height:1px;background-color:#fbd2d3}
.layer_alert .link_close{position:absolute;top:0;right:0;padding:20px}
.layer_alert .ico_close{width:10px;height:10px;background-position:-200px -100px}
/* 이미지 관리 리스트 */
.imgmanage_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.imgmanage_wrap .managebox_tit .opt_select{min-width:142px}
.imgmanage_wrap .box_thumb{position:relative;width:70px;height:70px;margin:0 auto}
.imgmanage_wrap .link_thumb{display:table;position:relative;width:70px;height:70px;margin:0 auto}
.imgmanage_wrap .link_thumb:after{content:'';position:absolute;top:0;left:0;width:70px;height:70px;border:1px solid rgba(0,0,0,0.04);box-sizing:border-box}
.imgmanage_wrap .box_thumb .inner_thumb{display:table-cell;height:100%;text-align:center;vertical-align:middle}
.imgmanage_wrap .box_thumb .inner_thumb{width:100%;height:100%;background-size:contain;background-position:center center;background-repeat:no-repeat}
.imgmanage_wrap .preview_thumb{display:none;position:absolute;top:0;left:85px;width:300px;padding:11px 16px 16px;background-color:#fff;-webkit-box-shadow:0px 2px 10px rgba(0,0,0,0.25);box-shadow:0px 2px 10px rgba(0,0,0,0.25);z-index:10;border-radius:4px;box-sizing:border-box}
.imgmanage_wrap .preview_thumb .tit_preview{display:block;margin-bottom:20px;line-height:20px;font-size:12px}
.imgmanage_wrap .preview_thumb .detail_thumb{display:table;width:268px;text-align:center}
.imgmanage_wrap .preview_thumb .img_thumb{max-width:268px;max-height:201px}
.imgmanage_wrap .link_thumb:hover + .preview_thumb{display:block}
.imgmanage_wrap .tbl_g2 .nodata_info{height:330px}
/* 홍보이미지 업로드 실패 레이어 */
.box_upload{display:block}
.basic_layer2 .list_upload{overflow-x:hidden;max-height:470px;padding:20px;margin-top:30px;background-color:#F5F5F5;box-sizing:border-box}
.basic_layer2 .list_upload li{text-align:left}
.basic_layer2 .list_upload li + li{margin-top:20px}
.basic_layer2 .list_upload li .tit_upload{display:block;line-height:24px;color:#F64A48}
.basic_layer2 .list_upload li .desc_upload{line-height:24px}
.basic_layer2 .guide_item{margin:15px 0 -11px 0;text-align:left}
.basic_layer2 .guide_item .link_guide{display:inline-block;line-height:22px;font-size:13px;color:#777;text-decoration:underline}
/* 공지사항 */
.notice_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.notice_wrap .tab_g{margin-bottom:20px}
.notice_wrap .tab_g .link_tab{width:auto;padding:0 30px}
.notice_wrap .search_box .opt_select{float:left;min-width:167px;border-radius:2px 0 0 2px}
.notice_wrap .search_box .box_inptxt{float:left;width:212px;border-radius:0 2px 2px 0;border-left:none}
.notice_wrap .search_box .box_inptxt:hover .inner_inp:before,.notice_wrap .search_box .box_inptxt.over .inner_inp:before{position:absolute;top:-1px;bottom:-1px;left:-1px;width:1px;background:#555;content:''}
.notice_wrap .search_box .box_inptxt.on .inner_inp:before{position:absolute;top:-1px;bottom:-1px;left:-1px;width:1px;background:#326edc;content:''}
.notice_wrap .search_box .search_inp2 .btn_search{width:34px}
.notice_wrap .tbl_g2 .link_txt{display:inline-block;overflow:hidden;max-width:100%;white-space:nowrap;text-overflow:ellipsis;vertical-align:top;color:#333}
.notice_wrap .tbl_g2 .new_adname{display:block;padding-left:47px}
.notice_wrap .tbl_g2 .new_adname .link_txt{margin-left:-47px}
.notice_wrap .tbl_g2 .nodata_info{height:330px}
.notice_wrap .ico_new{display:inline-block;margin:4px 0 0 2px;width:35px;height:16px;background-position:-790px -165px}
/* 공지사항 - 상세 */
.noticedetail_wrap{width:1180px;margin:0 auto}
.noticedetail_wrap .managebox_tit{position:relative;height:auto;padding:22px 184px 22px 29px}
.noticedetail_wrap .managebox_tit .tit_box{height:auto;line-height:29px;font-size:20px}
.noticedetail_wrap .managebox_tit .tit_cate{position:absolute;top:29px;right:11px}
.noticedetail_wrap .managebox_tit .txt_info{float:left;position:relative;padding:0 10px;line-height:20px;color:#777}
.noticedetail_wrap .managebox_tit .txt_info:before{content:'';position:absolute;top:3px;left:0;width:1px;height:14px;background-color:#777}
.noticedetail_wrap .managebox_tit .txt_info:first-child:before{content:none}
.noticedetail_wrap .notice_view{padding:30px;border-top:1px solid #EBEBEB}/** 2021-06-10 수정 */
.noticedetail_wrap .page_btn .btn_gm{height:36px;font-size:12px}
.noticedetail_wrap .nodata_info{margin:240px auto;line-height:20px;color:#333;text-align:center}
.noticedetail_wrap .section{position:relative}
.noticedetail_wrap .link_guide{display:block;position:absolute;top:270px;right:325px;width:120px;height:30px}
.noticedetail_wrap .link_more{display:block;position:absolute;bottom:98px;right:234px;width:432px;height:70px}
/* 개인정보설정 */
.box_bizcombine{padding:100px 0 100px}
.box_bizcombine .tit_bizcombine{display:block;font-size:14px;line-height:16px;color:#111;text-align:center}
.box_bizcombine .list_bizcombine{width:636px;margin:32px auto 50px;padding:19px 0 19px 29px;border:1px solid #ddd;border-radius:2px;box-sizing:border-box}
.box_bizcombine .list_bizcombine li{position:relative;padding-left:7px;font-size:13px;line-height:22px;color:#555}
.box_bizcombine .list_bizcombine li:before{position:absolute;left:0;top:11px;width:3px;height:3px;border-radius:100%;background-color:#6B6B6B;content:''}
.box_bizcombine .list_bizcombine li + li{margin-top:22px}
.box_bizcombine .page_btn{margin-top:22px;text-align:center}
.box_bizcombine .tit_bizcombine + .page_btn{margin-top:52px}
.box_bizcombine .page_btn .btn_gb{height:42px}
/* 레이어 > 내정보 > 개인정보설정 */
.withdrawal_layer .box_kakaobiz{padding:70px 0 40px;text-align:center}
.withdrawal_layer .box_kakaobiz .desc_kakaobiz{font-weight:500;font-size:16px;line-height:24px;color:#0F0F0F}
.withdrawal_layer .box_kakaobiz .btn_gb{margin-top:30px}
/* 자동결제관리 */
.myinfo_cardinfo{min-height:135px;padding:0 19px 20px}
.myinfo_cardinfo:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.myinfo_cardinfo .txt_nocard{margin-top:56px;line-height:22px;color:#777;text-align:center}
.myinfo_cardinfo.nodata_info{height:352px;padding:0;text-align:center}
.myinfo_cardinfo.nodata_info .txt_nodata{padding-top:100px;line-height:22px;color:#333}
.myinfo_cardinfo.nodata_info .btn_gm{margin-top:50px}
.myinfo_cardinfo .loading_wrap{display:table;width:100%;height:115px;padding-top:20px}
.myinfo_cardinfo .loading_wrap .inner_loading{display:table-cell;vertical-align:middle}
.managebox_tit + .myinfo_cardinfo{border-top:1px solid #ddd}
.managebox_tit + .myinfo_cardinfo.nodata_info{height:178px}
.managebox_tit + .myinfo_cardinfo.nodata_info .txt_nodata{padding-top:66px}/** 2021-05-10 수정 */
.cardinfo_layer .inner_basic_layer{width:875px}
.cardinfo_layer .layer_body{padding:22px 30px 30px}
.cardinfo_layer .agree_master{border:1px solid #e5e5e5}
.cardinfo_layer .agree_master + .agree_master{margin-top:20px}
.cardinfo_layer .agree_master .tit_agreemaster{display:block;height:56px;padding-left:30px;border-bottom:1px solid #e5e5e5;font-weight:400;line-height:57px;font-size:14px;background-color:#f5f5f5}
.cardinfo_layer .agree_master .desc_agreemaster{display:inline-block;overflow:hidden;vertical-align:top;line-height:22px;padding:15px 22px 7px 22px}
.cardinfo_layer .agree_master .list_guide{padding:15px 22px 17px 22px;border:0 none}
/* 카드관리 */
.accountpayment_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.accountpayment_wrap .dsp_tit + .list_append{margin:-4px;padding-top:15px;border-top:1px solid rgba(0,0,0,0.05)}
.info_account + .ad_managebox{margin-top:20px}
.info_account{position:relative;padding-left:229px;margin-top:28px;border:1px solid #ddd;background-color:#fff}
.info_account .account_paymentinfo{position:absolute;left:0;top:0;bottom:0;width:188px;padding:0 20px;border-right:1px solid #ebebeb}
.info_account .account_cardinfo{overflow:hidden;padding:0 20px 20px}
.info_account .account_cardinfo .nodata_info{padding:73px 0;font-size:14px;line-height:22px;color:#777;text-align:center}
.info_account.nodata_info{padding-left:0}
.info_account .tooltip_budget .ico_arr{left:11px;margin-left:0}
.accountpayment_wrap .tooltip_budget .fld_budget{width:208px}
.account_paymentinfo .tit_account{display:block;margin-top:18px;padding-bottom:18px;border-bottom:1px solid #e5e5e5;line-height:24px;font-size:16px;color:#000}
.account_paymentinfo .tit_cardpayment{display:block;margin-top:20px;line-height:22px;color:#777}
.account_paymentinfo .txt_cardpayment{display:block;margin-top:6px;line-height:22px;font-weight:500;font-size:22px;color:#333;letter-spacing:-1px}
.account_paymentinfo .define_adinfo{margin-top:50px}
.account_paymentinfo .define_adinfo td{width:94px}
.accountpayment_wrap .define_adinfo a.fc_emph:hover{text-decoration:none}
.accountpayment_wrap .define_adinfo span.fc_emph{color:#ccc}
.account_paymentinfo .txt_error{display:block;margin-top:10px;line-height:22px;font-size:12px}
.account_paymentinfo .txt_error .link_help{margin-top:-1px}
.account_cardinfo:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.account_cardinfo .tit_account{display:block;margin:18px 0 20px;padding-bottom:18px;border-bottom:1px solid #e5e5e5;line-height:24px;font-size:16px;color:#000}
.account_cardinfo .tit_account + .card_pay{margin-left:0}
.account_cardinfo .tit_account + .add_card{margin-left:0}
.account_cardinfo .tit_account .link_help{margin:4px 0 0 4px}
.account_paymentinfo .txt_error + .define_adinfo{margin-top:18px}
.accountpayment_wrap .opt_state{width:152px}
.account_cardinfo .btn_gm{float:right;margin-top:-6px}
/* 결제카드_추가 */
.add_card{float:left;position:relative;width:291px;min-height:190px;box-sizing:border-box;margin-left:16px;border:1px solid #ddd;border-radius:10px;background-color:#eee}
.add_card .card_wrap{display:table;width:100%;height:100%;min-height:187px}
.add_card .card_wrap .inner_card{display:table-cell;padding-top:5px;vertical-align:middle;text-align:center}
.add_card .link_add{position:absolute;left:0;top:0;bottom:0;right:0}
.add_card .ico_plus{display:block;width:34px;height:34px;margin:0 auto 16px;background-position:-190px -160px}
.add_card .txt_add{font-size:16px;font-weight:500;color:#326edc;letter-spacing:-1px}
.add_card .link_g{position:relative;z-index:1;margin:4px 0 0 3px}
/* 결제카드_추가 레이어 */
.cardregist_layer .inner_basic_layer{width:600px}
.cardregist_layer .wrap_tbl .inner_wrap{padding:30px}
.cardregist_layer .tbl_layer .list_cash li{line-height:30px}
.cardregist_layer .tbl_layer .box_radioinp{height:30px;line-height:30px}
.cardregist_layer .tbl_layer .txt_append{margin:10px 0 -3px;font-size:12px}
.cardregist_layer .tbl_layer .box_cash{width:157px;padding-right:26px}
.cardregist_layer .box_inptxt .inp_txt{text-align:right}
.cardregist_layer .tbl_layer .box_cash .txt_won{position:absolute;right:8px;top:0;line-height:32px;color:#777}
.cardregist_layer .wrap_tbl .list_notice{margin:10px 0 -4px}
.cardregist_layer .wrap_tbl .list_notice li{position:relative;padding:0 0 0 10px;line-height:22px}
.cardregist_layer .wrap_tbl .list_notice li:before{position:absolute;left:0;top:10px;width:2px;height:2px;background-color:#555;content:''}
.cardregist_layer .wrap_tbl .list_default li{padding:0}
.cardregist_layer .wrap_tbl .list_default li:before{content:none}
.box_vertical{display:table;width:100%;min-height:203px}
.box_vertical .inner_box{display:table-cell;vertical-align:middle}
.box_vertical .tit_center{display:block;font-size:18px;line-height:28px;color:#111;text-align:center}
.box_vertical .txt_center{margin-top:20px;line-height:24px;text-align:center}
/* 결제카드 */
.card_pay{float:left;position:relative;width:291px;height:auto;padding:0;margin-left:16px;border-radius:10px;background-color:#e5e5e5}
.card_pay .inner_card{min-height:190px;padding:0 24px 26px;border:1px solid rgba(0,0,0,0.05);box-sizing:border-box;border-radius:10px}
.card_pay .tit_card{display:block;padding-top:24px}
.card_pay .tit_card .use_card{display:inline-block;height:22px;padding:0 10px;border-radius:22px;font-size:11px;line-height:21px;color:#fff;background-color:rgba(0,0,0,0.4);vertical-align:top}
.card_pay .tit_card .link_g{margin:-1px 0 0 2px;vertical-align:top}
.card_pay .txt_info{display:block;overflow:hidden;margin:28px 0 3px;font-weight:500;font-size:22px;line-height:28px;color:#fff;letter-spacing:-1px;white-space:nowrap;text-overflow:ellipsis}
.card_pay .card_logo{display:block;height:24px;padding:30px 0 5px}
.card_pay .card_logo .ico_cardlogo{display:inline-block;vertical-align:top}
.card_pay .enter_card{overflow:hidden;position:absolute;right:22px;top:23px}
.card_pay .link_enter{float:left;padding:4px;font-size:13px;text-decoration:underline;color:rgba(255,255,255,0.7);vertical-align:top}
.card_pay .btn_del{position:absolute;right:0px;top:0px;height:10px;padding:12px}
.card_pay .btn_del .ico_del{width:10px;height:10px;opacity:0.5;filter:alpha(opacity=50);background-position:-180px -500px}
.card_pay .btn_del:hover .ico_del{opacity:1;filter:alpha(opacity=100)}
.card_pay .detail_cardnum{font-size:15px;color:#fff}
.card_pay .detail_cardnum dt{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.card_pay .detail_carduser{overflow:hidden;margin-top:15px;font-size:13px;color:rgba(255,255,255,0.7)}
.card_pay .detail_carduser dt{float:left;margin-right:10px}
.card_pay .detail_carduser dd{overflow:hidden}
.card_pay .ico_cardoff{display:inline-block;width:40px;height:22px;margin:1px 0 0 12px;font-size:11px;line-height:21px;border-radius:22px;background-color:#fc5144;color:#fff;text-align:center;vertical-align:top;letter-spacing:-0.12em}
.card_pay .txt_info .ico_cardoff{margin-top:3px}
.card_pay .link_g{margin:4px}
/* 카드로고이미지 */
.ico_cardlogo{display:block;overflow:hidden;font-size:0;line-height:0;background:url("../images/ico_cardlogo.png") no-repeat;text-indent:-9999px;vertical-align:top}
.card_pay_s .ico_cardlogo{display:block;overflow:hidden;font-size:0;line-height:0;background:url("../images/ico_cardlogo_s.png") no-repeat;text-indent:-9999px;vertical-align:top}
/* 카드로고 */
.card_pay.card_basic,.card_pay_s.card_basic{background-color:#326EDC}/** 기본,우리 */
.card_pay.card_kb,.card_pay_s.card_kb{background-color:#7e7267}/** 국민 */
.card_pay.card_nh,.card_pay_s.card_nh{background-color:#2e71b8}/** 농협 */
.card_pay.card_shinhan,.card_pay_s.card_shinhan{background-color:#3460c7}/** 신한 */
.card_pay.card_hana,.card_pay_s.card_hana{background-color:#018f7f}/** 하나 */
.card_pay.card_bc,.card_pay_s.card_bc{background-color:#ed4c53}/** 비씨 */
.card_pay.card_lotte,.card_pay_s.card_lotte{background-color:#de2a3a}/** 롯데 */
.card_pay.card_samsung,.card_pay_s.card_samsung{background-color:#0072bc}/** 삼성 */
.card_pay.card_citi,.card_pay_s.card_citi{background-color:#144f90}/** 시티 */
.card_pay.card_hyundai,.card_pay_s.card_hyundai{background-color:#797979}/** 현대 */
.card_pay.card_kakaobank,.card_pay_s.card_kakaobank{background-color:#474747}/** 카카오뱅크 */
.card_pay.card_kakaopay,.card_pay_s.card_kakaopay{background-color:#ff9711}/** 카카오페이 */
.card_pay.card_paymoney,.card_pay_s.card_paymoney{background-color:#fce800}/** 페이머니 */
.card_pay.card_kb .ico_cardlogo{width:134px;height:22px;margin-top:1px;background-position:0 0}/** 국민 */
.card_pay.card_nh .ico_cardlogo{width:130px;height:18px;margin-top:4px;background-position:0 -30px}/** 농협 */
.card_pay.card_shinhan .ico_cardlogo{width:117px;height:20px;margin-top:1px;background-position:0 -50px}/** 신한 */
.card_pay.card_hana .ico_cardlogo{width:141px;height:24px;background-position:0 -80px}/** 하나 */
.card_pay.card_bc .ico_cardlogo{width:100px;height:24px;background-position:0 -110px}/** 비씨 */
.card_pay.card_lotte .ico_cardlogo{width:135px;height:24px;background-position:0 -140px}/** 롯데 */
.card_pay.card_samsung .ico_cardlogo{width:140px;height:14px;margin-top:5px;background-position:0 -170px}/** 삼성 */
.card_pay.card_citi .ico_cardlogo{width:36px;height:24px;background-position:0 -190px}/** 시티 */
.card_pay.card_hyundai .ico_cardlogo{width:104px;height:21px;margin-top:2px;background-position:0 -220px}/** 현대 */
.card_pay.card_kakaobank .ico_cardlogo{width:110px;height:19px;margin-top:2px;background-position:0 -250px}/** 카카오뱅크 */
.card_pay.card_kakaopay .ico_cardlogo{width:120px;height:18px;margin-top:3px;background-position:0 -280px}/** 카카오페이 */
.card_pay.card_paymoney .ico_cardlogo{width:92px;height:19px;margin-top:3px;background-position:0 -310px}/** 페이머니 */
.card_pay.card_paymoney .detail_cardnum{color:#111}
.card_pay.card_paymoney .detail_carduser{color:rgba(17,17,17,0.7)}
.card_pay.card_paymoney .link_enter{color:rgba(17,17,17,0.7)}
.card_pay.card_paymoney .btn_del .ico_del{background-position:-180px -480px}
.card_pay.on_card .tit_card .use_card{color:#fce800}
.card_pay.off_card{background-color:#e5e5e5}
.card_pay.off_card .inner_card{border:1px solid rgba(0,0,0,0.1)}
.card_pay.off_card .txt_info{color:rgba(17,17,17,0.3)}
.card_pay.off_card .detail_cardnum{color:rgba(17,17,17,0.3)}
.card_pay.off_card .detail_carduser dt{color:#555}
.card_pay.off_card .detail_carduser dd{color:#111}
.card_pay.off_card .link_enter{color:#777}
.card_pay.off_card .btn_del .ico_del{background-position:-180px -480px}
.card_pay.card_kb.off_card .ico_cardlogo{background-position:-160px 0}/** 국민 */
.card_pay.card_nh.off_card .ico_cardlogo{background-position:-160px -30px}/** 농협 */
.card_pay.card_shinhan.off_card .ico_cardlogo{background-position:-160px -50px}/** 신한 */
.card_pay.card_hana.off_card .ico_cardlogo{background-position:-160px -80px}/** 하나 */
.card_pay.card_bc.off_card .ico_cardlogo{background-position:-160px -110px}/** 비씨 */
.card_pay.card_lotte.off_card .ico_cardlogo{background-position:-160px -140px}/** 롯데 */
.card_pay.card_samsung.off_card .ico_cardlogo{background-position:-160px -170px}/** 삼성 */
.card_pay.card_citi.off_card .ico_cardlogo{background-position:-160px -190px}/** 시티 */
.card_pay.card_hyundai.off_card .ico_cardlogo{background-position:-160px -220px}/** 현대 */
.card_pay.card_kakaobank.off_card .ico_cardlogo{background-position:-160px -250px}/** 카카오뱅크 */
.card_pay.card_kakaopay.off_card .ico_cardlogo{background-position:-160px -280px}/** 카카오페이 */
.card_pay.card_paymoney.off_card .ico_cardlogo{background-position:-160px -310px}/** 페이머니 */
/* 결제카드_작은사이즈 */
.card_pay_s{float:left;position:relative;width:208px;margin:20px 10px 0;border-radius:8px;background-color:#e5e5e5}
.card_pay_s .inner_card{height:117px;box-sizing:border-box;padding:15px 15px 15px 15px;border:1px solid rgba(0,0,0,0.05);border-radius:8px}
.card_pay_s .use_card{display:inline-block;height:20px;padding:0 7px;border-radius:20px;font-size:11px;line-height:19px;color:#fff;background-color:rgba(0,0,0,0.4);vertical-align:top}
.card_pay_s .tit_card{display:block;margin:15px 0 2px;font-weight:500;font-size:15px;color:#fff;letter-spacing:-1px}
.card_pay_s .card_logo{display:block;height:20px;padding-top:26px;white-space:nowrap}
.card_pay_s .card_logo .ico_cardlogo{display:inline-block;vertical-align:top}
.card_pay_s .detail_cardnum{font-size:13px;color:#fff}
.card_pay_s .detail_cardnum dt{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.card_pay_s .enter_card{position:absolute;right:15px;top:15px}
.card_pay_s .link_enter{float:left;font-size:13px;text-decoration:underline;color:rgba(255,255,255,0.7)}
.card_pay_s .enter_card .layer_choice2{top:23px}
.card_pay_s .enter_card .layer_choice2 .txt_menu{width:285px}
.card_pay_s .enter_card .layer_choice2 .ico_mobile{float:left;margin-top:17px}
.card_pay_s .ico_cardoff{display:inline-block;width:36px;height:20px;margin:-2px 0 0 8px;font-size:11px;line-height:19px;border-radius:20px;background-color:#fc5144;color:#fff;text-align:center;vertical-align:top;letter-spacing:-0.12em}
.card_pay_s .tit_card .ico_cardoff{margin-top:1px}
.card_pay_s.card_kb .ico_cardlogo{width:98px;height:16px;margin-top:1px;background-position:0 0}/** 국민 */
.card_pay_s.card_nh .ico_cardlogo{width:93px;height:13px;margin-top:2px;background-position:0 -20px}/** 농협 */
.card_pay_s.card_shinhan .ico_cardlogo{width:89px;height:15px;margin-top:1px;background-position:0 -40px}/** 신한 */
.card_pay_s.card_hana .ico_cardlogo{width:106px;height:18px;background-position:0 -60px}/** 하나 */
.card_pay_s.card_bc .ico_cardlogo{width:77px;height:18px;background-position:0 -80px}/** 비씨 */
.card_pay_s.card_lotte .ico_cardlogo{width:103px;height:18px;background-position:0 -100px}/** 롯데 */
.card_pay_s.card_samsung .ico_cardlogo{width:105px;height:10px;margin-top:4px;background-position:0 -120px}/** 삼성 */
.card_pay_s.card_citi .ico_cardlogo{width:28px;height:18px;background-position:0 -140px}/** 시티 */
.card_pay_s.card_hyundai .ico_cardlogo{width:86px;height:18px;background-position:0 -160px}/** 현대 */
.card_pay_s.card_kakaobank .ico_cardlogo{width:78px;height:14px;margin-top:2px;background-position:0 -180px}/** 카카오뱅크 */
.card_pay_s.card_kakaopay .ico_cardlogo{width:93px;height:14px;margin-top:2px;background-position:0 -200px}/** 카카오페이 */
.card_pay_s.card_paymoney .ico_cardlogo{width:67px;height:13px;margin-top:2px;background-position:0 -220px}/** 페이머니 */
.card_pay_s.card_paymoney .detail_cardnum{color:#111}
.card_pay_s.card_paymoney .link_enter{color:rgba(17,17,17,0.7)}
.card_pay_s.on_card .use_card{color:#fce800}
.card_pay_s.off_card{background-color:#e5e5e5}
.card_pay_s.off_card .inner_card{border:1px solid rgba(0,0,0,0.1)}
.card_pay_s.off_card .tit_card{color:rgba(17,17,17,0.3)}
.card_pay_s.off_card .tit_card .link_help{margin-top:2px}
.card_pay_s.off_card .detail_cardnum{color:rgba(17,17,17,0.3)}
.card_pay_s.off_card .link_enter{color:#777}
.card_pay_s.card_kb.off_card .ico_cardlogo{background-position:-130px 0}/** 국민 */
.card_pay_s.card_nh.off_card .ico_cardlogo{background-position:-130px -20px}/** 농협 */
.card_pay_s.card_shinhan.off_card .ico_cardlogo{background-position:-130px -40px}/** 신한 */
.card_pay_s.card_hana.off_card .ico_cardlogo{background-position:-130px -60px}/** 하나 */
.card_pay_s.card_bc.off_card .ico_cardlogo{background-position:-130px -80px}/** 비씨 */
.card_pay_s.card_lotte.off_card .ico_cardlogo{background-position:-130px -100px}/** 롯데 */
.card_pay_s.card_samsung.off_card .ico_cardlogo{background-position:-130px -120px}/** 삼성 */
.card_pay_s.card_citi.off_card .ico_cardlogo{background-position:-130px -140px}/** 시티 */
.card_pay_s.card_hyundai.off_card .ico_cardlogo{background-position:-130px -160px}/** 현대 */
.card_pay_s.card_kakaobank.off_card .ico_cardlogo{background-position:-130px -180px}/** 카카오뱅크 */
.card_pay_s.card_kakaopay.off_card .ico_cardlogo{background-position:-130px -200px}/** 카카오페이 */
.card_pay_s.card_paymoney.off_card .ico_cardlogo{background-position:-130px -220px}/** 페이머니 */
.card_pay_s .btn_del{position:absolute;right:0px;top:0px;height:10px;padding:12px}
.card_pay_s .btn_del .ico_del{width:10px;height:10px;opacity:0.5;filter:alpha(opacity=50);background-position:-180px -500px}
.card_pay_s .btn_del:hover .ico_del{opacity:1;filter:alpha(opacity=100)}
/* layer_choice2 */
.layer_choice2{display:none;position:absolute;left:0;z-index:20;width:430px;padding:19px 24px 25px;border:1px solid #888;border-radius:2px;background-color:#fff;-webkit-box-shadow:0 1px 1px rgba(0,0,0,0.1);box-shadow:0 1px 1px rgba(0,0,0,0.1)}
.layer_choice2 .tit_layer{display:block;margin-bottom:7px;font-size:14px;color:#777;font-weight:normal}
.layer_choice2 .tit_layer .fc_empty{font-weight:700}
.layer_choice2 .tit_layer .txt_num{color:#326edc}
.layer_choice2 .wrap_list{padding:1px 2px 1px 0;border:1px solid #e6e6e6;box-sizing:border-box}
.layer_choice2 .list_menu{overflow-x:hidden;overflow-y:auto;max-height:264px}
.layer_choice2 .list_menu li{width:100%;height:50px;border-top:1px solid #e6e6e6;box-sizing:border-box}
.layer_choice2 .list_menu li:first-child{border-top:0 none}
.layer_choice2 .list_menu .link_menu{display:block;height:100%;padding:0 29px 0 19px;line-height:49px;color:#555;text-decoration:none}
.layer_choice2 .list_menu .link_menu:hover{background-color:#f5f5f5}
.layer_choice2 .link_menu .txt_menu{float:left;overflow:hidden;width:290px;color:#326edc;text-overflow:ellipsis;white-space:nowrap}
.layer_choice2 .link_menu .txt_append{float:right;width:61px;text-align:left}
.settingmyinfo_wrap .managebox_tit .opt_select{min-width:145px}
.settingmyinfo_wrap .managebox_tit .opt_account{min-width:300px;max-width:300px}
.settingmyinfo_wrap .managebox_tit .opt_state{min-width:158px}
/* 2021-04-12 table head 2줄타입 시작 */
.thead_type2 th{height:40px}
.thead_type2 .inner_tbl{padding-top:9px;padding-bottom:9px}
/* 2021-04-12 table head 2줄타입 종료 */
/* 현금영수증 조회 */
.tax_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.tax_wrap .tit_dsp{padding-bottom:15px;border-bottom:1px solid rgba(0,0,0,0.05)}
.tax_wrap .txt_append{display:block;margin:15px 0 20px;font-size:13px;line-height:24px;color:#555}
.tax_wrap .tit_box{display:block;margin-top:6px;font-weight:700;font-size:16px;line-height:24px;color:#000}
.tax_wrap .ad_managebox .gm_calendar{position:relative;top:0;left:0}
.tax_wrap .managebox_tit .opt_select{min-width:148px}
.tax_wrap .opt_select .link_selected{min-width:auto}
.tax_wrap .btn_gs .inner_g{padding:5px 9px 6px;font-size:11px;line-height:17px}
.tax_wrap .tbl_g2 .tr_loading .loading_wrap{padding:100px 0}
.tax_wrap .tbl_g2 .nodata_info{height:330px}
.os_mac .tax_wrap .tbl_g2 .wrap_btn,.tax_wrap .tbl_g2 .wrap_btn{margin-top:-4px}
/* 현금영수증 레이어 */
.tax_layer .inner_basic_layer{width:604px}
.tax_layer .layer_head{height:59px}
.tax_layer .layer_body{padding:0 30px 30px}
.tax_layer .logo_popup{width:53px;margin:4px 6px 0 0}
.tax_layer .logo_popup,.os_mac .tax_layer .logo_popup{margin-top:23px}
.tax_layer .tit_details{margin:30px 0 20px;font-size:16px;line-height:24px}
.tax_layer .tbl_layer th{line-height:20px}
.tax_layer .list_append{margin-top:20px}
.tax_layer .basic_layer .btn_close{top:14px;right:20px}
/* 픽셀&SDK 연동 관리 */
.pixelsdk_wrap{width:100%;min-width:1180px;max-width:1320px;margin:0 auto}
.pixelsdk_wrap .pixelsdk_tit{padding-bottom:14px;margin-bottom:15px;border-bottom:1px solid rgba(0,0,0,0.05)}
.pixelsdk_wrap .list_append{margin-bottom:30px}
.pixelsdk_wrap .list_append li{font-size:13px}
.pixelsdk_wrap .ad_managebox{position:relative;margin-top:30px}
.managebox_tit .txt_noti{margin-left:10px;font-size:13px;line-height:36px;color:#777;vertical-align:top}
.managebox_tit .fc_emph{font-weight:700}
.tbl_g2 .sub_time{display:block;font-size:12px;line-height:14px;color:#777;font-weight:normal}
.tbl_g2 .txt_info{margin-top:-4px;color:#999}
.tbl_g2 td .sub_time{padding-left:16px}
.tbl_g2 .event_name{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.tbl_g2 .tr_depthitem .event_name{position:relative;padding-left:15px}
.tbl_g2 .tr_depthitem .event_name:before{position:absolute;left:0;top:4px;width:7px;height:7px;border-left:1px solid #b2b2b2;border-bottom:1px solid #b2b2b2;content:''}
.pixelsdk_wrap .tbl_event td{height:66px}
.pixelsdk_wrap .tbl_event .inner_tbl{padding:4px 12px 6px}
.pixelsdk_wrap .tbl_g2 .nodata_info{height:330px}
.group_name .box_inptxt{width:708px}
/* 픽셀 연동관리 개편 */
.pixelsdk_wrap .tab_g{overflow:visible;height:58px;border-radius:4px 4px 0 0;border:0 none;border-bottom:1px solid rgba(0,0,0,0.15);background-color:#f5f5f5}
.pixelsdk_wrap .tab_g li{margin:-1px 0 -1px -1px}
.pixelsdk_wrap .tab_g .link_tab{width:auto;height:60px;padding:2px 45px 0;line-height:59px;font-weight:700;font-size:16px;color:#777;border:1px solid transparent;box-sizing:border-box}
.pixelsdk_wrap .tab_g .on .link_tab{color:#000;border-color:rgba(0,0,0,0.15);border-bottom-color:#fff;background-color:#fff}
.pixelsdk_wrap .tab_g .fc_emph{color:#777;font-weight:700}
.pixelsdk_wrap .tab_g .on .fc_emph{color:#326edc}
.pixelsdk_wrap .tab_right{position:absolute;top:11px;right:19px}
.pixelsdk_wrap .managebox_tit{height:36px;padding:11px 19px 12px}
.pixelsdk_wrap .managebox_tit .desc_box{display:inline-block;height:36px;font-size:13px;line-height:34px;color:#777}
.ad_managebox .tbl_g2 .f_right + .link_txt{display:block;padding-right:70px}
.tblg2_wrap td .desc_subtxt{display:block;margin-top:-2px;color:#777}
.tooltip_basic.tooltip_pixelsdk{overflow-y:auto;max-width:460px;max-height:410px;line-height:18px}
.tooltip_basic.tooltip_pixelsdk .list_tooltip{padding-top:2px}
.tooltip_pixelsdk .list_tooltip + .tit_tooltip{margin-top:16px}
.pixelcreat_layer .inner_basic_layer{width:720px}
.pixelcreat_layer .layer_body{padding:28px 30px 30px 30px}
.pixelcreat_layer .box_search2{margin-top:20px}
.pixelcreat_layer .box_search2 .box_inptxt{width:567px}
.pixelcreat_layer .box_search2 .btn_gm{border-color:#ddd}
.pixelcreat_layer .desc_pixelcreat{margin-right:-3px;font-size:14px;line-height:22px;color:#555}
.pixelcreat_layer .box_search2 + .desc_pixelcreat,.pixelcreat_layer .box_info + .desc_pixelcreat{margin-top:30px}
.pixelcreat_layer .box_info{margin-top:20px;padding:12px 20px;border:1px solid #ddd;border-radius:2px;background:#f5f5f5}
.pixelcreat_layer .box_info .desc_info{font-size:14px;font-weight:700;line-height:22px;color:#111}
.pixelcreat_layer .box_pixelcreat{padding:20px;margin-top:10px;border:1px solid #ddd;border-radius:2px}
.pixelcreat_layer .box_pixelcreat .tit_g{position:relative;display:block;padding:0 0 10px 7px;color:#777;font-size:14px;line-height:23px;font-weight:400}
.pixelcreat_layer .box_pixelcreat .tit_g:after{position:absolute;top:10px;left:0;width:3px;height:3px;border-radius:3px;background:#777;content:''}
.pixelcreat_layer .box_pixelcreat li + li{margin-top:30px}
.pixelcreat_layer .box_pixelcreat .btn_gm{margin-right:7px}
/* 픽셀 연동관리 팝업 (모먼트복사) */
/*(팝업)카카오 서비스 연동*/
.pixelkakakao_layer .inner_basic_layer{width:720px}
.pixelkakakao_layer .layer_body{padding:22px 30px 30px 30px}
.pixelkakakao_layer .box_card{margin-top:11px;border-color:#d9d9d9}
.pixelkakakao_layer .layer_subtit{line-height:38px;font-weight:500;color:#333}
.pixelkakakao_layer .layer_subtit .fw_normal{font-weight:400}
.pixelkakakao_layer .layer_subtit .fw_normal .fc_gray{color:#777}
.pixelkakakao_layer .wrap_tab{position:relative}
.pixelkakakao_layer .wrap_tab .search_inp2{position:absolute;top:7px;right:20px;width:147px}
.pixelkakakao_layer .tab_g{overflow:visible;height:49px;border-radius:0;border:0 none;border-bottom:1px solid #ddd;background:#f5f5f5}
.pixelkakakao_layer .tab_g li{height:51px}
.pixelkakakao_layer .tab_g li + li .link_tab{border-left:1px solid #ddd}
.pixelkakakao_layer .tab_g .link_tab{width:auto;height:50px;padding:0 44px;line-height:18px;color:#777;font-weight:700;border-radius:0;letter-spacing:0}
.pixelkakakao_layer .tab_g .inner_tab{display:table;width:auto;height:100%}
.pixelkakakao_layer .tab_g .inner_txt{display:table-cell;vertical-align:middle}
.pixelkakakao_layer .tab_g li{position:relative;margin:-1px 0 -1px -1px}
.pixelkakakao_layer .tab_g .on .link_tab{border:1px solid transparent}
.pixelkakakao_layer .tab_g .on .link_tab{border-color:#d9d9d9;border-bottom-color:#fff;background-color:#fff;color:#111}
.pixelkakakao_layer .tbl_g2 th .ico_arrow{display:inline-block;width:7px;height:12px;margin:5px 7px;background-position:-670px -445px;vertical-align:top}
.pixelkakakao_layer .itemtbl_wrap{border:none;border-top:1px solid #ddd;border-radius:0}
.pixelkakakao_layer .tbl_itemchoice th{font-weight:700;height:52px}
.pixelkakakao_layer .tbl_itemchoice .inner_tbl{position:relative;display:block;padding:0 19px;font-size:13px;line-height:19px}
.pixelkakakao_layer .itemtbl_wrap .itemtbl_body{max-height:342px}
.pixelkakakao_layer .itemtbl_wrap .itemtbl_body td{height:66px}
.pixelkakakao_layer .itemtbl_wrap .itemtbl_body .nodata_info{height:342px;text-align:center}
.pixelkakakao_layer .itemtbl_wrap .link_txt .img_profile{overflow:hidden;position:absolute;top:50%;left:20px;width:36px;height:36px;margin-top:-18px;border-radius:36px;background:#EBEBEB}
.pixelkakakao_layer .itemtbl_wrap .link_txt .img_profile img{width:100%}
.pixelkakakao_layer .itemtbl_wrap .link_txt .inner_g{display:-webkit-box;overflow:hidden;max-height:36px;padding-left:47px;line-height:18px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical}
.pixelkakakao_layer .itemtbl_wrap .fc_gray{margin-top:-1px;color:#777}
.pixelkakakao_layer .itemtbl_wrap .link_txt.fc_black{color:#333}
.pixelkakakao_layer .tbl_top{padding:20px 20px}
.pixelkakakao_layer .list_guide{padding:2px 0 0 0;margin-right:-2px;border:0 none}
.pixelkakakao_layer .list_guide li{padding-left:7px;line-height:18px;font-size:13px;color:#777}
.pixelkakakao_layer .list_guide li:after{top:7px;width:3px;height:3px;border-radius:3px;background:#777}
.pixelkakakao_layer .list_guide li + li{margin-top:0}
.pixelkakakao_layer .layer_foot .wrap_delbtn{float:left;margin-top:0;font-weight:500;line-height:16px}
.pixelkakakao_layer .wrap_delbtn .btn_gm{position:relative;height:34px;margin-right:7px;padding-right:24px;border-radius:34px;border-color:#ddd;background:#fff}
.pixelkakakao_layer .wrap_delbtn .btn_gm .inner_g{padding-left:20px;line-height:18px}
.pixelkakakao_layer .wrap_delbtn .btn_gm .ico_del{position:absolute;top:9px;right:18px;width:10px;height:10px;margin:3px auto;background-position:-770px -150px;opacity:0.2}
.pixelkakakao_layer .wrap_delbtn .btn_gm:hover .ico_del{opacity:1}
.pixelkakakao_layer .box_card + .txt_error{padding-top:20px}
/*(팝업)광고계정 연동*/
.pixelaccount_layer .tbl_top{padding:12px 20px}
.pixelaccount_layer .tbl_top:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.pixelaccount_layer .tbl_top .txt_noti{color:#777777;font-size:13px;line-height:36px}
.pixelaccount_layer .tbl_top .opt_select,.pixelaccount_layer .tbl_top .opt_select .link_selected{min-width:128px}
.pixelaccount_layer .tbl_top .box_inptxt{float:right;margin-left:10px}
.pixelaccount_layer .list_guide + .box_card{margin-top:20px}
.pixelaccount_layer .itemtbl_wrap .itemtbl_body{max-height:372px}
/*(팝업)픽셀 & SDK 설치*/
.pixelinstall_layer .cont_install{padding:22px 20px;border-top:1px solid #ddd}
.pixelinstall_layer .tit_install{display:block;line-height:18px;font-size:14px;color:#333;font-weight:400}
.pixelinstall_layer .desc_install{margin-right:-5px;padding-top:5px;line-height:18px;font-size:14px;color:#777}
.pixelinstall_layer .btn_install{padding-top:13px}
.pixelinstall_layer .btn_install + .tit_install{padding-top:32px}
.pixelinstall_layer .list_btninstall{overflow:hidden;margin-top:13px;border-radius:2px;border:1px solid rgba(0,0,0,0.15)}
.pixelinstall_layer .list_btninstall li{float:left;width:25%;border-right:1px solid rgba(0,0,0,0.15);box-sizing:border-box}
.pixelinstall_layer .list_btninstall li:hover{background:rgba(0,0,0,0.08)}
.pixelinstall_layer .list_btninstall li:nth-child(4n){border-right:none}
.pixelinstall_layer .list_btninstall li:nth-child(5),.pixelinstall_layer .list_btninstall li:nth-child(6),.pixelinstall_layer .list_btninstall li:nth-child(7),.pixelinstall_layer .list_btninstall li:nth-child(8){border-top:1px solid rgba(0,0,0,0.15)}
.pixelinstall_layer .list_btninstall li .link_install,.pixelinstall_layer .list_btninstall li .link_install2{display:block;font-weight:700;font-size:12px;color:#555;text-align:center;text-decoration:none}
.pixelinstall_layer .list_btninstall li .link_install{height:69px;line-height:69px}
.pixelinstall_layer .list_btninstall li .link_install2{height:34px;line-height:34px}
/* 모먼트 import 싱크 css */
.pixelsdk_set_wrap .managebox_tit{height:auto}
.pixelsdk_set_wrap .list_append{clear:both;padding-top:6px;margin-bottom:15px}
.pixelsdk_set_wrap .list_append li + li{margin-top:0}
.pixelsdk_set_wrap .list_append li:before{content:none}
.pixelsdk_set_wrap .set_table .set_head .btn_gm,.pixelsdk_set_wrap .managebox_tit .btn_gm{border-color:#ddd}
.pixelsdk_set_wrap .list_append{margin:-2px 0 20px;padding-top:15px}
.pixelsdk_set_wrap .list_append li{font-size:14px;color:#777}
.pixelsdk_set_wrap .tab_g{overflow:visible;height:56px;border:0 none;border-bottom:1px solid #ddd;border-radius:4px 4px 0 0;background-color:#fafafa}
.pixelsdk_set_wrap .tab_g li{overflow:visible;float:left;position:relative;margin:-1px 0 -1px -1px}
.pixelsdk_set_wrap .tab_g .link_tab{display:block;width:auto;height:56px;padding:0 30px;border:1px solid transparent;border-radius:4px 4px 0 0;font-size:14px;line-height:53px;letter-spacing:-1px;text-decoration:none;color:#555;text-align:center}
.pixelsdk_set_wrap .tab_g .on .link_tab{position:relative;z-index:1;border-color:#ddd;border-bottom-color:#fff;font-weight:700;color:#111;background-color:#fff}
.pixelsdk_layer .inner_basic_layer{width:650px}
.addevent_layer .inner_basic_layer{width:650px}
/* 픽셀&SDK 권한 추가하기 레이어 */
.addauthor_layer .inner_basic_layer{width:750px}
.addauthor_layer .layer_body{padding:30px 30px}
.addauthor_layer .addauthor_top{overflow:hidden}
.addauthor_layer .addauthor_top .txt_addauthor{float:left;height:36px;line-height:36px}
.addauthor_layer .addauthor_top .btn_gm{float:right}
.addauthor_layer .itemtbl_wrap{margin-top:30px}
.addauthor_layer .itemtbl_wrap .inner_cell{padding:7px 10px}
.addauthor_layer .itemtbl_wrap .itemtbl_body{max-height:353px}
.addauthor_layer .itemtbl_wrap .nodata_wrap{height:254px}
.addauthor_layer .itemtbl_wrap .nodata_wrap .inner_nodata{line-height:25px}
.addauthor_layer .itemtbl_wrap .thumb_loading{height:199px}
.addauthor_layer .tbl_itemchoice th{height:49px}
.addauthor_layer .tbl_itemchoice td{height:50px}
.addauthor_layer .tbl_itemchoice .th_choice{text-align:center}
.addauthor_layer .tbl_itemchoice .th_choice .inner_cell{padding:0}
.addauthor_layer .tbl_itemchoice .td_choice{text-align:center}
.addauthor_layer .tbl_itemchoice .radio_inp{cursor:pointer}
.addauthor_layer .tbl_itemchoice .lab_name{display:block;cursor:pointer}
.addauthor_layer .list_append{margin-top:0}
.addauthor_layer .list_append li{color:#555}
.addauthor_layer .txt_error{padding-top:10px}
/*(팝업)픽셀 &amp;SDK 멤버 권한 요청*/
.authority_layer .inner_basic_layer{width:720px}
.authority_layer .layer_body{padding:30px 30px 30px 30px}
.authority_layer .tit_authority{display:block;font-weight:500;font-size:14px;line-height:20px;color:#333}
.authority_layer .search_inp2 + .tit_authority{padding-top:30px}
.authority_layer .desc_authority{padding-top:10px;font-size:14px;line-height:20px;color:#555}
.authority_layer .wrap_radioinp{padding-top:18px}
.authority_layer .wrap_radioinp .box_radioinp{margin-right:28px}
.authority_layer .box_radioinp .lab_radio{font-size:13px;color:#333}
.authority_layer .search_inp2{margin-top:18px}
.authority_layer .list_authority{padding-top:12px}
.authority_layer .list_authority li{position:relative;padding-left:8px;line-height:18px;font-size:14px;color:#555}
.authority_layer .list_authority li:before{position:absolute;top:8px;left:0;width:3px;height:3px;border-radius:3px;background:#555;content:''}
.authority_layer .list_authority li + li{margin-top:2px}
.authority_layer .itemtbl_wrap{margin-top:21px;border:1px solid #ddd;border-radius:0}
.authority_layer .itemtbl_head th{height:45px;font-weight:700}
.authority_layer .tbl_itemchoice td{height:60px}
.authority_layer .itemtbl_wrap .inner_tbl{padding:0 19px;font-size:13px;line-height:19px}
.authority_layer .itemtbl_wrap .inner_tbl .link_help{margin-top:-1px}
.authority_layer .itemtbl_wrap .fc_gray{color:#777}
.authority_layer .tbl_itemchoice .align_c .inner_tbl{padding:0}
.authority_layer .itemtbl_wrap .itemtbl_body{max-height:305px}
.authority_layer .itemtbl_wrap .itemtbl_body .nodata_info{height:305px;text-align:center}
/* 픽셀SDK 상세보기 - 필요 공통 */
.btn_gm.gm_round{border-radius:16px;border-color:rgba(0,0,0,0.08);background:#f4f4f4}
.tit_dsp .btn_gm.gm_round{float:left;margin-left:10px;background:#f8f8f8}
.tit_dsp .btn_gm.gm_round{height:32px;margin-top:-2px}
.tit_dsp .btn_gm.gm_round .inner_g{line-height:17px}
.ad_managebox .tab_g8 + .audience_detail{border-top:none}/** 2021-07-06 수정 */
.ad_managebox .tab_g8{padding:10px 20px 0;border-top:1px solid #ddd}
.ad_managebox .tab_g8 .link_tab{padding:0 40px;border-radius:0}
.ad_managebox .tab_g8 .link_tab .fc_emph{display:inline-block;padding-left:3px;color:#777;vertical-align:top}
.ad_managebox .tab_g8 .link_tab:hover .fc_emph{color:#111}
.ad_managebox .tab_g8 .on .link_tab .fc_emph{font-weight:bold;color:#326EDC}
.ad_managebox .tbl_top:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.ad_managebox .tbl_top .txt_noti{display:inline-block;padding-bottom:20px;font-size:13px;color:#777;vertical-align:top}
.ad_managebox .tbl_top .f_left + .f_left .txt_noti{padding-left:20px;line-height:36px}
.ad_managebox .tbl_top .opt_select{float:right;max-width:226px;width:226px;margin-top:-9px}
.audience_detail{position:relative;padding:20px 20px 30px;border-top:1px solid #ddd}
.audience_detail .tit_sub{display:block;margin-bottom:15px;font-weight:500;color:#111}
/* 픽셀SDK 상세보기 - 컨텐츠 */
.pixelsdkdetail_wrap{width:100%;min-width:1180px;max-width:1320px;margin:0 auto}
.pixelsdkdetail_wrap .dsp_tit .f_right{position:relative}
.pixelsdkdetail_wrap .dsp_tit .tit_dsp .btn_gm{height:36px}
.pixelsdkdetail_wrap .dsp_tit .tit_dsp .btn_gm.gm_round{height:32px;margin-left:16px}
.pixelsdkdetail_wrap .tit_dsp .sub_code{float:left;padding-left:5px;line-height:30px;font-size:20px;font-weight:500;color:#777}
.pixelsdkdetail_wrap .tab_g9{margin-bottom:20px}
.pixelsdkdetail_wrap .btn_alarm{position:relative;padding:5px 11px 5px}
.pixelsdkdetail_wrap .btn_alarm .ico_comm{width:22px;height:22px;background-position:-715px -470px}
.pixelsdkdetail_wrap .btn_alarm .num_alarm{position:absolute;top:0;right:5px;width:14px;height:14px;border-radius:14px;border:1px solid #f8f8f8;line-height:14px;font-size:9px;color:#fff;background:#326EDC}
.pixelsdkdetail_wrap .ad_managebox .desc_state{position:relative;display:inline-block;padding-left:18px;color:#333;vertical-align:top}
.pixelsdkdetail_wrap .ad_managebox .desc_state .ico_state{position:absolute;top:0;left:0;width:10px;margin:6px 8px 0 0}
.pixelsdkdetail_wrap .ad_managebox .desc_state .ico_state:before{float:left;width:10px;height:10px;border-radius:20px;background-color:#ccc;content:''}
.pixelsdkdetail_wrap .ad_managebox .desc_state.on{color:#326EDC}
.pixelsdkdetail_wrap .ad_managebox .desc_state.on .ico_state:before{background:#326EDC}
.pixelsdkdetail_wrap .ad_managebox .tbl_g2 a.desc_state.on{text-decoration:underline}
.pixelsdkdetail_wrap .itemtbl_wrap .sub_txt,.pixelsdkdetail_wrap .tbl_g2 .sub_txt{display:block;line-height:17px;font-size:11px;color:#999;font-weight:400}
.pixelsdkdetail_wrap .ad_managebox .desc_state + .sub_txt{padding-left:2px;text-decoration:none}
.pixelsdkdetail_wrap .ad_managebox .group_inp .box_inptxt{width:248px}
.pixelsdkdetail_wrap .itemtbl_wrap .itemtbl_body td:first-child .inner_tbl{line-height:20px}
.pixelsdkdetail_wrap .itemtbl_wrap .itemtbl_body td:first-child .sub_txt{display:block;line-height:20px}
.pixelsdkdetail_wrap .tblg2_wrap .btn_gs{min-width:50px;height:28px;margin:-4px 1px}
.pixelsdkdetail_wrap .tblg2_wrap .btn_gs .inner_g{padding-left:10px;padding-right:10px;font-size:11px;line-height:23px}
.pixelsdkdetail_wrap .tblg2_wrap .btn_gs.wd100{width:106px}
.pixelsdkdetail_wrap .tblg2_wrap .btn_depth{margin-left:10px}
.pixelsdkdetail_wrap .ad_managebox .tbl_top{margin-top:-8px;padding-bottom:12px}
.pixelsdkdetail_wrap .ad_managebox .tbl_top .txt_noti{padding-bottom:0;line-height:36px}
.pixelsdkdetail_wrap .ad_managebox .managebox_tit .btn_gm + .btn_gm,.pixelsdkdetail_wrap .ad_managebox .tbl_top .btn_gm + .btn_gm{margin-left:6px}
.pixelsdkdetail_wrap .ad_managebox .tbl_top .opt_select{margin-top:0}
.pixelsdkdetail_wrap .tbl_g2 .tr_depthitem td,.pixelsdkdetail_wrap .itemtbl_wrap .tr_depthitem td{background-color:rgba(0,0,0,0.03)}
.pixelsdkdetail_wrap .tbl_g2 .txt_limit{display:inline-block;display:-webkit-box;white-space:normal}
.pixelsdkdetail_wrap .tbl_g2 .desc_app{display:inline-block;overflow:hidden}
.pixelsdkdetail_wrap .tbl_g2 .desc_app .link_txt{float:right;padding-left:3px}
.pixelsdkdetail_wrap .tbl_g2 .ico_depth{display:inline-block;width:8px;height:7px;margin:7px 4px 0 5px;background-position:-200px -80px;vertical-align:top}
.pixelsdkdetail_wrap .tbl_g2 .tr_inactive .ico_depth{background-position:-200px -80px;opacity:0.4;filter:alpha(opacity=40)}
.pixelsdkdetail_wrap .tbl_g2 .link_txt .ico_depth{background-position:-200px -90px}
.pixelsdkdetail_wrap .tbl_g2 .ico_depth,.pixelsdkdetail_wrap .itemtbl_wrap .ico_depth{display:inline-block;width:8px;height:7px;margin:7px 4px 0 5px;background-position:-200px -80px;vertical-align:top}
.pixelsdkdetail_wrap .wrap_sdkguide{padding-top:20px}
.pixelsdkdetail_wrap .wrap_sdkguide .link_guide .ico_arrow{position:absolute;left:0;top:50%;width:0;height:0;margin-top:-3px;border-left:5px solid transparent;border-right:5px solid transparent;border-top:6px solid #333;vertical-align:middle}
.pixelsdkdetail_wrap .wrap_sdkguide.on .link_guide .ico_arrow{border-bottom:6px solid #333;border-top:0 none}
.pixelsdkdetail_wrap .wrap_sdkguide .link_guide{position:relative;padding-left:17px;font-size:13px;line-height:20px;font-weight:500;color:#333;text-decoration:none}
.pixelsdkdetail_wrap .wrap_sdkguide .desc_sdkguide{padding:5px 0 0 17px;font-size:13px;line-height:20px;color:#777}
.pixelsdkdetail_wrap .wrap_sdkguide.on .area_sdkguide{display:block}
.pixelsdkdetail_wrap .area_sdkguide{display:none}
.pixelsdkdetail_wrap .wrap_sdkguide .list_sdkguide{overflow:hidden;margin-top:21px}
.pixelsdkdetail_wrap .list_sdkguide li{float:left;position:relative;width:20%;height:155px;padding:19px;border:1px solid #ddd;border-left:none;box-sizing:border-box}
.pixelsdkdetail_wrap .list_sdkguide li.on{border-color:#326EDC;background:#ECF3FF}
.pixelsdkdetail_wrap .list_sdkguide li.on:after{position:absolute;top:-1px;bottom:-1px;left:-1px;width:1px;background:#326EDC;content:''}
.pixelsdkdetail_wrap .list_sdkguide li:first-child{border-left:1px solid #ddd}
.pixelsdkdetail_wrap .list_sdkguide li .tit_g{display:block;line-height:19px;font-weight:500;font-size:13px;color:#333}
.pixelsdkdetail_wrap .list_sdkguide li .txt_g{padding-top:5px;line-height:19px;font-weight:400;font-size:13px;color:#555}
.pixelsdkdetail_wrap .list_sdkguide li .link_g{position:absolute;bottom:18px;left:20px;width:100%;font-size:13px;color:#326EDC;text-align:left;text-decoration:none}
.pixelsdkdetail_wrap .list_sdkguide li .link_g .ico_comm{display:inline-block;width:7px;height:10px;margin:5px 0 0 3px;background-position:-680px -445px;vertical-align:top}
.pixelsdkdetail_wrap .itemtbl_wrap .txt_event,.pixelsdkdetail_wrap .tbl_g2 .txt_event{display:inline-block;max-width:240px;padding-top:1px;vertical-align:top}
.pixelsdkdetail_wrap .itemtbl_wrap .sub_txt,.pixelsdkdetail_wrap .tbl_g2 .sub_txt{display:inline-block;font-size:13px;line-height:22px;color:#777;font-weight:400;vertical-align:top}
.pixelsdkdetail_wrap .gm_line.in_active,.pixelsdkdetail_wrap .gm_bl.in_active{border-color:#ddd;color:#ccc;background-color:#f5f5f5}
.pixelsdkdetail_wrap .btn_gs.in_active{border-color:#ddd;color:#ccc;background-color:#f5f5f5}
.pixelsdkdetail_wrap .td_inactive{color:#ccc}
.pixelsdkdetail_wrap .ad_managebox .group_limit{display:inline-block;overflow:hidden;max-width:100%;vertical-align:top}
.pixelsdkdetail_wrap .ad_managebox .group_limit .f_right{padding-left:2px}
.pixelsdkdetail_wrap .managebox_tit .opt_select.opt_filter,.pixelsdkdetail_wrap .managebox_tit .opt_select.opt_filter .link_selected{min-width:168px}
/* 2021-07-06 삭제 .pixelsdkdetail_wrap .ad_managebox .tab_g8 + .audience_detail{padding-bottom:0} */
/* 픽셀 & SDK 연동 상세 */
.linkagedetail_wrap .tbl_g3 th{background-color:#f5f5f5}
.linkagedetail_wrap .tbl_g3 .btn_gm{margin:-7px 0 -6px 12px}
.linkagedetail_wrap .tbl_g3 .link_help + .btn_gm{margin-left:6px}
.linkagedetail_wrap .ad_managebox .desc_state.on{color:#333}
.pixelsdkdetail_wrap .ad_managebox .itemtbl_wrap{margin-top:-1px;margin-bottom:-1px;border-left:none;border-right:none}
.pixelsdkdetail_wrap .itemtbl_wrap .itemtbl_body{max-height:556px}
.ad_managebox .itemtbl_wrap .itemtbl_head th{height:55px;font-size:13px;color:#555;font-weight:500}
.ad_managebox .itemtbl_wrap .itemtbl_body td{height:47px;border-right:1px solid #ebebeb}
.ad_managebox .itemtbl_wrap .itemtbl_head th:first-child .inner_tbl,.ad_managebox .itemtbl_wrap .itemtbl_body td:first-child .inner_tbl{padding-left:20px}
.ad_managebox .itemtbl_wrap .itemtbl_body .nodata_info{height:250px;color:#777;text-align:center;vertical-align:middle}
.ad_managebox .itemtbl_wrap .inner_tbl{position:relative;padding:11px 12px 14px;line-height:22px;font-size:13px}
.ad_managebox .itemtbl_wrap .itemtbl_body td:last-child{border-right:0}
.ad_managebox .itemtbl_wrap .btn_gs{min-width:50px;height:28px;margin:-4px 1px}
.ad_managebox .itemtbl_wrap .btn_gs .inner_g{padding-left:10px;padding-right:10px;font-size:11px;line-height:23px}
.ad_managebox .itemtbl_wrap .btn_gs.wd100{width:106px}
.ad_managebox .itemtbl_wrap .btn_depth{margin-left:10px}
.ad_managebox .itemtbl_wrap .desc_app{display:inline-block;overflow:hidden}
.ad_managebox .itemtbl_wrap .desc_app .txt_device{float:left;padding-right:3px}
.ad_managebox .itemtbl_wrap .desc_app .txt_limit{position:relative;padding-right:5px}
.itemtbl_wrap .txt_limit{display:inline-block;display:-webkit-box;overflow:hidden;height:21px;max-width:100%;-webkit-line-clamp:1;-webkit-box-orient:vertical;vertical-align:top;white-space:normal}
.ad_managebox .itemtbl_wrap .txt_limit .txt_bracket{position:absolute;top:0;right:0}
.pixelsdkdetail_wrap .ad_managebox .tbl_top{margin-top:-8px;padding-bottom:12px}
.pixelsdkdetail_wrap .ad_managebox .tbl_top .txt_noti{padding-bottom:0;line-height:36px}
.pixelsdkdetail_wrap .ad_managebox .managebox_tit .btn_gm + .btn_gm,.pixelsdkdetail_wrap .ad_managebox .tbl_top .btn_gm + .btn_gm{margin-left:6px}
.pixelsdkdetail_wrap .ad_managebox .tbl_top .opt_select{margin-top:0}
/* 다운로드 버튼 */
.wrap_download{position:relative}
.wrap_download .ico_download{width:10px;height:13px;background-position:-700px -500px}
.wrap_download .btn_gm .ico_download{margin:10px 0 0 12px}
.download_option{display:none;position:absolute;right:0;top:0;min-width:147px;padding-top:44px;z-index:10}
.wrap_download .btn_gm:hover + .download_option,.download_option:hover{display:block}
.option_group{position:relative;overflow:hidden;padding:12px 0;background:#FFF;border-radius:4px;box-shadow:0 2px 10px 0 rgba(0,0,0,0.25)}
.list_download li:after{display:block;clear:both;content:""}
.list_download .link_download{display:inline-flex;order:2;position:relative;width:147px;padding:8px 0 8px 32px;font-size:14px;line-height:20px;color:#333;box-sizing:border-box;text-decoration:none}
.list_download .link_download:hover,.list_download .link_download.on{background:rgba(0,0,0,0.03)}
.list_download .ico_comm{display:inline-block}
.link_download .ico_download{float:right;margin:4px 13px 0 10px}
.link_download .ico_arrow{display:none;position:absolute;top:14px;left:10px;width:8px;height:8px;background-position:-720px -520px}
.list_download .link_download:hover .ico_arrow,.list_download .link_download.on .ico_arrow{display:block}
.list_download:hover{padding-left:207px}
.desc_download{display:none;position:absolute;left:0;top:0;bottom:0;width:206px;padding:20px 16px;border-right:1px solid rgba(0,0,0,0.05);font-size:13px;line-height:22px;color:#555;background:#FFF;box-sizing:border-box}
.link_download:hover + .desc_download,.link_download.on + .desc_download,.desc_download:hover{display:block}
/*gnb 개편 후 공통으로 사용되는 스타일*//** 2023 디자인 가이드 변경 시작 **/
/* comm */
#kakaoLogo{float:left;width:131px;height:14px;background-position:0 0;background-size:100% auto;text-decoration:none}
/* 2021 개편버전 */
#kakaoLogo,#kakaoServiceLogo{background:url(../images/logo_business.png) no-repeat;background-size:131px 14px}
/* 2021 개편버전으로 수정 */
.ico_gnb{display:inline-block;overflow:hidden;font-size:0;line-height:0;background:url(../images/ico_gnb.png) no-repeat;background-size:300px 300px;text-indent:-9999px;vertical-align:top}
/* 2021-GNB전용 */
/* scroll */
.cont_snb .list_snb{scrollbar-width:5px}
.layer_account .fake_scroll{-ms-overflow-style:none}
#kakaoWrap .cont_snb .list_snb::-webkit-scrollbar{width:5px;height:5px}
#kakaoWrap .cont_snb .list_snb::-webkit-scrollbar-button{width:5px;height:5px;background:transparent}
#kakaoWrap .list_relate ::-webkit-scrollbar,#kakaoWrap .layer_account ::-webkit-scrollbar{width:6px;height:6px}
#kakaoWrap .list_relate ::-webkit-scrollbar-track,#kakaoWrap .layer_account ::-webkit-scrollbar-track{background-color:transparent}
#kakaoWrap .list_relate ::-webkit-scrollbar-thumb,#kakaoWrap .layer_account ::-webkit-scrollbar-thumb{background-color:#eee;border-radius:10px}
#kakaoWrap .list_relate ::-webkit-scrollbar-thumb:hover,#kakaoWrap .layer_account ::-webkit-scrollbar-thumb:hover{background-color:#919191}
#kakaoWrap .list_relate ::-webkit-scrollbar-button,#kakaoWrap .layer_account ::-webkit-scrollbar-button{width:6px;height:6px;background:transparent}
/* header */
#kakaoHead{position:fixed;left:0;top:0;z-index:1100;width:100%;min-width:1080px;height:60px;background-color:#19234B}
#kakaoHead h1{float:left;position:relative;z-index:0;padding:23px 24px 0 24px;font-weight:400;font-size:14px}
/* gnb */
.gnb_comm{float:left;padding-left:21px;height:60px}
.gnb_comm .list_gnb{height:60px}
.list_gnb li{float:left;position:relative}
.list_gnb .link_gnb{display:block;height:100%;padding:0 16px;line-height:60px;font-size:15px;color:rgba(255,255,255,0.85)}
.list_gnb .link_gnb:hover{color:white;text-decoration:none}
.list_gnb .link_gnb .ico_gnb{width:16px;height:16px;margin:23px 0 0 8px;background-position:-20px 0}
.list_gnb .on .link_gnb{color:white}
.list_gnb .in_service.on .link_gnb{color:#326EDC;font-weight:700}
.list_gnb .open .link_gnb .ico_gnb{background-position:-40px 0}
.sub_gnb{display:none;position:absolute;left:16px;top:50px;padding:10px 0;background-color:#fff;box-shadow:0 0 8px 2px rgba(0,0,0,0.15);border-radius:4px;z-index:10}
.list_gnb .open .sub_gnb{display:block}
.inner_gnbsub{display:table-cell;position:relative}
.inner_gnbsub + .inner_gnbsub{padding-left:10px}
.list_gnb li:nth-child(3) .sub_gnb{padding:26px 10px 20px}
.list_gnb li:nth-child(3) .sub_gnb .list_gnbsub li{float:none;width:156px}
.sub_gnb .inner_gnbsub .list_gnbsub{padding-top:29px}
.sub_gnb .tit_sub{display:block;position:absolute;top:0;margin:0 0 11px 16px;font-size:12px;line-height:18px;font-weight:normal;color:#919191;cursor:default}
.list_gnbsub li{float:none}
.list_gnbsub li .link_sub{display:block;min-height:36px;padding:0 17px 0 16px;line-height:35px;color:#191919;text-decoration:none;white-space:nowrap}
.list_gnbsub .link_sub:hover{background:#F4F9FF}
.list_gnbsub .link_sub.on{color:#326edc;background:#F4F9FF;font-weight:700}
.list_gnbsub .link_sub.disabled{color:#757B93;pointer-events:none}
.list_gnbsub .link_sub .txt_sub{display:block;margin-top:-9px;padding-bottom:10px;font-size:11px;line-height:16px}
.list_gnbsub .ico_outlink{width:10px;height:10px;margin:13px 0 0 4px;background-position:-80px 0}
/* 사용자메뉴 */
.gnb_relate{float:right;padding-right:17px}
.list_relate{height:60px}
.list_relate li{float:left;position:relative}
.list_relate .link_gnb{display:block;overflow:hidden;position:relative;height:60px;padding:0 13px;font-size:13px;line-height:60px;color:rgba(255,255,255,0.85)}
.list_relate .link_gnb:hover{color:white;text-decoration:none}
.list_relate .on .link_gnb{color:white}
.list_relate .ico_new{width:16px;height:16px;margin:22px 0 0 4px;background-position:-60px 0}
/* 공지사항 레이어 */
.list_relate .layer_service{display:none;overflow:hidden;position:absolute;right:13px;top:50px;z-index:100;width:350px;min-height:460px;max-height:510px;border-radius:4px;box-shadow:0 0 8px 2px rgba(3,9,35,0.15);background-color:#fff}
.list_relate .on .layer_service{display:block}
.layer_service .layer_head{height:49px;padding:0 16px;border-bottom:1px solid #eee;font-weight:700}
.layer_service .tit_service{font-size:14px;line-height:50px;font-weight:700;letter-spacing:-1px;color:#191919}
.layer_service .fake_scroll{overflow-y:auto;height:410px;width:100%}
.layer_service .list_noti{overflow:hidden}
.layer_service .no_data{position:relative;height:410px}
.list_relate .no_data .error_g{display:flex;justify-content:center;align-items:center;width:100%;height:100%}
.list_relate .no_data .ico_loading{display:inline-block;width:30px;height:30px;margin:0 auto;border:2px solid #666;border-top:2px solid #fff;border-radius:30px;font-size:0;line-height:0;text-indent:-9999px;vertical-align:top;box-sizing:border-box;animation:loading 1s linear infinite}
.list_relate .btn_error{font-size:13px;line-height:32px;color:#919191}
.list_relate .btn_error .ico_errordata{width:32px;height:32px;margin-right:10px;background-position:0 -130px}
.list_relate .fake_scroll .no_data{height:74px}
@keyframes loading{100%{transform:rotate(360deg)} }
.list_noti li{float:none}
.list_noti li:last-child{border-bottom:none}
.list_noti .link_noti{display:block;padding:12px 16px 12px 16px;font-size:13px;line-height:19px;color:#191919;text-decoration:none;letter-spacing:-0.03em}
.list_noti li:hover .link_noti{background:#F4F9FF}
.list_noti .txt_noti{font-weight:700}
.list_noti .noti_on .txt_noti{color:#326EDC}
.list_noti .desc_noti{overflow:hidden;display:block;max-height:38px}
.list_noti .txt_date{display:inline-block;font-size:11px;line-height:16px;padding-top:2px;color:#666}
.list_noti .desc_noti .ico_new{display:inline-block;width:34px;height:16px;border-radius:16px;font-size:10px;line-height:16px;background-color:#37c333;text-align:center;vertical-align:top;color:#fff}
.layer_service .ico_gnb{margin:2px 0 0 5px}
.layer_service .link_allview{display:block;height:50px;padding:0 16px;font-weight:400;font-size:13px;line-height:50px;background-color:#FAFAFA;color:#191919;text-decoration:none}
.layer_service .link_allview:hover{font-weight:500}
.layer_service .txt_nodata{padding-top:171px;font-size:13px;line-height:19px;color:#757B93;text-align:center}
/* 마이메뉴 */
.menu_my .link_gnb{overflow:hidden;max-width:174px;min-width:72px;padding:0 27px 0 18px;font-size:12px;text-overflow:ellipsis;white-space:nowrap}
.menu_my .on .link_gnb{opacity:1}
.menu_my .ico_fold{position:absolute;top:25px;right:13px;width:10px;height:11px;margin:0;background-position:-10px 0}
.menu_my.on .ico_fold{background-position:0 0}
.list_relate .layer_mymenu{display:none;overflow:hidden;position:absolute;right:14px;top:50px;z-index:30;background-color:#fff;border-radius:4px;box-shadow:0 0 8px 2px rgba(3,9,35,0.15);white-space:nowrap}
.list_relate .on .layer_mymenu{display:block}
.layer_mymenu .list_service{padding:10px 0 8px;border-top:1px solid #eee}
.layer_mymenu .list_service:first-child{border-top:0 none}
.layer_mymenu .list_service + .list_service{padding:8px 0 10px}
.layer_mymenu .list_service li{float:none}
.layer_mymenu .list_service li:hover .link_sub{background:#F4F9FF}
.layer_mymenu .list_service li.on .link_sub{color:#326EDC;background:#F4F9FF;font-weight:500}
.layer_mymenu .list_service .link_sub{display:block;padding:0 16px;font-size:14px;line-height:35px;color:#191919;text-decoration:none}
.layer_mymenu .list_service .link_sub .txt_sub{position:relative}
.layer_mymenu .list_service .txt_detail{margin-left:6px;font-size:10px;letter-spacing:-0.06em}
.layer_mymenu .list_service .txt_category{float:left;width:64px;font-size:12px;color:#949494}
.layer_mymenu .list_service.gnbsub_etc .link_sub{line-height:31px}
.menu_my .layer_mymenu .list_service .on .txt_sub:after{display:block}
/* LNB */
#mFeature{position:fixed;left:0;top:60px;bottom:0;z-index:1100;background-color:#fff;transition:width 0.2s ease}
.feature_wrap{position:relative;overflow:hidden;height:100%;width:60px;padding-bottom:68px;box-sizing:border-box}
.feature_wrap:after{content:'';position:absolute;right:0;top:0;bottom:0;width:1px;background-color:#ddd}
.feature_wrap .cont_info{display:block;height:41px;border-bottom:1px solid #ddd;transition:height 0.2s ease}
/* 상단 타이틀 */
.account_thumb{display:block;width:60px;height:41px;text-align:center;border-bottom:0 none}
.account_thumb .ico_gnb{width:26px;height:27px;margin:7px 0 9px;background-position:-100px 0}
.account_thumb .tit_service{display:none;height:41px;border-bottom:1px solid #ddd;text-align:center;font-weight:500}
.account_thumb .tit_service .link_service{font-size:14px;font-weight:700;line-height:41px;color:#191919}
/* 사용자 정보 */
.account_info{display:none;width:200px}
.account_info .user_info{position:relative;padding:25px 0;margin:0 16px}
.account_info .user_info .tit_account{display:block;position:relative;width:125px;max-height:40px;padding:0 24px 0 22px;margin:0 auto;font-size:12px;line-height:18px;color:#191919;text-align:center;cursor:pointer;font-weight:700}
.account_info .user_info .tit_account .ico_arrow{position:absolute;top:50%;right:1px;width:16px;height:16px;margin-top:-8px;background-position:-130px 0}
.cont_info .on .tit_account .ico_arrow{background-position:-150px 0}
.account_info .ad_info{padding:16px 0;margin:0 16px;border-top:1px solid #eee}
.account_info .ad_info .item_info{font-size:12px;line-height:18px}
.account_info .ad_info .item_info:after{content:'';overflow:hidden;display:block;clear:both}
.account_info .item_info + .item_info{margin-top:6px}
.account_info .item_info .tit_item{float:left;color:#666;font-weight:400}
.account_info .item_info .txt_item{position:relative;float:right;color:#191919}
.account_info .item_info .switch_check{margin-top:4px}
.account_info .item_info .txt_state{color:#191919}
.account_info .item_info .ico_on{width:7px;height:7px;margin:6px 3px 0 0}
.account_info .item_info .ico_on:before{width:7px;height:7px}
.account_info .item_info .ico_off{width:7px;height:7px;margin:6px 3px 0 0}
.account_info .item_info .ico_off:before{width:7px;height:7px}
.account_info .item_info .link_tooltip{text-decoration:underline}
.account_info .item_info .info_tooltip{display:none;position:fixed;top:auto;left:56px;z-index:1;min-width:159px;margin-top:16px}
.cont_info.over .info_tooltip{display:block}
.account_info .item_info .inner_tooltip{position:relative}
.account_info .item_info .info_tooltip .ico_infoarrow{position:absolute;top:-20px;left:15px;width:40px;height:40px;margin-left:-20px;background:url(../images/ico_infoarrow.png) no-repeat;background-size:40px 40px;z-index:-1}
.account_info .item_info .info_tooltip .ico_infoarrow2{position:absolute;top:-20px;left:15px;width:40px;height:40px;margin-left:-20px;background:url(../images/ico_infoarrow2.png) no-repeat;background-size:40px 40px;z-index:1}
.info_tooltip.tooltip_c .ico_infoarrow,.info_tooltip.tooltip_c .ico_infoarrow2{left:50%;margin-left:-20px}
.account_info .item_info .info_tooltip .desc_tooltip{padding:12px;background-color:#fff;box-shadow:0 0 8px 2px rgba(3,9,35,0.15);border-radius:4px;z-index:10;color:#222;letter-spacing:-0.03em}
/* 서브메뉴 */
.cont_snb{overflow:hidden;box-sizing:border-box}
.cont_snb .ico_gnb{position:absolute;top:50%;left:18px;margin-top:-12px;width:24px;height:24px}
.cont_snb .ico_snb1{background-position:0 -70px}
.cont_snb .ico_snb2{background-position:-30px -70px}
.cont_snb .ico_snb3{background-position:-60px -70px}
.cont_snb .ico_snb4{background-position:-90px -70px}
.cont_snb .ico_snb5{background-position:-120px -70px}
.cont_snb .ico_snb6{background-position:-150px -70px}
.cont_snb li.on .ico_snb1{background-position:0 -100px}
.cont_snb li.on .ico_snb2{background-position:-30px -100px}
.cont_snb li.on .ico_snb3{background-position:-60px -100px}
.cont_snb li.on .ico_snb4{background-position:-90px -100px}
.cont_snb li.on .ico_snb5{background-position:-120px -100px}
.cont_snb li.on .ico_snb6{background-position:-150px -100px}
.cont_snb .ico_arrow{display:none;left:170px;width:16px;height:16px;margin-top:-8px;background-position:-130px 0}
.cont_snb li.open .ico_arrow{background-position:-150px 0}
.cont_snb .list_snb{overflow-x:hidden;text-indent:-9999px}
.cont_snb .list_snb li{position:relative;width:200px}
.cont_snb .list_snb li .link_snb{display:block;position:relative;overflow:hidden;min-height:52px;font-size:14px;line-height:20px;color:#191919;box-sizing:border-box}
.cont_snb .list_snb li .link_snb:hover{background-color:#F4F9FF;text-decoration:none}
.cont_snb .list_snb .link_snb .txt_nav{overflow:hidden;display:block;display:-webkit-box;max-height:42px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical}
.cont_snb .list_snb li.on .link_snb{font-weight:700;color:#326EDC;background-color:#F4F9FF}
.sub_snb{overflow:hidden;height:0;display:none}
.sub_snb .link_sub{display:block;min-height:36px;padding:8px 20px 8px 50px;line-height:19px;font-size:13px;color:#191919;box-sizing:border-box}
.sub_snb .link_sub:hover{background-color:#F4F9FF;text-decoration:none}
.sub_snb .link_sub .txt_subnav{overflow:hidden;display:block;display:-webkit-box;max-height:38px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical}
.sub_snb li.on .link_sub{background-color:#F4F9FF;font-weight:700;color:#326EDC}
.fold_btn{position:fixed;left:0;bottom:0;width:59px;height:59px;background-color:#fff;border-top:1px solid #ddd;z-index:100}
.btn_opcl{float:right;width:59px;height:60px;text-align:right;outline:0 none}
.btn_opcl .ico_gnb{width:28px;height:28px;margin-right:16px;background-position:-30px -20px}
/* 오버시 */
.lnb_over #mFeature{box-shadow:0 0 8px 3px rgba(3,9,35,0.15)}
.lnb_over .feature_wrap{width:200px;padding-bottom:0;transition:width 0.2s ease}
.lnb_over .feature_wrap .cont_info{height:auto}
.lnb_over .feature_wrap .account_thumb .ico_gnb{display:none}
.lnb_over .feature_wrap .account_thumb{width:200px}
.lnb_over .cont_info .tit_service{display:block}
.lnb_over .account_info{display:block}
.lnb_over .feature_wrap .cont_snb .list_snb{text-indent:0}
.lnb_over .feature_wrap .cont_snb .list_snb li{width:200px}
.lnb_over .feature_wrap .cont_snb .list_snb li .link_snb{padding:16px 40px 16px 50px}
.lnb_over .feature_wrap .cont_snb .ico_arrow{display:block}
.lnb_over .feature_wrap .cont_snb li.open .sub_snb{display:block;height:auto}
.lnb_over .feature_wrap .cont_snb .list_snb .on.open .link_snb{background-color:#fff}
.lnb_over .feature_wrap ~ .fold_btn{display:none}
.lnb_over .feature_wrap ~ .fold_btn .btn_opcl{display:none}
/* 고정시 */
.lnb_on #mArticle{padding-left:30px}
.lnb_on #mArticle.type_dashboard{padding-left:15px}
.lnb_on .feature_wrap{width:200px}
.lnb_on .feature_wrap .cont_info{height:auto}
.lnb_on .feature_wrap .account_thumb .ico_gnb{display:none}
.lnb_on .feature_wrap .account_thumb{width:200px}
.lnb_on .cont_info .tit_service{display:block}
.lnb_on .account_info{display:block}
.lnb_on .feature_wrap .cont_snb .list_snb{text-indent:0}
.lnb_on .feature_wrap .cont_snb .list_snb li{width:200px}
.lnb_on .feature_wrap .cont_snb .list_snb li .link_snb{padding:16px 40px 16px 50px}
.lnb_on .feature_wrap .cont_snb .ico_arrow{display:block}
.lnb_on .feature_wrap .cont_snb li.open .sub_snb{display:block;height:auto}
.lnb_on .feature_wrap .cont_snb .list_snb .on.open .link_snb{background-color:#fff}
.lnb_on .feature_wrap ~ .fold_btn{width:199px}
.lnb_on .btn_opcl{display:block}
.lnb_on .btn_opcl .ico_gnb{background-position:0 -20px}
/* 광고계정선택상자 */
.layer_account{display:none;overflow:hidden;position:fixed;left:16px;top:auto;width:382px;margin-top:5px;border-radius:4px;background-color:#fff;box-shadow:0 0 8px 2px rgba(3,9,35,0.15);z-index:9999}
.cont_info .on .layer_account{display:block}
.layer_account .fake_scroll{overflow-y:auto;overflow-x:hidden;height:315px;margin-top:10px;box-sizing:border-box}
/* 디자인 스크롤 여백 */
.layer_account .search_account{padding:10px 16px 0}
.search_account .box_inptxt{padding-right:64px;border-radius:0}
.search_account .box_inptxt:hover,.search_account .box_inptxt.on{border-color:#326EDC}
.search_account .box_inptxt .lab_txt{font-size:14px;color:#919191}
.search_account .box_inptxt .inp_txt{color:#191919}
.search_account .search_inp .btn_search{position:absolute;top:0;left:auto;right:0;padding:9px 11px 9px 5px;outline:0 none}
.search_account .search_inp .ico_search{display:block;position:static;width:16px;height:16px;margin:0 auto;background-position:-20px -50px}
.search_account .search_inp .btn_del{/*display:none;*/ position:absolute;top:0;right:32px;width:26px;height:34px}
.search_account .search_inp .btn_del .ico_del{width:16px;height:16px;margin-top:1px;background-position:-625px -665px}
.layer_account .list_account{width:100%}
.layer_account .link_option{position:relative;display:block;padding:12px 50px 13px 16px;font-weight:400;font-size:13px;color:#191919;line-height:19px;text-decoration:none;letter-spacing:-0.03em}
.layer_account li:hover .link_option{background-color:#F4F9FF}
.layer_account li.on .link_option{background-color:#F4F9FF}
.layer_account li.on .link_option .txt_option{font-weight:700;color:#326EDC}
.layer_account li.on .link_option .txt_num{font-weight:400}
.layer_account .ico_on{width:8px;height:8px;margin:6px 6px 0 0}
.layer_account .ico_on:before{width:8px;height:8px}
.layer_account .ico_off{width:8px;height:8px;margin:6px 6px 0 0}
.layer_account .ico_off:before{width:8px;height:8px}
.layer_account .opt_state{position:absolute;top:14px;left:320px;font-size:0}
.layer_account .ico_badge{display:block;min-width:40px;max-width:49px;height:16px;padding:0 6px;font-size:10px;line-height:15px;color:#fff;font-weight:500;border-radius:8px;text-align:center;letter-spacing:0;box-sizing:border-box}
.layer_account .ico_master{background-color:#326EDC}
.layer_account .txt_num{display:inline-block;padding:2px 0 0 4px;font-size:12px;line-height:16px;color:#919191;vertical-align:top}
.layer_account .opt_tool{overflow:hidden;padding:15px 16px 14px 16px;font-size:0;background-color:#FAFAFA}
.layer_account .link_all{display:inline-block;font-weight:400;font-size:13px;line-height:19px;color:#191919;letter-spacing:-0.03em;vertical-align:top}
.layer_account .link_all:hover{font-weight:500}
.layer_account .link_create{float:right;font-size:13px;padding-top:1px;line-height:19px;color:#326EDC;letter-spacing:-0.03em}
.layer_account .link_create:hover{font-weight:500;text-decoration:none}
.layer_account .txt_nodata{padding-top:149px;font-size:13px;line-height:19px;color:#757B93;text-align:center}
/* footer */
.kakao_foot{position:relative;z-index:1;min-width:1080px;height:60px;border-top:1px solid #ddd;padding:19px 60px 0 60px;margin-top:-60px;z-index:10;font-family:'Noto Sans KR',sans-serif;box-sizing:border-box;text-align:center}
.kakao_foot:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.reform_guide_show .kakao_foot{padding:19px 280px 0 60px}
.reform_guide_show.lnb_on .kakao_foot{padding:19px 340px 0 60px}
.reform_guide_hide .kakao_foot{padding:19px 60px 0 60px}
.kakao_foot .inner_footer{display:inline-block;overflow:hidden;margin:0 auto;vertical-align:top}
/* width:470px;*/
.kakao_foot .link_copyright{float:left;font-size:12px;line-height:18px;font-family:'roboto',sans-serif;color:#191919}
/* float:left;*/
.kakao_foot .link_copyright strong{font-weight:700}
.kakao_foot .link_copyright:hover{text-decoration:underline}
.kakao_foot .list_policy{float:left;margin-left:34px}
/* float:left */
.kakao_foot .list_policy li{float:left}
.kakao_foot .list_policy li:before{float:left;width:1px;height:14px;margin:3px 16px 0;background-color:#ddd;content:''}
.kakao_foot .list_policy li:first-child:before{display:none}
.kakao_foot .link_policy{display:inline-block;font-size:12px;line-height:17px;color:#191919;vertical-align:top}
.kakao_foot .link_policy:hover{text-decoration:underline}
.kakao_foot li:last-child .link_policy,.kakao_foot li:last-child .link_policy:hover{font-weight:700;opacity:1}
.kakao_foot .btn_top{position:fixed;bottom:0;right:30px;width:32px;height:32px;background-color:rgba(0,0,0,0.4)}
.kakao_foot .btn_top:hover{background-color:rgba(0,0,0,0.5)}
.kakao_foot .btn_top .ico_top{width:14px;height:8px;margin:12px auto 0;background-position:-150px 0}/** 2023 디자인 가이드 변경 끝  **/
/*gnb 디자인 가이드 공통 */
/* 2023-12-20 gnb 개선 작업 시작 */
#kakaoHead h1{padding:23px 18px 0 24px}
#mFeature{z-index:100}
.gnb_comm{padding-left:0}
.sub_gnb{min-width:164px;z-index:100}
.list_gnb .link_gnb{padding:0 12px}
.list_gnb .on .link_gnb{color:#326EDC;font-weight:700}
.list_gnb li:nth-child(3) .sub_gnb{padding:10px 0}
.list_gnb li:nth-child(3) .sub_gnb .list_gnbsub li{float:left;width:100%}
.list_gnbsub li .link_sub{padding:0 16px}
.menu_my .link_gnb .thumb_account{display:inline-block;position:relative;width:20px;height:20px;margin:20px 6px 0 0;font-weight:700;font-size:10px;line-height:20px;color:#fff;text-align:center;vertical-align:top}
.menu_my .link_gnb .thumb_account:after{position:absolute;top:0;left:0;width:100%;height:100%;background:url("https://t1.daumcdn.net/biz/ui/keywordad/reform//frame_account2.png") no-repeat;background-size:100% 100%;z-index:1;content:''}
.list_relate .layer_mymenu{min-width:220px;z-index:100}
.menu_my .link_gnb{max-width:158px}
.layer_mymenu .list_service{padding:10px 0}
.layer_mymenu .list_service .link_sub{position:relative;padding:0 16px 0 58px}
.layer_mymenu .list_service + .list_service{padding:10px 0}
.list_relate .link_gnb{padding:0 8px}
.list_relate .menu_my{margin-left:12px}
.menu_my .link_gnb{padding:0 27px 0 0}
.layer_mymenu .list_service .ico_comm{position:absolute;top:50%;left:24px;transform:translateY(-50%);width:18px;height:18px}
.layer_mymenu .list_service .ico_myinfo{background-position:-700px -685px}
.layer_mymenu .list_service .ico_myaccount{background-position:-720px -685px}
.layer_mymenu .list_service .ico_guide{background-position:-740px -685px}
.layer_mymenu .list_service .ico_logout{background-position:-760px -685px}
.layer_mymenu .wrap_account{padding:18px 16px}
.layer_mymenu .wrap_account .thumb_account{float:left;position:relative;width:34px;height:34px;margin-right:8px;text-align:center}
.thumb_account .txt_account.account_type2{font-family:'Courier New',Courier,monospace,sans-serif}
.layer_mymenu .wrap_account .thumb_account .txt_account{font-weight:700;font-size:12px;line-height:34px;color:#fff}
.layer_mymenu .wrap_account .tit_account{display:block;overflow:hidden;max-width:146px;padding-top:1px;font-weight:700;font-size:14px;line-height:14px;color:#191919;white-space:nowrap;text-overflow:ellipsis}
.layer_mymenu .wrap_account .desc_account{display:block;overflow:hidden;max-width:146px;padding-top:6px;font-size:12px;line-height:12px;font-family:'Roboto',sans-serif;color:#666;white-space:nowrap;text-overflow:ellipsis}
.layer_mymenu .wrap_account .thumb_account:after{position:absolute;top:0;left:0;width:100%;height:100%;background:url("https://t1.daumcdn.net/biz/ui/keywordad/reform//frame_account.png") 0 0 no-repeat;background-size:100% 100%;z-index:1;content:''}
.account_info .layer_account .paging_wrap2{margin:0 9px 0 -6px}
.layer_account .link_all{text-decoration:underline}
/* //2023-12-20 gnb 개선 작업 끝 */
.keywordad_content{min-height:100%}
.account_info .item_info .txt_state{overflow:hidden;max-width:132px;text-overflow:ellipsis;white-space:nowrap}
/*새로운 알림*/
.cont_snb .list_snb .ico_new{position:absolute;top:18px;left:10px;overflow:hidden;width:5px;height:5px;border-radius:50%;font-size:0;line-height:0;background:#E85E36;text-indent:-9999px}
.cont_snb .sub_snb .ico_new{top:10px;left:39px}
.layer_account .search_account .box_inptxt{border-color:#ddd}
.layer_account .search_account .box_inptxt:hover,.layer_account .search_account .box_inptxt.on{border-color:#326EDC}
.search_account .search_inp .btn_del .ico_del{background-position:-750px -175px}
/* lnb 사용자 정보 토글버튼 - 체크박스(스위치형) */
.account_info .ad_info .switch_check{display:inline-block;position:relative;width:31px;height:16px;vertical-align:top;text-align:center}
.account_info .ad_info .switch_check .inp_check{position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.01;filter:alpha(opacity=0)}
.account_info .ad_info .switch_check .lab_check{display:block;position:relative;height:16px;border-radius:9px;background-color:#ccc;cursor:pointer}
.account_info .ad_info .switch_check .ico_switch{overflow:hidden;position:absolute;left:1.5px;top:50%;width:13px;height:13px;margin:0;border-radius:16px;font-size:0;line-height:0;background-color:#fff;text-indent:-9999px;transform:translateY(-50%)}
.account_info .ad_info .switch_check.on .lab_check{border-color:#326EDC;background-color:#326EDC}
.account_info .ad_info .switch_check .inp_check:checked + .lab_check{border-color:#326EDC;background-color:#326EDC}
.account_info .ad_info .switch_check.on .ico_switch{left:auto;right:1.5px}
.account_info .ad_info .switch_check .inp_check:checked + .lab_check .ico_switch{left:auto;right:1.5px}
.account_info .ad_info .switch_check.in_active .lab_check{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
.account_info .ad_info .switch_check .inp_check:disabled + .lab_check{opacity:0.4;filter:alpha(opacity=40);cursor:default;pointer-events:none}
html,body{height:100%;background-color:#eee}
#app{height:100%}
#kakaoWrap{position:relative;min-width:1140px;height:100%}
#kakaoWrap.login_type{min-width:1280px;min-height:800px}
#kakaoWrap.layout_type1{min-width:1040px;margin:0 0 0 240px}/** 왼쪽만있는경우 */
#kakaoWrap.layout_type1 #mFeature{left:0}
#kakaoWrap.layout_type2{min-width:1040px}/** 오른쪽만있는경우 */
#kakaoWrap.layout_type3{min-width:1040px;margin:0 0 0 240px}/** 양쪽있는경우 */
#kakaoWrap.layout_type3 #mFeature{left:0}
#kakaoMain{min-height:100%}
#kakaoWrap.lnb_on{margin:0 0 0 200px}/** 대시보드 */
/* 공통 - 레이어 */
.layer_on{overflow:hidden}
.layer_on #kakaoWrap{z-index:1}
.layer_dimmed{z-index:990 !important}
.gnb_type1 #mArticle{padding-top:32px;box-sizing:border-box}
.gnb_type1 #mFeature{top:32px}
#kakaoContent{position:relative;min-height:100%}
#mArticle{min-height:auto;padding:60px 15px 73px 76px}
.layout_fix #mArticle{width:980px;margin:0 auto}
.layout_type2 #mArticle,.layout_type3 #mArticle{width:730px;padding-right:300px;margin:0 auto}
.sub_on #mArticle{padding-top:129px}
/* 디자인스크롤 */
.fake_scroll{overflow:auto;position:relative}
.list_userinfo .fake_scroll{-ms-overflow-style:none}/** 사용하는 곳 확인 후 추가 */
.list_userinfo .fake_scroll::-webkit-scrollbar{display:none}/** 사용하는 곳 확인 후 추가 */
.scroll{position:absolute;right:1px;top:0;z-index:10;width:4px;margin:1px 0;border-radius:8px;background-color:rgba(0,0,0,0.2)}
#kakaoWrap,.layer_wrap{scrollbar-width:5px;scrollbar-color:#d9d9d9;/*파폭*/ scrollbar-face-color:#d9d9d9;scrollbar-track-color:#fff;scrollbar-arrow-color:#d9d9d9;scrollbar-highlight-color:#e0e0e0;scrollbar-3dlight-color:none;scrollbar-shadow-color:#d9d9d9;scrollbar-darkshadow-color:none}
#kakaoWrap ::-webkit-scrollbar{width:5px;height:5px}
#kakaoWrap ::-webkit-scrollbar-track{background-color:transparent}
#kakaoWrap ::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,0.15);border-radius:10px}
#kakaoWrap ::-webkit-scrollbar-thumb:hover{background-color:rgba(0,0,0,0.15)}
#kakaoWrap ::-webkit-scrollbar-button{width:5px;height:5px;background:transparent}
.layer_wrap ::-webkit-scrollbar{width:5px;height:5px}
.layer_wrap ::-webkit-scrollbar-track{background-color:transparent}
.layer_wrap ::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,0.15);border-radius:10px}
.layer_wrap ::-webkit-scrollbar-thumb:hover{background-color:rgba(0,0,0,0.15)}
.layer_wrap ::-webkit-scrollbar-button{width:5px;height:5px;background:transparent}
/* 사이드 메뉴 */
/*#mFeature{position:fixed;left:0;top:60px;bottom:0;z-index:100;width:239px;border-right:1px solid #ddd;background-color:#fff}#mFeature .btn_opcl{position:absolute;top:23px;left:240px;width:15px;height:50px;border:1px solid #ddd;border-left:none;border-radius:0 4px 4px 0;background:#fafafa}#mFeature .btn_opcl:hover{background:#f5f5f5}#mFeature .btn_opcl .ico_comm{width:6px;height:10px;margin:0 0 0 4px;background-position:-885px -10px}#mFeature .btn_opcl:hover .ico_comm{background-position:-885px 0}.lnb_on #mFeature{left:200px}.lnb_on #mFeature .btn_opcl .ico_comm{border-left:0 none;background-position:-875px -10px}.lnb_on #mFeature .btn_opcl:hover .ico_comm{background-position:-875px 0}*/
.paging_wrap2{display:inline-block;font-size:0;vertical-align:top}
.paging_wrap2 .inner_paging{overflow:hidden;margin:0 8px;font-weight:500;font-size:14px;line-height:20px;font-family:'Roboto',sans-serif;color:#777}
.paging_wrap2 .inner_paging .num_current{font-weight:500}
.paging_wrap2 .ico_prev{height:12px;width:7px;margin:4px 7px 4px 6px;background-position:-820px -670px}
.paging_wrap2 .ico_next{height:12px;width:7px;margin:4px 6px 4px 7px;background-position:-830px -670px}
.paging_wrap2 span.num_paging .ico_prev{background-position:-840px -670px}
.paging_wrap2 span.num_paging .ico_next{background-position:-850px -670px}
.paging_wrap2 .num_prev{float:left}
.paging_wrap2 .num_next{float:right}
.layer_account .paging_wrap2 .inner_paging{font-weight:400;color:#919191}
.layer_account .paging_wrap2 .num_current{margin-right:3px;font-weight:400;color:#191919}
.adnotify_managebox .paging_wrap2{position:absolute;left:50%;bottom:29px;transform:translateX(-50%)}
.settingmyinfo_wrap .search_account2{position:relative;width:382px}
.search_account2 .box_inptxt{padding-right:64px;border-radius:0}
.search_account2 .box_inptxt:hover,.search_account2 .box_inptxt.on{border-color:#326EDC}
.search_account2 .box_inptxt .lab_txt{font-size:14px;color:#919191}
.search_account2 .box_inptxt .inp_txt{color:#191919}
.search_account2 .search_inp .btn_search{position:absolute;top:0;left:auto;right:0;padding:9px 11px 9px 5px;outline:0 none}
.search_account2 .search_inp .ico_search{display:block;position:static;width:16px;height:16px;margin:0 auto;background-position:-740px -630px}
.search_account2 .search_inp .btn_del{/*display:none;*/ position:absolute;top:0;right:32px;width:26px;height:34px}
.search_account2 .search_inp .btn_del .ico_del{width:16px;height:16px;margin-top:2px;background-position:-870px -645px}
.search_account2 .layer_account{position:absolute;left:0;margin-top:10px}
.search_account2 .layer_account .fake_scroll{height:308px}
.search_account2 .layer_account .paging_wrap2{position:relative;left:50%;transform:translateX(-50%)}
/* lnb - 캠페인관리(대쉬보드) */
.feature_campaign{position:relative;height:100%}
.feature_campaign .search_inp .lab_txt{font-size:12px}
.feature_campaign .tit_campaign{display:block;height:30px;padding-left:20px;font-size:12px;font-weight:500;color:#555}
.feature_campaign .tit_campaign .link_tit:hover{text-decoration:none}
.feature_campaign .tit_campaign .link_campaign{display:block;overflow:hidden;position:relative;height:39px;padding:0 50px 0 20px;font-size:13px;line-height:39px;color:#111;text-decoration:none}
.feature_campaign .wrap_nav .nav_campaign{display:none}
.feature_campaign .wrap_nav:last-of-type{border-top:1px solid #ddd}
.feature_campaign .nav_open .nav_campaign{display:block}
.feature_campaign .btn_fold{position:absolute;top:50%;left:202px;width:25px;height:25px;margin-top:-11px}
.feature_campaign .btn_fold .ico_comm{width:10px;height:5px;margin:0 auto;background-position:-870px -170px;opacity:0.2}
.feature_campaign .btn_fold:hover .ico_comm{opacity:0.5}
.feature_campaign .on .btn_fold .ico_comm{background-position:-880px -170px}
.feature_campaign .wrap_select .opt_select .link_selected{cursor:pointer}
.feature_campaign .wrap_nav{position:relative;padding:0 3px 0 0}/** 디자인 스크롤 여백 */
.adsearch_wrap{width:200px;margin:0 auto;padding:20px 0}
.adsearch_wrap .btn_gb{width:198px}
.adsearch_wrap .search_inp{margin-top:10px;padding-left:16px}
.adsearch_wrap .search_inp .lab_txt{left:16px}
.nav_campaign{overflow:hidden;overflow-y:auto;font-size:13px;background-color:#fff;box-sizing:border-box}
.nav_campaign li{position:relative;border-bottom:1px solid #ebebeb}
.nav_campaign li:last-child{border-bottom:none}
.nav_campaign .nav_list{overflow:hidden}
.nav_campaign .main_nav{display:block;position:relative;text-decoration:none;color:#555}
.nav_campaign .link_nav{display:block;height:100%;padding:17px 30px 8px 30px;color:#333}
.nav_campaign .link_nav.fw_bold2,.nav_campaign .link_subnav.fw_bold2{font-weight:500}
.nav_campaign .link_nav.on,.nav_campaign .link_nav.fc_empty,.nav_campaign .link_subnav.fc_empty,.nav_campaign .link_nav.fc_empty:hover,.nav_campaign .link_subnav.fc_empty:hover{background:#f1f6ff}/** on 과 fc_empty 동일한 기능*/
.nav_campaign .main_nav:hover .link_nav.on,.nav_campaign .main_nav:hover .link_nav.fc_empty{background:#f1f6ff}
.nav_campaign .link_nav.on,.nav_campaign .link_nav.fc_empty,.nav_campaign .link_subnav.fc_empty{color:#326edc;font-weight:500}
.nav_campaign .inner_link{display:inline-block;overflow:hidden;max-width:100%;padding:0 0 8px;line-height:19px;vertical-align:top}
.nav_campaign .subtit_word{display:block;font-size:11px;line-height:16px;color:#777}
.nav_campaign .subtit_word .ico_comm{display:inline-block;width:8px;height:8px;margin:4px 2px 0 4px;background-position:-790px -215px}
.nav_campaign:hover .subtit_word{text-decoration:none}
.nav_campaign .txt_word{display:block;padding-top:0}
.nav_campaign .txt_num{margin-left:5px}
.nav_campaign .subnav_campaign{display:none;padding-bottom:22px}
.nav_campaign .subnav_campaign li{border:0 none}
.nav_campaign .subnav_campaign .ico_nav{left:30px}
.nav_campaign .link_subnav{display:block;overflow:hidden;padding:7px 33px 7px 40px;font-size:12px;line-height:16px;text-decoration:none;color:#555}
.nav_campaign .main_nav:hover .link_nav{background:rgba(0,0,0,0.03)}
.nav_campaign .link_nav:hover,.nav_campaign .link_subnav:hover{background:rgba(0,0,0,0.03);text-decoration:none}
.nav_campaign .link_nav:hover .nav_tooltip,.nav_campaign .link_subnav:hover .nav_tooltip{display:block}
.nav_campaign .link_nav:hover .txt_word .txt_num{text-decoration:none}
.nav_campaign li.on{background-color:#fff}
.nav_campaign .on .subnav_campaign{display:block}
.navcampaign_msg .main_nav{padding:0}
.navcampaign_msg .link_nav{padding-left:20px}
.btn_gm:hover .ico_del{background-position:-870px -630px}
/* basic layer4 (하단버튼 있음 / 좌측정렬 / 타이틀 텍스트 12px / 대시보드에서 사용) */
.basic_layer4{position:absolute;left:50%;top:50%;z-index:999}
.basic_layer4.layer_inptype .inner_basic_layer{width:auto;min-width:268px}
.basic_layer4 .tit_layer{display:block;padding-top:16px;font-weight:700;font-size:12px;line-height:18px;color:#111}
.basic_layer4 .con_layer{padding-top:12px}
.basic_layer4 .btn_group{display:block;padding-top:15px;text-align:right}
.basic_layer4 .btn_group .btn_gm{margin-left:10px}
.basic_layer4 .btn_group .btn_gm:first-child{margin-left:0}
.basic_layer4 .btn_group{display:block;padding-top:15px;text-align:right}
.basic_layer4 .btn_group .btn_gm{margin-left:10px}
.basic_layer4 .btn_group .btn_gm:first-child{margin-left:0}
.set_table .btn_gm:hover{border-color:rgba(0,0,0,0.08)}
.set_table .btn_gm.in_active{border-color:#ddd;color:#ccc;background:#f5f5f5;cursor:default;pointer-events:none}
.txt_checkinp_url{display:block;overflow:hidden;padding-left:28px;font-size:10px;line-height:15px;color:#999;white-space:nowrap;text-overflow:ellipsis}
.tooltip_basic .txt_tooltip3{margin-top:11px;font-size:13px;line-height:19px;color:#333}
.basic_layer4.layer_campaign_biz .inner_basic_layer{width:284px;padding:0 0 16px 16px;box-shadow:0 2px 10px rgba(0,0,0,0.25)}
.basic_layer4.layer_campaign_biz .con_layer{overflow-y:auto;max-height:380px}
.basic_layer4.layer_campaign_biz .btn_group{padding-right:16px}
.basic_layer4.layer_campaign_biz .con_layer .list_layer{padding-right:19px}
/* 공통 - 로딩 */
.reform_area_loading{position:relative;display:table;width:100%;height:100%;line-height:8px;text-align:center;vertical-align:middle}
.reform_area_loading .group_loading{display:table-cell;width:100%;height:100%;text-align:center;vertical-align:middle}
.reform_area_loading .ico_loading{display:inline-block;width:8px;height:8px;margin:2px;border-radius:100%;background-color:#326edc}
.reform_area_loading .loading_size2{line-height:2px}
.reform_area_loading .loading_size3{line-height:12px}
.reform_area_loading .loading_size2 .ico_loading{width:4px;height:4px;margin:0}
/* 작은 로딩 (버튼,작은공간 사용) */
.reform_area_loading .loading_size3 .ico_loading{width:12px;height:12px;margin:1px}
/* 큰 로딩 (전체 로딩시) */
.reform_area_loading .ani_loading{animation:0.6s ease-in-out 0.07s infinite normal both running animation-10yals0}
.reform_area_loading .ani_loading2{animation:0.6s ease-in-out 0.14s infinite normal both running animation-10yals0}
.reform_area_loading .ani_loading3{animation:0.6s ease-in-out 0.21s infinite normal both running animation-10yals0}
.reform_area_loading .group_loading .txt_loading{padding-top:25px;line-height:22px;font-size:14px;color:#555}
.reform_area_loading .group_loading .subtxt_loading{padding-top:0;line-height:22px;font-size:12px;color:#777}
.optlist_row .list_optrow{float:left;position:relative;width:127px;padding-top:12px;box-sizing:border-box}
.optlist_row .list_optrow li + li{margin-top:2px}
.optlist_row .list_optrow .link_option{padding:0 14px}
.optlist_row .sub_listopt .user_opt .link_option{height:32px;padding:0 15px;line-height:32px}
.optlist_row .optrow_cell .list_opt.sub_listopt li + li{margin-top:1px}
/* 캠페인 id 팝업 */
.basic_layer4.layer_campaign_id .inner_basic_layer{min-width:280px;box-shadow:0 2px 10px rgba(0,0,0,0.25)}
.basic_layer4.layer_campaign_id .txt_error{margin-top:8px}
.set_head .optlist_row .optrow_wrap{display:table;position:relative;float:inherit;width:401px;border-left:1px solid #e8e8e8;background:#fff}
.set_head .optlist_row .optrow_cell{display:table-cell;position:relative;float:inherit;width:224px}
.set_head .opt_right .optlist_row{left:auto;right:-1px;background:#FAFAFA}
.set_head .optlist_row .scroll_listopt{overflow-y:auto;height:303px;margin-bottom:13px}
.set_head .optlist_row .scroll_listopt .desc_nodata{padding-top:130px;font-size:13px;line-height:20px;color:#aaa;text-align:center}
.set_head .optlist_row .btn_colset{width:193px;margin:0 0 16px 16px}
.set_head .optlist_row .optrow_wrap:before{display:none;position:absolute;left:180px;top:0;width:1px;height:100%;background-color:#ebebeb;content:''}
.wrap_select .list_opt .link_option{display:block;height:24px;padding:0 15px;font-size:12px;line-height:24px;color:#555;text-align:left;text-decoration:none;letter-spacing:0}
/* 오디언스_상세 */
.audience_detail{position:relative;padding:20px 20px 30px;border-top:1px solid #ddd}
.audience_detail .tit_sub{display:block;margin-bottom:15px;font-weight:500;color:#111}
.audience_detail .wrap_tblbtn + .tit_sub{padding-top:30px}
.audience_detail .tbl_g3 + .tit_sub,.audience_detail .tblg2_wrap + .tit_sub{margin-top:30px}
.audience_detail .tbl_g3 th{vertical-align:middle}
.audience_detail .tbl_g3 th .inner_tbl{height:55px;line-height:55px;padding-left:20px}
.audience_detail .tbl_g3 th .fc_empty{font-weight:500}
.audience_detail .tbl_g3 td{color:#555}
.audience_detail .tbl_g3 td .inner_tbl{padding:16px 20px 16px}
.audience_detail .tbl_g3.tbl_demo th .inner_tbl{height:47px;line-height:47px;padding-left:20px}
.audience_detail .tbl_g3.tbl_demo td .inner_tbl{padding-top:12px;padding-bottom:12px}
.audience_detail .message_cate{overflow:hidden}
.audience_detail .message_cate + .message_cate{margin-top:10px}
.wrap_tblbtn .btn_tblmore{position:relative;display:inline-block;padding-right:20px;font-size:13px;line-height:50px;color:#333}
.wrap_tblbtn .btn_tblmore .ico_arr{position:absolute;top:50%;right:0;width:13px;height:9px;margin-top:-4px;background-position:-815px -100px}
.wrap_tblbtn .btn_tblmore.on .ico_arr{background-position:-815px -90px}
/*광고계정 만들기*/
.accountcreat_wrap{min-width:1180px;max-width:1320px;width:100%;margin:0 auto}
.accountcreat_wrap .dsp_tit + .list_append{margin:-2px 0 20px;padding-top:15px;border-top:1px solid rgba(0,0,0,0.05)}
.accountcreat_wrap .ad_managebox + .ad_managebox{margin-top:20px}
.accountcreat_wrap .area_subtit{overflow:hidden;padding-top:5px;margin-bottom:-5px}
.accountcreat_wrap .area_subtit + .tbl_g3{margin-top:30px}
.accountcreat_wrap .area_subtit .tit_sub{float:left;width:195px;margin-bottom:0;line-height:20px}
.accountcreat_wrap .audience_detail{padding-left:24px;padding-right:24px}
.accountcreat_wrap .audience_detail .item_card{float:left;width:800px}
.accountcreat_wrap .item_card .box_inptxt{float:left;width:331px}
.accountcreat_wrap .item_card .box_inptxt.inp_wd1{width:42px}
.accountcreat_wrap .item_card .box_inptxt.inp_wd2{width:72px}
.accountcreat_wrap .item_card .inp_wd1 .inp_txt,.accountcreat_wrap .item_card .inp_wd2 .inp_txt{text-align:center}
.accountcreat_wrap .item_card .txt_inptxt{float:left;width:27px;line-height:36px;text-align:center}
.accountcreat_wrap .item_card .box_inptxt + .btn_gm{float:left;margin-left:10px}
.accountcreat_wrap .item_card .box_checkinp{float:left;margin:8px 0 0 30px}
.accountcreat_wrap .item_card .lab_check{font-size:13px}
.accountcreat_wrap .item_card .txt_error{clear:both;padding-top:10px;font-size:12px}
.accountcreat_wrap .item_card .txt_error .fc_gray{color:#999}
.accountcreat_wrap .item_card .desc_g{font-size:14px;color:#333}
.accountcreat_wrap .item_card .box_accountcheck{margin-top:-8px}
.accountcreat_wrap .item_card .txt_error + .box_accountcheck,.accountcreat_wrap .item_card .desc_g + .box_accountcheck{margin-top:10px}
.accountcreat_wrap .item_card .btn_gm + .box_accountcheck{margin-top:12px}
.accountcreat_wrap .item_card .box_accountcheck .box_checkinp{margin-left:0}
.accountcreat_wrap .item_card .box_account{clear:both;overflow:hidden;width:634px;padding-top:10px}
.accountcreat_wrap .box_account .inner_account{padding:19px 10px 19px 20px;font-size:12px;line-height:18px;color:#555;background:#F5F5F5}
.accountcreat_wrap .box_account .list_account li + li{padding-top:1px}
.accountcreat_wrap .box_account .list_account + .desc_account{padding-top:17px}
.accountcreat_wrap .audience_detail .tbl_g3 th .inner_tbl{padding:0 0 0 20px;height:76px;line-height:76px}
.accountcreat_wrap .audience_detail .tbl_g3 td .inner_tbl{line-height:19px;padding:19px 20px}
.accountcreat_wrap .wrap_accountguide{padding-top:20px;margin-bottom:-42px}
.accountcreat_wrap .wrap_accountguide.on{margin-bottom:0}
.accountcreat_wrap .wrap_accountguide .link_guide{position:relative;padding-left:16px;font-size:13px;line-height:20px;font-weight:500;color:#333;text-decoration:none}
.accountcreat_wrap .wrap_accountguide .link_guide .ico_arrow{position:absolute;left:0;top:50%;width:0;height:0;margin-top:-3px;border-left:5px solid transparent;border-right:5px solid transparent;border-top:6px solid #333;vertical-align:middle}
.accountcreat_wrap .wrap_accountguide.on .link_guide .ico_arrow{border-bottom:6px solid #333;border-top:0 none}
.accountcreat_wrap .wrap_accountguide .desc_subtxt{font-size:13px;color:#777}
.accountcreat_wrap .wrap_accountguide .desc_subtxt .fc_emph{font-weight:700}
.accountcreat_wrap .area_accountguide{margin-top:15px;padding:19px 20px 0;border:1px solid #ddd;background:#fff}
.accountcreat_wrap .area_accountguide .desc_guide{padding-bottom:20px;font-size:13px;line-height:22px;color:#777}
.accountcreat_wrap .item_card .inp_wd1 .lab_txt,.accountcreat_wrap .item_card .inp_wd2 .lab_txt{right:15px;margin-top:-9px;text-align:center}
.accountcreat_wrap .tbl_g3 th{width:160px;font-size:13px;color:#555;background:#f5f5f5}
.basic_layer .inner_basic_layer1{width:650px}
.tooltip_basic.tooltip_myaccount .list_tooltip{padding-top:2px}
.tooltip_myaccount .list_tooltip + .tit_tooltip{margin-top:16px}
.managebox_tit .txt_sub{float:left;height:34px;font-size:12px;line-height:36px;color:#999999}
/*광고계정 만들기 > 광고그룹 생성 완료*/
.accountend_wrap .audience_detail:first-child{border-top:none}
.accountend_wrap .audience_detail .desc_subtxt{display:inline-block;padding-left:6px;font-size:12px;color:#999}
.accountend_wrap .page_btn .f_left{text-align:left}
.accountend_wrap .desc_accountend{font-size:12px;color:#999;line-height:20px}
.accountend_wrap .link_accountend{font-size:12px;color:#326EDC;line-height:20px}
/* 본인 개인정보 확인 레이어 */
.check_layer .layer_body{padding:30px 30px}
.check_layer .tit_accdel{margin:5px 0 25px;font-weight:500;font-size:18px;color:#111;text-align:center}
.check_layer .subtit_check{display:block;margin:26px 0 6px;font-weight:500;font-size:14px;color:#111}
.check_layer .tit_accdel + .subtit_check{margin-top:31px}
.check_layer .txt_accdel{margin:16px 0 -4px;font-size:14px;color:#555}
.check_layer .list_accdel{padding:13px 20px 14px;border:1px solid #e5e5e5}
.check_layer .list_accdel li{padding-left:9px;text-indent:-9px;font-size:14px;line-height:26px;color:#555}
.check_layer .fld_accdel{margin:27px 0 -2px;text-align:center}
.check_layer .box_checkinp .lab_check{font-weight:500;color:#111}
.check_layer .list_guide{margin:24px 0 -5px;padding:0;border:0}
.check_layer .list_guide li{padding-left:12px;line-height:24px}
.check_layer .list_guide li + li{margin-top:6px}
/*(팝업)사업자등록번호 신규 등록 */
.register_layer .inner_basic_layer{width:720px}
.register_layer .layer_body{padding:22px 30px 30px 30px}
.register_layer .wrap_tbl{padding-top:0}
.register_layer .group_inp{overflow:hidden}
.register_layer .box_inptxt{margin-bottom:10px}
.register_layer .tbl_layer th{padding-left:20px}/*** mac 분기 스타일 ***/
/* layout */
.os_mac .kakao_foot .link_copyright{line-height:22px}
.os_mac .kakao_foot .list_policy li{line-height:22px}
/* 공통 */
.os_mac .ico_set{margin-top:5px}
/* 공통 - 타이틀 */
.os_mac .tit_dsp .sub_title:before{top:7px}
/* 공통 - 버튼 */
.os_mac .btn_gm .inner_g{padding-top:7px;padding-bottom:8px}
.os_mac .btn_gm .icon_g{padding:0}
.os_mac .btn_gm .ico_add{margin-top:4px}
.os_mac .btn_gm .ico_download2{margin-top:-1px}
.os_mac .basic_layer .btn_gs .inner_g{padding-top:8px;padding-bottom:9px}
.os_mac .preview_layer .view_tit .control_btn .btn_gs .inner_g{padding:0}
.os_mac .btn_gs .icon_g{padding:0}
.os_mac .btn_gs .ico_add{margin-top:4px}
/* 달력버튼 */
.os_mac .gm_calendar .link_calendar{line-height:33px}
/* 광고그룹생성 - 요일선택 레이어 */
.os_mac .calendar_layer .detailtool_wrap dt{line-height:37px}
.os_mac .calendar_layer .detailtool_wrap .list_day .box_checkinp{line-height:37px}
/* 토글버튼 - 라디오(기본형) */
.os_mac .toggle_radio2 .lab_radio{line-height:26px}
/* 체크박스(선택형) */
.os_mac .choice_item .lab_choice{line-height:34px}
.os_mac .choice_item2 .lab_choice .link_txt{letter-spacing:0}
/* 체크박스(버튼형) */
.os_mac .toggle_check .lab_check{line-height:26px}
.os_mac .toggle_check .ico_on{margin-top:9px}
/* 드롭다운 - 셀렉트박스 */
.os_mac .opt_select .link_selected{padding-top:6px;padding-bottom:7px}
.os_mac .opt_select .ico_on{margin-top:5px}
/*.os_mac .opt_select .box_checkinp{line-height:33px}*/
.os_mac .opt_select .link_rowset{line-height:40px}
/* 드롭다운 - 셀렉트박스(프로필형) */
.os_mac .box_urlplus .opt_select .link_selected{padding-top:5px;padding-bottom:5px}
/* 셀렉트박스_작은사이즈 */
.os_mac .opt_select.opt_small .link_selected{padding-top:3px;padding-bottom:2px}
.os_mac .opt_select.opt_small .link_option{line-height:21px}
/* 좌측메뉴 */
.os_mac .wrap_select .opt_select .link_selected{padding-top:0}
.os_mac .wrap_select .opt_select .opt_list{top:20px}
.os_mac .wrap_select .opt_select .ico_arr{margin-top:-8px}
/*.os_mac .wrap_select .opt_select .opt_list .ico_sort{top:10px}*/
.os_mac .wrap_select .txt_bar{margin-top:8px}
.os_mac .wrap_select .link_selected .ico_sort{margin-top:-8px}
.os_mac .wrap_select .link_selected .ico_sort,.os_mac .sortable .desc_sort .ico_sort{margin-top:5px}
.os_mac .ico_nav{top:24px}
.os_mac .subnav_campaign li .ico_nav{top:12px}
/* 공통 - onoff 셀렉트박스 */
.os_mac .onoff_select .link_option{line-height:26px}
/* 드롭다운 - 셀렉트박스 - 체크박스 */
.os_mac .check_select .link_selected{padding:9px 26px 6px 15px}
/* 공통 input-text */
.os_mac .box_inptxt .txt_won{line-height:35px}
.os_mac .box_inptxt .num_byte{padding-top:1px;line-height:20px}
/* 검색 input-text - 버튼이 붙은 type */
.os_mac .box_search .box_inptxt{padding-top:12px;padding-bottom:10px}
/* 공통 - 페이징 */
.os_mac .paging_wrap .num_paging{line-height:35px}
/* 공통 - 페이징 작은사이즈 */
.os_mac .paging_wrap.paging_s .num_paging{line-height:26px}
/* gnb */
/*.os_mac .gnb_comm .link_gnb{line-height:50px}
.os_mac .gnb_comm .link_gnb:after{margin-top:-4px}
.os_mac .gnbsub_comm .link_sub{line-height:35px}*/
/* 사용자메뉴 */
.os_mac .list_userinfo .menu_my .link_gnb:after{margin-top:-2px}
.os_mac .list_userinfo .menu_landing .link_gnb{letter-spacing:0}
.os_mac .list_userinfo .link_gnb .ico_new{margin-top:8px}
.os_mac .gnbsub_userinfo .list_gnbsub .txt_detail{letter-spacing:0}
/* 광고계정선택상자 */
.os_mac .select_account{width:330px}
.os_mac .select_account .link_selected{padding:10px 30px 12px 20px;line-height:28px}
.os_mac .select_account .ico_on,.os_mac .select_account .ico_off{margin-top:-3px}
.os_mac .select_account .link_option{line-height:18px}
.os_mac .select_account .link_all{line-height:26px}
.os_mac .select_account .btn_gs .ico_add{margin-top:7px}
.os_mac .select_account .ico_master{right:7px}
/* 공통 - 테이블 */
.os_mac .tbl_g .inner_tbl{padding-top:7px;padding-bottom:6px}
.os_mac .tbl_g .ad_state.btn_state{padding-right:55px}
.os_mac .tbl_g .ad_state .btn_gs .inner_g{padding-right:0;line-height:15px}
.os_mac .tbl_g .link_help{margin-top:-1px}
.os_mac th .link_g .ico_help{margin-top:5px}
.os_mac .td_material .inner_tbl{padding:0}
.os_mac .td_url .inner_tbl{padding:15px 10px 15px}
/* 공통 - 테이블2 */
.os_mac .tbl_g2 .inner_tbl,.os_mac .ad_managebox .itemtbl_wrap .inner_tbl{padding-top:13px;padding-bottom:12px}
.os_mac .tbl_g2 th .link_g{margin-top:1px}
.os_mac .tbl_g2 .wrap_btn{margin-top:0;margin-bottom:-2px;vertical-align:middle}
/* 테이블내 스몰버튼 */
.os_mac .tbl_g .btn_gs,.os_mac .tbl_g2 .btn_gs,.os_mac .tbl_g3 .btn_gs{letter-spacing:0}
/* lnb - 캠페인관리(대쉬보드) */
.os_mac .nav_campaign .btn_fold:before{margin-top:-1px}
/* lnb - 캠페인관리(대쉬보드) :서제스트 레이어 */
.os_mac .suggest_layer .list_suggest .suggest_item{line-height:18px}
.os_mac .suggest_layer .link_type .ico_type{line-height:15px}
.os_mac .suggest_layer .link_type .ico_type{margin-top:2px}
/* lnb - 광고만들기(캠페인) */
.os_mac .step_manage .tit_step .link_step{line-height:22px}
/* tooltip layer */
.os_mac .tooltip_layer .inner_tooltip_layer{padding:20px 40px 20px 71px}
/* 안내메시지 */
.os_mac .guide_msg{padding-top:13px;padding-bottom:12px}
.os_mac .guide_msg2{line-height:50px}
.os_mac .guide_msg3{line-height:44px}
/* basic layer */
.os_mac .basic_layer .tit_layer{line-height:60px}
.os_mac .basic_layer .txt_layer{padding-top:26px;padding-bottom:21px}
.os_mac .basic_layer .layer_top .txt_top{line-height:38px}
.os_mac .basic_layer .modify_cause .inner_cause{padding-top:17px;padding-bottom:14px}
/* 일예산수정 레이어 */
.os_mac .tooltip_budget .box_inptxt{padding-top:7px;padding-bottom:7px}
.os_mac .tooltip_budget .box_checkinp .lab_check{line-height:18px}
/* 공통 - 테이블 */
/*.os_mac .tbl_g3 td .inner_tbl{padding-top:25px;padding-bottom:23px}*/
.os_mac .tbl_g3 .tr_box th .inner_tbl{line-height:98px}
.os_mac .tbl_g3 .tr_box2 th .inner_tbl{padding-top:27px;padding-bottom:24px;line-height:inherit}
.os_mac .tbl_g3 .tr_box2 td .inner_tbl{padding-top:30px;padding-bottom:30px}
.os_mac .tbl_g3 .wrap_selinp{margin-top:0;margin-bottom:3px;line-height:24px}
.os_mac .tbl_g3 .wrap_selinp + .group_inp{margin-bottom:6px}
.os_mac .tbl_g3 .wrap_selinp + .limit_cell{margin-bottom:6px}
.os_mac .tbl_g3 .tr_box .wrap_selinp{margin-bottom:0}
.os_mac .tbl_g3 .tr_box2 .wrap_selinp{margin:-3px 0}
.os_mac .tbl_g3 .tr_box3 td .inner_tbl{padding-top:25px;padding-bottom:22px}
.os_mac .tbl_g3.tbl_g3_type2 th .inner_tbl{line-height:54px}
.os_mac .tbl_g3.tbl_g3_type2 td .inner_tbl{padding-top:16px;padding-bottom:15px}
.os_mac .tbl_g3.tbl_g3_type2 .tr_box th .inner_tbl{line-height:76px}
.os_mac .tbl_g3.tbl_g3_type2 .tr_box td .inner_tbl{padding-top:20px;padding-bottom:20px}
/* 공통 - 대시보드 */
.os_mac .info_navigation .ico_arrow{margin-top:5px}
/* 공통 - 컨텐츠 */
.os_mac .subtit_subject .link_help{margin-top:-2px}
/* 공통 - 타이틀 */
.os_mac .dsp_tit .switch_check{margin-top:3px}
/* 결제정보 */
.os_mac .cardinfo_payment .desc_price .btn_gs{margin-top:0}
.os_mac .cardinfo_payment .desc_nodata .btn_gs{margin-top:0}
/* 레이어팝업 - 열 맞춤 설정 */
.os_mac .head_row{padding:23px 25px 21px}
.os_mac .head_row .check_inp{margin-top:2px}
.os_mac .tit_row .box_checkinp .lab_check{line-height:18px}
.os_mac .body_row .box_choicerow .list_choicerow li{padding:8px 48px 7px 27px}
.os_mac .foot_row .box_inptxt .lab_txt{margin-top:-10px}
/* 공통 - 광고계정 생성 */
.os_mac .group_inp .box_radioinp{line-height:17px}
.os_mac .group_inp .lab_radio{padding-left:24px}
.os_mac .verifi_inp .txt_noti{line-height:36px;letter-spacing:0}
.os_mac .group_inp.hyphen_inp .txt_hyphen{line-height:39px}
/* .os_mac .group_inp.hyphen_inp .inp_txt{letter-spacing:-1px} */
/* 공통 - 컨텐츠테이블 */
.os_mac .itembox_set .txt_error{margin-top:8px}
.os_mac .itembox_set .txt_guide{margin-top:8px}
.os_mac .itembox_set th .link_help{margin-top:-3px}
.os_mac .sortable .desc_sort{margin-top:-9px}
/* 레이어팝업 테이블 공통 */
.os_mac .tbl_layer2 .inner_cell{padding-top:14px;padding-bottom:11px}
/* 서류 업로드 */
.os_mac .tbl_layer .target_url .inner_cell{padding-bottom:0}
/* 충전금액 */
.os_mac .tbl_layer .box_cash .txt_won{line-height:36px}
/* 광고계정관리 */
.os_mac .total_cash > .ico_comm{margin-top:29px}
/*.os_mac .managebox_tit .tit_box{line-height:40px}*/
.os_mac .managebox_tit .subtit_box{line-height:40px}
.os_mac .managebox_tit .subtit_box2{line-height:40px}
.os_mac .card_summaryinfo .tit_card .sub_title:before{top:1px}
.os_mac .account_cardinfo .define_adinfo .btn_gs{margin-top:-2px}
.os_mac .ad_managebox .desc_append .ico_star{margin-top:3px}
.os_mac .define_adinfo td .txt_bar{margin-top:5px}
/* 카드관리 */
.os_mac .account_paymentinfo .txt_error .link_help{margin-top:-3px}
.os_mac .card_pay .tit_card .use_card{line-height:23px}
.os_mac .card_pay .ico_cardoff{line-height:23px;letter-spacing:0}
.os_mac .card_pay .txt_info .ico_cardoff{margin-top:1px}
.os_mac .card_pay_s .use_card{line-height:21px}
.os_mac .card_pay_s .ico_cardoff{line-height:21px;letter-spacing:0}
.os_mac .card_pay_s .tit_card .ico_cardoff{margin-top:0}
/* 카드관리 및 캐쉬관리 레이어 */
/* .os_mac .tbl_layer .list_cash{margin-top:-7px;margin-bottom:-4px}
.os_mac .tbl_layer .list_cash .box_radioinp{line-height:39px} */
/* 로그인 */
.os_mac .login_box .box_checkinp{line-height:22px}
.os_mac .login_box .info_user{line-height:24px}
.os_mac .login_box .box_error{padding-top:11px;padding-bottom:9px}
.os_mac .tooltip_login{padding-top:11px;padding-bottom:8px}
.os_mac .tooltip_login .link_tooltip + .link_tooltip:before{margin-top:2px}
/* 약관동의 */
.os_mac .wrap_agreepersonal .tbl_g3 th .inner_tbl{line-height:56px}
.os_mac .wrap_agreepersonal .tbl_g3 td .inner_tbl{padding-top:17px;padding-bottom:14px}
/* 사용자 정보 입력 */
.os_mac .define_selinp .list_define{line-height:23px}
.os_mac .box_infoagree{line-height:62px}
.os_mac .box_infomsg{line-height:49px}
/* 내정보메뉴 */
.os_mac .myinfo_wrap .subtit_subject{line-height:40px}
.os_mac .adnotify_managebox .tbl_g3 td .inner_tbl{padding:21px 30px}
/* 내정보메뉴 - 자동결제카드등록 레이어 */
.os_mac .selfcard_layer .box_checkinp .lab_check{line-height:28px}
/* 회원탈퇴/회원탈퇴 불가능 알림 레이어 */
.os_mac .timer_set{line-height:37px}
.os_mac .tbl_layer .aftertxt_wrap .btn_gm{letter-spacing:0}
/* 마스터권한 이동 요청 레이어 */
.os_mac .masterauth_layer .list_master li:before{top:9px}
/* 픽셀&SDK 관리 */
.os_mac .tbl_event .inner_tbl{padding-top:6px;padding-bottom:4px}
.os_mac .tbl_event .ad_state{margin-top:-3px}
/* 픽셀&SDK 연동 관리 */
.os_mac .pixelsdk_wrap .list_append li:before{margin-top:9px}
.os_mac .dashcopy_wrap .tit_copy .link_help{margin-top:-2px}
/* 광고그룹 만들기 */
.os_mac .hide_all .dsp_tit{top:22px}
.os_mac .area_making .hide_all_desc{padding-top:25px}
/* 비즈채널 관리 */
.os_mac .link_g .ico_comm{margin-top:4px}
/* 맞춤보고서 */
.os_mac .report_wrap .tbl_g2 .inner_tbl{padding-top:10px;padding-bottom:9px}
/* 소재만들기 */
.os_mac .item_set .link_g .ico_comm{margin-top:6px}
/* 2020-12-29 시작 :대량관리 */
.os_mac .filemanage_wrap .tbl_g2 .wrap_btn{margin-bottom:-1px}
.os_mac .filemanage_wrap .ad_state{letter-spacing:0}
/* 2020-12-29 종료 :대량관리 */
/* 2021-04-12 table head 2줄타입 시작 */
.os_mac .thead_type2 .inner_tbl{padding-top:10px;padding-bottom:8px}
/* 2021-04-12 table head 2줄타입 종료 */
/* mac CSS */
